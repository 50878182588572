import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';


export default class IntroNine extends Component {
    render() {
        return (

            <div className="forex-re-7">
                <Container>

                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                        <Container>
                            <Accordion id="faq-acc">
                                <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                    <Accordion.Header>What is WinsorFX?</Accordion.Header>
                                    <Accordion.Body>
                                    WinsorFX is a forex trading website that provides users with access to a range of currency pairs, as well as a variety of trading tools and resources.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                    <Accordion.Header>What types of Blends does WinsorFX offer?</Accordion.Header>
                                    <Accordion.Body>
                                    WinsorFX offers a selection of Blends, which are pre-set combinations of currency pairs designed to help traders optimize their trading strategies.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                    <Accordion.Header>How do I use a Blend on WinsorFX?</Accordion.Header>
                                    <Accordion.Body>
                                    To use a Blend on WinsorFX, simply select the desired Blend from the Blends menu, then enter the desired trade parameters.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                    <Accordion.Header>What types of Blends are available on WinsorFX?</Accordion.Header>
                                    <Accordion.Body>
                                    WinsorFX offers a range of Blends, including short-term, long-term, and highly-diversified Blends.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                    <Accordion.Header>Can I customize my Blend on WinsorFX?</Accordion.Header>
                                    <Accordion.Body>
                                    Yes, users can customize their Blends on WinsorFX by selecting the desired currency pairs, setting the desired trade parameters, and setting the desired risk level.

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="2500"> 
                    <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}