import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "../compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import chartimg from "../../images/card-s1-img.png";
import settingtimg from "../../images/card-s2-img.png";
import pichartimg from "../../images/card-s3-img.png";
import starimg from "../../images/card-s4-img.png";

export default class Mt5Two extends Component {
  render() {
    return (
      <div className="mt5-two-bg-ctrade">
        <Container fluid className="mt5-two-cont">
          <Row>
            <h1 className="text-center head-our-ctrade text-4xl">
              Benefits of Ctrader
            </h1>
            <Col xs="12" md="3" lg="3" className="justify-content-center">
              <Container className="mt5-card-ctrade" data-aos="flip-down" data-aos-duration="1500">
                <Row>
                  <Col
                    xs="12"
                    md="12"
                    lg="12"
                    className="d-flex justify-content-start"
                  >
                    <Image loading="lazy"
                      src={chartimg}
                      alt="Image"
                      className="mt5-two-img-ctrade"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12" className="text-left">
                    <div className="min-ght-ctrade">
                      <h4 className="ctrade-card-titl1 text-lg">
                        Improved Trade Management
                      </h4>
                      <h4 className="text-black text-sm font-normal s1-ctr-crd">
                        Traders can net as well as hedge their positions to
                        manage their exposure and take better control of their
                        risk management
                      </h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12" className="text-left">
                    <a href="#" className="lrn-mre-ctrade">
                      Learn More
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs="12" md="3" lg="3" className="justify-content-center">
              <Container className="mt5-card-ctrade" data-aos="flip-down" data-aos-duration="2000">
                <Row>
                  <Col
                    xs="12"
                    md="12"
                    lg="12"
                    className="d-flex justify-content-start"
                  >
                    <Image loading="lazy"
                      src={settingtimg}
                      alt="Image"
                      className="mt5-two-img-ctrade"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12" className="text-left">
                    <div className="min-ght-ctrade">
                      <h4 className="ctrade-card-titl2 text-lg">
                        Algorithmic Trading
                      </h4>
                      <h4 className="text-black text-sm font-normal s2-ctr-crd">
                        Automate your trading with Expert Advisors to make the
                        most of market moves without having to do a thing, by
                        plugging-in your EA to the platform
                      </h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12" className="text-left">
                    <a href="#" className="lrn-mre-ctrade">
                      Learn More
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs="12" md="3" lg="3" className="justify-content-center">
              <Container className="mt5-card-ctrade" data-aos="flip-down" data-aos-duration="2500">
                <Row>
                  <Col
                    xs="12"
                    md="12"
                    lg="12"
                    className="d-flex justify-content-start"
                  >
                    <Image loading="lazy"
                      src={pichartimg}
                      alt="Image"
                      className="mt5-two-img-ctrade"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12" className="text-left">
                    <div className="min-ght-ctrade">
                      <h4 className="ctrade-card-titl3 text-lg">
                        Trade More Assets
                      </h4>
                      <h4 className="text-black text-sm font-normal s3-ctr-crd">
                        Designed for trading more assets and offering more
                        technical indicators than MT4, it's the most complete
                        MetaTrader platform to date
                      </h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12" className="text-left">
                    <a href="#" className="lrn-mre-ctrade">
                      Learn More
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs="12" md="3" lg="3" className="justify-content-center">
              <Container className="mt5-card-ctrade" data-aos="flip-down" data-aos-duration="3000">
                <Row>
                  <Col
                    xs="12"
                    md="12"
                    lg="12"
                    className="d-flex justify-content-start"
                  >
                    <Image loading="lazy"
                      src={starimg}
                      alt="Image"
                      className="mt5-two-img-ctrade"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12" className="text-left">
                    <div className="min-ght-ctrade">
                      <h4 className="ctrade-card-titl4 text-lg">
                        Intuitive Charts
                      </h4>
                      <h4 className="text-black text-sm font-normal s4-ctr-crd">
                        Fully customisable, multi- time frame charts and a wide
                        range of technical indicators
                      </h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12" className="text-left">
                    <a href="#" className="lrn-mre-ctrade">
                      Learn More
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
