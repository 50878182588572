import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer'
import ForexFive from './components/forex-five';
import ShareFour from './components/share-four';
import ShareFive from './components/share-five';
import ForexEight from './components/forex-eight';
import ShareEight from './components/share-eight';
import ShareOne from './components/share-one';
import ShareThree from './components/sharfe-three';
import ForexTwo from './components/forex-two';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function SharesPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - Shares';
    }, []);
    return (
        <div className='App'>
            <NavbarComp />
            <div className='clsmnu'>
                <ShareOne />
                {/* <ShareTwo /> */}
                <ForexTwo />
                <ShareThree />
                <ShareFour />
                <ShareFive />
                <ForexFive />
                <ShareEight />
                <ForexEight />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default SharesPage;