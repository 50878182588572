import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../../images/stay-red-img.png";
import appstore from '../../images/down-appstore.svg';
import playstore from '../../images/down-playstore.svg';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

export default class AboutSixFour extends Component {
  render() {
    return (
      <div className="stay-bg">
        <Container fluid className="container-fluid">
          <Row>
            <Col xs="12" md="6" lg="6" className="justify-content-start tradeplatform-two">
              <Container className="about6-four-cont-1">
                <h1 className="about8-two-head text-left mr-bo-one-tx">Winsorfx on the move</h1>
                <p className="text-smtext-left">Our official mobile applications are only<br></br>
                  available through the Apple App Store and<br></br>
                  Google Play Store.</p>
                <div>

                </div>
                {/* <Row id="apps-links">
                  <Col
                    xs="12"
                    md="12"
                    lg="12"
                    className="justify-content-start d-flex"
                  >
                    <a href="javascript:void(0);">
                      <Image loading="lazy"
                        src={appstore_img}
                        alt="Image"
                        className="appl-btn"
                      ></Image>
                    </a>
                    <a href="javascript:void(0);">
                      <Image loading="lazy"
                        src={playstore_img}
                        className="ply-btn-our-pltdf"
                      ></Image>
                    </a>
                  </Col>
                </Row> */}
                <Row id="btn-group-new">
                  <Col xs="12" md="12" lg="12" className="">
                    <ButtonToolbar aria-label="Toolbar with button groups">
                      <ButtonGroup className="me-2" aria-label="Second group">
                        <a href="https://apps.apple.com/in/app/ctrader/id767428811" target="_blank"><Image loading="lazy" src={appstore} alt="Image" className="app-btn" /></a>
                      </ButtonGroup>
                      <ButtonGroup aria-label="Third group">
                        <a href="https://play.google.com/store/apps/details?id=com.spotware.ct" target="_blank"><Image loading="lazy" src={playstore} alt="Image" className="app-btn2" /></a>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
              <Image loading="lazy" src={img1} alt="Image" className="about8-two-img-1" data-aos="fade-right" data-aos-duration="1200" />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}