import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/new-lap-img.png";

export default class ShareThree extends Component {
    render() {
        return (
            <div className="share-bg-3">
                <Container className="">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h1 className="text-center tx-cl-re">Why Trade Shares CFDs with Winsorfx</h1>
                            <p className="text-center tx-p-re">Commission-free trading plus the best tools*
                                Access over 2,000 stocks online via our proprietary platform
                                Bullish or Bearish? Go Long and Short with leverage, depending on your outlook
                                Tight spreads and competitive overnight swaps
                                Leverage up to 1:10</p>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" className="lap-re-img" data-aos="fade-up" data-aos-duration="1300"></Image>
                                </Col>
                            </Row>
                            <p className="text-center tx-p-re">* No commission will be charged for the opening and/or closing of positions. Other fees may apply.</p>

                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}