import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import chartimg from "../images/bar-chart.svg"
import settingtimg from "../images/settings.svg"
import pichartimg from "../images/pie-chart.svg"
import starimg from "../images/rating-star.svg"




export default class Mt5Two extends Component {
    render() {
        return (
            <div className="mt5-two-bg">
                <Container className="mt5-two-cont">
                    <Row>
                        <h1 className="text-center text-black text-4xl">Benefits of MT5</h1>
                        <Col xs="12" md="3" lg="3"></Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="mt5-card">
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={chartimg} alt="Image" className="mt5-two-img" />
                                    </Col>
                                </Row>
                                <h4 className="text-black text-center text-lg">
                                    Improved Trade Management
                                </h4>
                                <h4 className="text-black text-center text-sm font-normal">
                                    Traders can net as well as hedge their positions to manage their exposure and take better control of their risk management
                                </h4>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="mt5-card">
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={settingtimg} alt="Image" className="mt5-two-img" />
                                    </Col>
                                </Row>
                                <h4 className="text-black text-center text-lg">
                                    Algorithmic Trading
                                </h4>
                                <h4 className="text-black text-center text-sm font-normal">
                                    Automate your trading with Expert Advisors to make the most of market moves without having to do a thing, by plugging-in your EA to the platform
                                </h4>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3"></Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="3" lg="3"></Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="mt5-card">
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={pichartimg} alt="Image" className="mt5-two-img" />
                                    </Col>
                                </Row>
                                <h4 className="text-black text-center text-lg">
                                    Trade More Assets
                                </h4>
                                <h4 className="text-black text-center text-sm font-normal">
                                    Designed for trading more assets and offering more technical indicators than MT4, it's the most complete MetaTrader platform to date
                                </h4>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="mt5-card">
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={starimg} alt="Image" className="mt5-two-img" />
                                    </Col>
                                </Row>
                                <h4 className="text-black text-center text-lg">
                                    Intuitive Charts
                                </h4>
                                <h4 className="text-black text-center text-sm font-normal">
                                    Fully customisable, multi- time frame charts and a wide range of technical indicators
                                </h4>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}