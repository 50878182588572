import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from '../images/cTrader-mobile.png'
import Button from "react-bootstrap/Button";

export default class ShareFive extends Component {
    render() {
        return (
            <div className="Shares-bg-5">
                <Container >
                    <Row>
                        <Col xs="12" md="4" lg="4">
                            <h1 className="sh-tx-h">IPO Trading</h1>
                            <h5 className="mt-4">Discover upcoming IPOs and how to
                                trade CFDs on grey markets at
                                Winsorfx</h5>

                            <Row>
                                <Col xs="12" md="12" lg="12">
                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                        <Button className="btn text-center share-our-btn">Start Trading</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="2" lg="2"></Col>
                        <Col xs="12" md="6" lg="6" className="d-flex justify-content-center">
                            <Image loading="lazy" src={img1} alt="Image" className="ctrader-img-re" data-aos="fade-left" data-aos-duration="1300"></Image>
                        </Col>
                    </Row>
                </Container>
            </div>

        )
    }
}