import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import img1 from "../images/pamm-tab-lft.png";
import mail from "../images/pamm-mail.svg";
import call from "../images/pamm-call.svg";
import live from "../images/live-chat.svg";

export default class PammThree extends Component {
    render() {
        return (
            <div className="tr-pl-sec-two-pamm">
                <Container className="mr-pamm-cont-1">
                    <Row>
                        <Col xs="12" md="6" lg="6" className="d-flex justify-content-start pamm-img-space pamm-img-space">
                            <Image loading="lazy" src={img1} alt="Image" className="mobile-pamm-nw" data-aos="fade-right" data-aos-duration="1500"></Image>
                        </Col>
                        <Col xs="12" md="6" lg="6">
                            <h1 className="mar-tp-pamm-three" id="pamm3-head1">CREATE ACCOUNT INSTANTLY <br></br>AS A STRATEGY MANAGER</h1>
                            <h1 className="mar-tp-pamm-three" id="pamm3-head2">CREATE ACCOUNT INSTANTLY AS A STRATEGY MANAGER</h1>
                            <h5 className="mar-tp-pamm-three-p">Simply fill the required form to be a Strategy Manager and get started your journey with Winsorfx.</h5>
                            <Row className="align-items-center mobile-pamm-row-new">
                                <Col xs="12" md="3" lg="3" className="d-flex pamm-3-col">
                                    <Image loading="lazy" src={mail} alt="Image" className="paam3-ico"></Image>
                                    <a className="pamm3-txt-new" href="mailto:support@winsorfx.com">Mail us</a>
                                </Col>

                                <Col xs="12" md="3" lg="3" className="d-flex pamm-3-col">
                                    <Image loading="lazy" src={call} alt="Image" className="paam3-ico"></Image>
                                    <a className="pamm3-txt-new" href="tel:+44 7915 608657">Call us</a>
                                </Col>

                                <Col xs="12" md="4" lg="4" className="d-flex pamm-3-col">
                                    <Image loading="lazy" src={live} alt="Image" className="paam3-ico"></Image>
                                    <a href="javascript:void(Tawk_API.toggle())" className="pamm3-txt-new">Live Chat</a>
                                </Col>
                            </Row>
                            <Row className="mar-tp-txt-pamm">
                                <Col xs="6" md="4" lg="4">
                                    <div>
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn pamm-btn-nw">Start Now</Button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs="6" md="4" lg="4">
                                    <div>
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn pamm-btn-2-nw">Create Account</Button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
