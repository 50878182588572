import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import MiniChartWidget41 from "./Widgets/MiniChartWidget41";
import MiniChartWidget42 from "./Widgets/MiniChartWidget42";
import MiniChartWidget43 from "./Widgets/MiniChartWidget43";
import MiniChartWidget44 from "./Widgets/MiniChartWidget44";

export default class BondFour extends Component {
    render() {
        return (
            <div className="commod-bg-4">
                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12" className="text-center">
                            <h1 className="commod-4-txt-h">Ways to Trade Bonds CFDs</h1>
                            <p className="commod-4-txt-p">Trade CFDSA CFD account will allow you to
                                trade on bonds directly and via access to a range
                                of ETFs.</p>
                        </Col>
                        <Container className="justify-content-center mr-comd-tp">
                            <Row className="mr-comd-tp d-flex justify-content-center">
                                {/* <Col xs="0" md="1" lg="1"></Col> */}
                                <Col xs="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="slide-right" data-aos-duration="1200">
                                    <Container className="bg-wh-comd">
                                        <MiniChartWidget41 />
                                        <Container>
                                            <Row className="d-flex justify-content-around">
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                                        <Button className="btn text-center comd-our-btn-nw-mob">Trade</Button>
                                                    </Link>
                                                </Col>
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/login.php" target="_blank">
                                                        <Button className="btn text-center comd2-our-btn-nw-mob">Commodities</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Container>
                                </Col>
                                {/* <Col xs="0" md="2" lg="2"></Col> */}
                                <Col xs="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="slide-left" data-aos-duration="1200">
                                    <Container className="bg-wh-comd">
                                        <MiniChartWidget42 />
                                        <Container>
                                            <Row className="d-flex justify-content-around">
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                                        <Button className="btn text-center comd-our-btn-nw-mob">Trade</Button>
                                                    </Link>
                                                </Col>
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/login.php" target="_blank">
                                                        <Button className="btn text-center comd2-our-btn-nw-mob">Commodities</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Container>
                                </Col>
                                {/* <Col xs="0" md="1" lg="1"></Col> */}
                            </Row>
                            <Row className="mr-comd-bt d-flex justify-content-center">
                                {/* <Col xs="0" md="1" lg="1"></Col> */}
                                <Col xs="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="slide-right" data-aos-duration="1800">
                                    <Container className="bg-wh-comd">
                                        <MiniChartWidget43 />

                                        <Container>
                                            <Row className="d-flex justify-content-around">
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                                        <Button className="btn text-center comd-our-btn-nw-mob">Trade</Button>
                                                    </Link>
                                                </Col>
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/login.php" target="_blank">
                                                        <Button className="btn text-center comd2-our-btn-nw-mob">Commodities</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Container>
                                </Col>
                                {/* <Col xs="0" md="2" lg="2"></Col> */}
                                <Col xs="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="slide-left" data-aos-duration="1800">
                                    <Container className="bg-wh-comd">
                                        <MiniChartWidget44 />
                                        <Container>
                                            <Row className="d-flex justify-content-around">
                                                <Col xs="5" md="6" lg="6">
                                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                                        <Button className="btn text-center comd-our-btn-nw-mob">Trade</Button>
                                                    </Link>
                                                </Col>
                                                <Col xs="5" md="6" lg="6">
                                                    <Link to="https://my.winsorfx.com/login.php" target="_blank">
                                                        <Button className="btn text-center comd2-our-btn-nw-mob">Commodities</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Container>
                                </Col>
                                {/* <Col xs="0" md="1" lg="1"></Col> */}
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </div>
        )
    }
}