import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from '../images/mboiletrad-red-img.png';
import img3 from '../images/mob-sec-4-new.png';
import img2 from '../images/mob-sec-3-new.png';
import Button from "react-bootstrap/Button";

export default class MobileTradingThree extends Component {
    render() {
        return (
            <div className="mobile3-bg">
                <Container>
                    <h1 className="text-center tx-cl-re-bobtr text-2xl font-semibold mb-4">Features</h1>
                </Container>
                <Row className="trad-plat2-bg-new">
                    <Col xs="12" md="5" lg="5" className="justify-content-center">
                        <Container className="mobile-three-1bg">
                            <h4 className="text-left font-semibold text-xl mts-tx">Simple and intuitive</h4>
                            <p className="bobile-txt text-left">The winsorfx.com app has been<br></br> streamlined to make
                                it simpler and<br></br> more intuitive to use, as well as<br></br> faster
                                and more stable</p>
                            <Row>
                                <Col xs="12" md="12" lg="12">
                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                        <Button className="btn text-center our-btn-new">Learn More</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs="12" md="7" lg="7" className="justify-content-center d-flex">
                        <Container className="justify-content-center d-flex mobile-three-cont">
                            <Image loading="lazy" src={img1} alt="Image" className="mobile3-img-new" data-aos="fade-left" data-aos-duration="1500" />
                        </Container>
                    </Col>
                </Row>
                <Container>
                    <Row className="mobile-cont-space">
                        <Col xs="12" md="5" lg="5" className="justify-content-center d-flex">
                            <Image loading="lazy" src={img2} alt="Image" className="mobile3-img" data-aos="fade-right" data-aos-duration="1500" />
                        </Col>
                        <Col xs="12" md="5" lg="5" className="justify-content-center">
                            <Container className="mobile-three-2bg">
                                <h4 className="text-left font-semibold text-xl">Integrated trading view charts</h4>
                                <p className="bobile-txt text-left">Quick order entry direct from your charts.<br className="dnone"></br> Swipe to
                                    open a ticket, edit or cancel orders<br className="dnone"></br> and positions.</p>
                                <Row>
                                    <Col xs="12" md="12" lg="12">
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn text-center our-btn-new">Learn More</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                <Row>
                    <Col xs="12" md="5" lg="5">
                        <Container className="mobile-three-3bg">
                            <h4 className="text-left font-semibold text-xl">The information you need. Fast.</h4>
                            <p className="bobile-txt text-left">Dynamic top movers and<br></br> customisable featured and
                                watch<br></br> lists, combined with integrated<br></br> financial news
                                and commentary.</p>
                            <Row>
                                <Col xs="12" md="12" lg="12">
                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                        <Button className="btn text-center our-btn-new mb-5">Learn More</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs="12" md="7" lg="7" className="justify-content-center d-flex">
                        <Image loading="lazy" src={img3} alt="Image" className="mobile3-img" data-aos="fade-left" data-aos-duration="1500" />
                    </Col>
                </Row>
            </div>
        )
    }
}
