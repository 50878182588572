import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import img1 from "../images/new-reimg-3.png";

export default class ETFOne extends Component {
    render() {
        return (
            <div className="forex-bg" id="fo-bg-re">
                <Container >
                    <Row>
                        <Col xs="12" md="5" lg="5">
                            <h1 className="tx-cl-re-1">ETFs</h1>
                            <p className="tx-cl-re-1-p">Trade CFD ETFs - Exchange Traded Funds - to gain exposure to a wide range of
                                stock markets, sectors, commodities, bonds and currencies. Buy the ones you like, short the ones you don't.
                            </p>

                            <Row>
                                <Col xs="12" md="12" lg="12" >
                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                        <Button className="btn text-center forex-our-btn">Trade ETFs</Button>
                                    </Link>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs="12" md="7" lg="7" className="justify-content-center d-flex">
                            <Image loading="lazy" src={img1} alt="Image" className="hero-img-forex" data-aos="zoom-in-left" data-aos-duration="1200"></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}