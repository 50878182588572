import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../../components/compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import img2 from "../../images/new-lap-img.png";

export default class AboutEightTwo extends Component {
  render() {
    return (
      <div className="share-bg-3">
        <Container className="">
          <Row>
            <Col xs="12" md="12" lg="12">
              <h1 className="text-center tx-cl-re">
                Why trade with winsorfx.com?
              </h1>
              <p className="text-center tx-p-re">
                We have over 4 Years of experience in powering our customers’
                trades. Join the 10,000 who already benefit from our low spread
                market leading trading tools and quick execution speeds. Access
                your trades in a way that suits you online or in our simple and
                easy to use trading app. And stay up to date with the news that
                affects your trades via our unique market insight & analysis.
                Online, on app, on your side.
              </p>
              <Row>
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  className="d-flex justify-content-center"
                >
                  <Image loading="lazy" src={img2} alt="Image" className="lap-re-img" data-aos="fade-up" data-aos-duration="1200"></Image>
                </Col>
              </Row>
              <div className="d-flex justify-content-center">
                <Link to="https://my.winsorfx.com/register.php" target="_blank">
                  <button type="button" class="btn text-center our-btn">Like it? Trade it!</button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
