import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image"
import "./compstyle.css"
import Button from "react-bootstrap/Button";
import img2 from '../images/tarding-sec-6-img.png';
import { Link } from "react-router-dom";

export default class TradeSix extends Component {
    render() {
        return (
            <div className="tradingpg-s4-bg">
                <Container>
                    <Row className="mobile-cont-space-new-32">
                        <Col xs="12" md="6" lg="6" className="justify-content-center">
                            <Container className="mobile-three-1bg-tradings">
                                <h4 className="text-left font-semibold text-lg trading-pg-head">Easy going support</h4>
                                <p className="bobile-txt-trading text-left">We are here to help and enrich your trading experience</p>
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="d-flex">
                                        <Link to="javascript:void(Tawk_API.toggle())">
                                            <Button className="btn text-center our-btn-new-chat">Chat with us</Button>
                                        </Link>
                                        <Link to="tel:+44 2080 979 794">
                                            <Button className="btn text-center our-btn-new-call">Call Us</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="d-flex justify-content-center">
                            <Image loading="lazy" src={img2} alt="Image" className="tardingpg-s4-mob-img" data-aos="fade-right" data-aos-duration="1500" />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}