import { React, Component } from "react";
import { Container, Image } from "react-bootstrap"
import './compstyle.css'
import terms_img from '../images/termscons-img.png'


export default class DuelContestFour extends Component {
    render() {
        return (
            <Container>
                <Container className="moss">
                    <h5 class="line-1 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> THE REWARDS:</h5>
                </Container>
                <div className="d-flex justify-content-center">
                    <Image loading="lazy" src={terms_img} alt="Image" className="terms-img"></Image>
                </div>
                <ul className="ul">
                    <li className="li terms-lh">Duel Time - 24HRS</li>
                    <li className="li terms-lh">A User can participate in One Duel at a time.</li>
                    <li className="li terms-lh">User can participate in any number of duel in the contest time line.</li>
                    <li className="li terms-lh">Duel winners will be awarded with the reward points for their wins per duel.</li>
                    <li className="li terms-lh">Reward points will be added in the rewards wallet(NEW).</li>
                    <li className="li terms-lh">Rewards can be converted into real money. (NEW)EX. 500 Points= 5$ Top 25 participants will get gift vouchers.</li>
                    <li className="li terms-lh">Any CRM User register can participate in the Duel.</li>
                    <li className="li terms-lh">Either they can invite the opponent by using the invite link Or system will assign a random opponent- if any participates on queue.</li>
                    <li className="li terms-lh">Once the both users accepts the duel invitation- a c trader account will be delivered with same balance, same leverage and same type of accounts will be given as per the amount user choosed.</li>
                    <li className="li terms-lh">The benchmark for determining a dual result is a 24-hour window starting from the moment of delivery.</li>
                    <li className="li terms-lh">After 24hrs- out of 2 participants based on their total profitability without losing 50% of equity will be considered as winner of the duel and reward points will be awarded.</li>
                </ul>

            </Container>
        )
    }
}