import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import appstore_img from "../images/down-appstore.svg";
import playstore_img from "../images/down-playstore.svg";
import ourplat_img from "../images/ourplatf-sec2-img.png";

export default class OurPlatTwo extends Component {
  render() {
    return (
      <div className="ourplatform-sec-2">
        <Container className="text-center">
          <Row>
            <Col xs="12" md="12" lg="12" className="">
              <Container>
                <h1 className="head-our-platf text-center">
                  Markets on the move
                </h1>
              </Container>
              <p className="our-plat-cont-p">
                Stay in control of your trading wherever you are, 24 hours a day
                with our winsorfx.com mobile app for iOS and Android. Featuring
                native designs for your devices and constantly updated to
                deliver the very best experience, the winsorfx.com trading app
                lets you keep your finger on the market pulse all the time.
                Execute trades with a single tap, place orders and manage alerts
                and positions on-the-go.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="12"
              lg="12"
              className="d-flex justify-content-center"
            >
              <Image loading="lazy"
                src={ourplat_img}
                alt="Image"
                className="our-platf-sec2-img"
              ></Image>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="12"
              lg="12"
              className="justify-content-center d-flex"
            >
              <a href="javascript:void(0);">
                <Image loading="lazy"
                  src={appstore_img}
                  alt="Image"
                  className="appl-btn"
                ></Image>
              </a>
              <a href="javascript:void(0);">
                <Image loading="lazy"
                  src={playstore_img}
                  className="ply-btn-our-pltdf"
                ></Image>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
