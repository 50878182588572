import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

export default class IBEight extends Component {
    render() {
        return (
            <div className="bg-white ib-four-bg">
                <Container>
                    <Row>
                        {/* <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                            <Card style={{ width: '24rem' }} className="ib-eight-card-design">
                                <Card.Body>
                                    <Card.Title className="ib-form-card-head">Calculate your reward</Card.Title>
                                    <Card.Text>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                                <Form.Label className="leading-9">Choose Account Type</Form.Label>
                                                <Form.Control type="email" placeholder="" />
                                            </Form.Group>
                                            <div>
                                                <Row>
                                                    <Col xs="10" md="10" lg="10">
                                                        <p className="text-[15px] leading-9">1st Level Clients attracted by you</p>
                                                    </Col>
                                                    <Col xs="2" md="2" lg="2">
                                                        <p className="text-[15px] leading-9">15</p>
                                                    </Col>
                                                    <Col xs="10" md="10" lg="10">
                                                        <p className="text-[15px] leading-9">2nd Level Clients attracted by you</p>
                                                        <p className="text-[15px] grey-txt">16.7%</p>
                                                    </Col>
                                                    <Col xs="2" md="2" lg="2">
                                                        <p className="text-[15px] leading-9">15</p>
                                                    </Col>
                                                    <Col xs="10" md="10" lg="10">
                                                        <p className="text-[15px] leading-9">3rd Level Clients attracted by you</p>
                                                        <p className="text-[15px] grey-txt">5.6%</p>
                                                    </Col>
                                                    <Col xs="2" md="2" lg="2">
                                                        <p className="text-[15px] leading-9">15</p>
                                                    </Col>
                                                    <Col xs="10" md="10" lg="10">
                                                        <p className="text-[15px]">Daily orders per client</p>
                                                    </Col>
                                                    <Col xs="2" md="2" lg="2">
                                                        <p className="text-[15px]">1</p>
                                                        <p className="text-[15px]">0</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                        <Container className="ib-card-cont">
                                            <Row>
                                                <Col xs="6" md="6" lg="6">
                                                    <p className="text-[14px]">Your total monthly income $1835</p>
                                                </Col>
                                                <Col xs="6" md="6" lg="6">
                                                    <Button className="btn ib-eight-btn">BECOME A PARTNER</Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                </Container>
                <Container className="ib-eight-cont">
                    <h4 className="text-[#c2223d] text-3xl font-medium text-center mb-8">START TRADING WITH MT5 WEBTRADER PLATFORM</h4>
                    <Row data-aos="fade-up" data-aos-duration="2000">
                        <Col xs="12" md="3" lg="3" className="justify-content-center d-flex">
                            <Container className="ib-one-cont2" id="ib-one-level">
                                <h4 className="pamm-one-head2 text-7xl font-bold text-center">01</h4>
                                <h4 className="pamm-one-head2 text-xl font-medium text-right">REGISTER</h4>
                                <p className="text-right font-normal text-base grey-txt mt-4">
                                    Register yourself
                                    with Winsorfx by
                                    logging in to your
                                    client area and
                                    providing the required
                                    documents.
                                </p>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="ib-one-cont2" id="ib-one-level">
                                <h4 className="pamm-one-head2 text-7xl font-bold text-center">02</h4>
                                <h4 className="pamm-one-head2 text-xl font-medium text-right">ACCOUNT<br></br>CREATION</h4>
                                <p className="text-right font-normal text-base grey-txt">
                                    Once the documents you
                                    provided are approved,
                                    you can now create
                                    a live trading account
                                    with Winsorfx.
                                </p>
                            </Container>

                        </Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="ib-one-cont2" id="ib-one-level">
                                <h4 className="pamm-one-head2 text-7xl font-bold text-center">03</h4>
                                <h4 className="pamm-one-head2 text-xl font-medium text-right">DEPOSIT<br></br>FUND</h4>
                                <p className="text-right font-normal text-base grey-txt">
                                    Choosing the right
                                    payment method,
                                    you can deposit the
                                    funds in your account
                                    in order to start
                                    trading.
                                </p>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="ib-one-cont2" id="ib-one-level">
                                <h4 className="pamm-one-head2 text-7xl font-bold text-center">04</h4>
                                <h4 className="pamm-one-head2 text-xl font-medium text-right">START<br></br>TRADING</h4>
                                <p className="text-right font-normal text-base grey-txt">
                                    Finally, you can
                                    embark your journey
                                    of being a trader,
                                    simply open CTrader web
                                    trader through your
                                    browser, login and
                                    start to trade.
                                </p>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}