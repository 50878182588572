import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import $ from 'jquery/src/jquery';

export default class IBTen extends Component {


  jQuerycode = () => {
    $(document).ready(function () {
      var rangeSlider = function () {
        var slider = $('.range-slider'),
          range = $('.range-slider input[type="range"]'),
          value = $('.range-value');
        slider.each(function () {
          value.each(function () {
            var value = $(this).prev().attr('value');
            $(this).html(value);
          });
          range.each(function () {


            var per = ((parseInt(this.value) / parseInt(this.max)) * 100);
            $(this).css('background', 'linear-gradient(to right,#FF5848 ' + per + '%,#A8A8A8 ' + per + '%)');
          });
          range.on('input', function () {
            var slider1, slider2, slider3, slider4, amount;
            slider1 = $("#slider1").val(); slider2 = $("#slider2").val(); slider3 = $("#slider3").val(); slider4 = $("#slider4").val();
            amount = Math.round(((slider1 * 6) + (slider2 * 3) + (slider3 * 1)) * slider4);
            $('.digit').html("$" + amount);


            var per = ((parseInt(this.value) / parseInt(this.max)) * 100);
            $(this).next(value).html(this.value);
            $(this).css('background', 'linear-gradient(to right,#FF5848 ' + per + '%,#A8A8A8 ' + per + '%)');
          });


        });
      };
      rangeSlider();
    });
  }
  componentDidMount() {
    this.jQuerycode()
  }
  render() {
    return (
      <div className="">
        <div className="ib-six-align3">
          <Container>
            <Container>

            </Container>
            <section className="ib-section-seven trading-promotion-five">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div
                    className="col-md-6 col-lg-6 col-xs-12 col-sm-12 d-flex justify-content-center"
                    data-aos="fade-left"
                  >
                    <div className="reward-calculate-text">
                      <h3 className="title text-left">Calculate your reward</h3>
                      <form action="#" method="post">
                        <div className="form-group frm-grp">
                          <label htmlFor="sel1" className="account-type">
                            Choose Account Type
                          </label>
                          <select class="form-control" id="sel1">
                            <option value="Classic" selected="selected">
                              Classic
                            </option>
                            <option value="Classic">PRO</option>
                            <option value="Classic">VIP</option>
                          </select>
                        </div>

                        <div className="form-group">
                          <div className="range-slider range-two">
                            <div className="range-two-per">$6</div>
                            <div className="range-two-text">
                              <label htmlFor="sel1" className="account-progress-text">
                                1st Level Clients attracted by you
                              </label>
                              <input
                                type="range"
                                defaultValue={15}
                                min={0}
                                max={100}
                                range="true"
                                id="slider1"
                              />
                              <span className="range-value">15</span>
                            </div>
                          </div>
                        </div>


                        <div className="form-group">
                          <div className="range-slider range-two">
                            <div className="range-two-per">$3</div>
                            <div className="range-two-text">
                              <label htmlFor="sel1" className="account-progress-text">
                                2nd Level Clients attracted by you
                              </label>
                              <input
                                type="range"
                                defaultValue={15}
                                min={0}
                                max={100}
                                range="true"
                                id="slider2"
                              />
                              <span className="range-value">15</span>
                            </div>
                          </div>
                        </div>


                        <div className="form-group">
                          <div className="range-slider range-two">
                            <div className="range-two-per">$1</div>
                            <div className="range-two-text">
                              <label htmlFor="sel1" className="account-progress-text">
                                3rd Level Clients attracted by you
                              </label>
                              <input
                                type="range"
                                defaultValue={15}
                                min={0}
                                max={100}
                                range="true"
                                id="slider3"
                              />
                              <span className="range-value">15</span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="range-slider range-one">
                            <label htmlFor="sel1" className="account-progress-text">
                              Daily orders per client
                            </label>
                            <input
                              type="range"
                              defaultValue={10}
                              min={0}
                              max={100}
                              range="true"
                              id="slider4"
                            />
                            <span className="range-value">10</span>
                          </div>
                        </div>
                      </form>
                      <div className="reward-total">
                        <ul>
                          <li className="total1">
                            <p className="total-title">Your total monthly income</p>
                            <p className="digit">$1500</p>
                          </li>
                          <li className="total2">
                            <a
                              href="https://my.winsorfx.com/login.php"
                              className="become-partner"
                            >
                              BECOME A PARTNER
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Container>
        </div>
      </div>
    )
  }
}