import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Table from 'react-bootstrap/Table';

export default class AboutTwoSeven extends Component {
    render() {
        return (
            <div className="abt-table-align-seven">
                <Container>
                    <h1 className="abt-txt-red">Country of Residence</h1>
                    <h6 className="abt-txt-mr-re">Depending on where you live you may be able to select a different office</h6>
                    <div className="abt-seven-table">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr className="table-align-six">
                                    <td></td>
                                    <td>Europe</td>
                                    <td>UK</td>
                                    <td>Australia</td>
                                    <td>South Africa</td>
                                    <td>BVI</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="table-align-six">
                                    <td>Residency</td>
                                    <td>Europe and territories, Iceland, Norway, Switzerland</td>
                                    <td>UK</td>
                                    <td>Austalia</td>
                                    <td>Global applicants</td>
                                    <td>Global applicants</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        )
    }
}