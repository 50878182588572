import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default class AboutFourOne extends Component {
    render() {
        return (
            
              <div className="copier1-bg" id="co-bg-re">
              <Container>
                  <Row>
                  <Col xs="12" md="1" lg="1"></Col>
                      <Col xs="12" md="10" lg="10">

                          <h1 className="text-center txt-cop-h1">Queries and Complaints</h1>
                          <h6 className="text-center txt-cop-p">Our trading platform, my.winsorfx.com, aims to give you the best
                           customer service possible and to forge a solid, long-lasting relationship with you. Your thoughts, ideas,
                            and worries are extremely important to us and our business. We make an effort to thoroughly respond to
                             your comments, understanding that customer comments and/or expressions of unhappiness are an opportunity 
                             for us to enhance our offerings and quality of customer service.</h6>
                      </Col>
                      <Col xs="12" md="1" lg="1"></Col>
                  </Row>
              </Container>
          </div>
        )
    }
}