import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from '../images/companies.png'
import shield from '../images/shield.svg'
import dollar from '../images/dollar-two.svg'
import bank from '../images/bank.svg'

export default class Secthird extends Component {
    render() {
        return (
            <div className="sec-third-p">
                <Container>
                    <Row>
                        {/* <Col lg="12 sec-third-space">
                            <Image loading="lazy" src={img1} alt="Image" className="sec-third-p" />
                        </Col> */}

                        <Col className="d-flex justify-content-center" lg="4" xm="12" md="4" data-aos="flip-down" data-aos-duration="600">
                            <Container className="boxes-design">
                                <Container className="ico-cont d-flex justify-content-center">
                                    <Image loading="lazy" src={shield} alt="Image" className="sec-3-ico" />
                                </Container>

                                <h6 className="box-head">Assured Quality</h6>
                                <ul className="sec3-ul">
                                    <li>
                                        Get premium Services
                                    </li>
                                    <li>
                                        Standard trading environment
                                    </li>
                                    <li>
                                        Safety of funds
                                    </li>
                                </ul>
                            </Container>
                        </Col>
                        <Col className="d-flex justify-content-center" lg="4" xm="12" md="4" data-aos="flip-down" data-aos-duration="900">
                            <Container className="boxes-design boxes-design2">
                                <Container className="ico-cont d-flex justify-content-center">
                                    <Image loading="lazy" src={dollar} alt="Image" className="sec-3-ico" />
                                </Container>

                                <h6 className="box-head">Transparency</h6>
                                <ul className="sec3-ul">
                                    <li>
                                        Clear Information
                                    </li>
                                    <li>
                                        Comply with laws and regulations
                                    </li>
                                    <li>
                                        Accessible Services
                                    </li>
                                </ul>
                            </Container>
                        </Col>
                        <Col className="d-flex justify-content-center" lg="4" xm="12" md="4" data-aos="flip-down" data-aos-duration="1200">
                            <Container className="boxes-design boxes-design2">
                                <Container className="ico-cont d-flex justify-content-center">
                                    <Image loading="lazy" src={bank} alt="Image" className="sec-3-ico" />
                                </Container>

                                <h6 className="box-head">Reliability</h6>
                                <ul className="sec3-ul">
                                    <li>
                                        24/5 Client Support
                                    </li>
                                    <li>
                                        Instant Transactions
                                    </li>
                                    <li>
                                        Security of Clients data
                                    </li>
                                </ul>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}