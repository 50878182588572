import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class TermsTwo extends Component {
    render() {
        return (
            <div className="ab-fo-two">
                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Acceptance of Terms:</h5>
                                <p className="about-para">You accept these Terms and Conditions by using the Winsorfx trading platform.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Eligibility:</h5>
                                <p className="about-para">To use the site, you must be at least 18 years old and be able to legally enter into a binding contract.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Account Registration:</h5>
                                <p className="about-para">During the registration process, you must give accurate and complete information and keep your account credentials secure.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Trading Risks:</h5>
                                <p className="about-para">You understand and agree that there are financial risks, including the potential loss of your money, involved with trading on the platform.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Trading Decisions:</h5>
                                <p className="about-para">You acknowledge that any information or resources given by Winsorfx are offered exclusively for educational reasons and do not constitute financial advice and that you are solely responsible for your own trading decisions.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Compliance with Laws:</h5>
                                <p className="about-para">You acknowledge that it is your obligation to ascertain and follow any legal or regulatory requirements and that you agree to abide by all applicable laws and regulations pertaining to trading activities.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Prohibited actions:</h5>
                                <p className="about-para">It is completely forbidden to use the platform for any fraudulent, illegal, or forbidden actions, including market manipulation or unauthorized trading.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Availability and Modifications:</h5>
                                <p className="about-para">Winsorfx will try to keep the platform available, but there may be hiccups, delays, or problems. Winsorfx retains the right to change, pause, or end any aspect of the platform at any moment and without incurring any liability.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Intellectual property:</h5>
                                <p className="about-para">Winsorfx is the sole owner of the platform's intellectual property rights. Without our express written permission, you are not allowed to use, reproduce, edit, or distribute any platform material.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Limitation of Liability:</h5>
                                <p className="about-para">Due to trading activities or the use of the site, Winsorfx is not responsible for any incidental, consequential, direct, indirect, punitive, or exemplary damages. Links or resources from third parties are not the responsibility of Winsorfx.</p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}