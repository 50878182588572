import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';


export default class MobTraderAcc extends Component {
    render() {
        return (

            <div className="forex-re-7">
                <Container>

                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                                <Accordion id="faq-acc">
                                    <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                        <Accordion.Header>What is Mobile Trading on WinsorFX?</Accordion.Header>
                                        <Accordion.Body>
                                            Mobile Trading on WinsorFX is a platform that allows you to trade on the foreign exchange market using your mobile device. It is equipped with the same features as the main trading platform, allowing you to access markets, analyze data and place trades.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                        <Accordion.Header>What mobile devices are supported?</Accordion.Header>
                                        <Accordion.Body>
                                            Mobile Trading on WinsorFX is currently supported on iPhone and Android devices.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                        <Accordion.Header>Can I use the same account for mobile trading?</Accordion.Header>
                                        <Accordion.Body>
                                            Yes, you can use the same account for mobile trading as for the main trading platform.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                        <Accordion.Header>Are there fees associated with mobile trading?</Accordion.Header>
                                        <Accordion.Body>
                                            No, there are no fees associated with mobile trading on WinsorFX.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                        <Accordion.Header>How secure is mobile trading?</Accordion.Header>
                                        <Accordion.Body>
                                            Mobile trading on WinsorFX is highly secure. All data is encrypted and stored on secure servers. Additionally, we use two-factor authentication to protect your account.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="2200">
                        <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}