import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import img1 from "../images/new-lap-img.png";

export default class ETFFour extends Component {
  render() {
    return (
      <div className="share-bg-3">
        <Container className="">
          <Row>
            <Col xs="12" md="12" lg="12">
              <h1 className="text-center tx-cl-re">
                Why Trade ETFS CFDs with Winsorfx
              </h1>
              <p className="text-center tx-p-re">
                Brand market exposure from a single position Go long or short
                Leveraged account.
              </p>
              <Row>
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  className="d-flex justify-content-center"
                >
                  <Image loading="lazy" src={img1} alt="Image" className="lap-re-img" data-aos="slide-up" data-aos-duration="1200"></Image>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
