import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Screener1 from "./Widgets/ScreenWidget1";
import Screener1Mob from "./Widgets/ScreenWidget1-mob";

export default class ForexFive extends Component {
    render() {
        return (
            <div className="forex-11-bg">
                <Container>
                    <h4 className="forex-11-head text-center text-4xl font-semibold mb-4">
                        Our most traded Forex pairs with<br></br>
                        the lowest spreads
                    </h4>
                </Container>

                <Container className="justify-content-center d-flex forex3-cont" id="scrrener1">
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justisy-content-center pad-t">
                            <div className="trading3-widget mt-8" data-aos="zoom-in-up" data-aos-duration="1000"><Screener1 /></div>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className="justify-content-center d-flex" id="scrrener1-mob">
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justisy-content-center pad-t">
                            <div className="trading3-widget mt-8" data-aos="zoom-in-up" data-aos-duration="1000"><Screener1Mob /></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
