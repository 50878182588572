import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from "../components/Navbar";
import Footer from '../components/Footer'
import { useEffect } from 'react';
import ConflictsOne from './Components/ConflictsOne';
import ConflictsTwo from './Components/ConflictsTwo';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function Conflicts() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - Clinets Complaints Handling Policy';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div clsmnu='clsmnu'>
                <ConflictsOne />
                <ConflictsTwo />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default Conflicts;