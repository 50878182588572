import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default class MasterFive extends Component {
    render() {
        return (
            <div className="">
                <Container >
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h1 className="tx-mas-re-1 text-center">Winsorfx Copytrading offers you an additional source of income: open 
                            a Master Trader Account, describe your strategy, and set your commission to let others copy your trades.</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}