import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Table from 'react-bootstrap/Table';

export default class AboutTwoTwo extends Component {
    render() {
        return (
            <div className="abt-table-align-one">
                <Container>
                    <h1 className="abt-txt-red">Discover our global trading opportunities</h1>
                    <h6 className="abt-txt-mr-re">Every one of our global offices is regulated</h6>
                    <div className="abt-two-table">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr className="table-align-one">
                                    <td></td>
                                    <td>Europe</td>
                                    <td>UK</td>
                                    <td>Australia</td>
                                    <td>BVI</td>
                                    <td>South Africa</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="table-align-one">
                                    <td>Regulated by</td>
                                    <td>CySEC</td>
                                    <td>FCA</td>
                                    <td>ASIC</td>
                                    <td>FSC</td>
                                    <td>FSCA</td>
                                </tr>
                                <tr className="table-align-one">
                                    <td>Supervised by</td>
                                    <td>CySEC, GFSC*</td>
                                    <td>FCA</td>
                                    <td>ASIC</td>
                                    <td>FSC</td>
                                    <td>FSCA</td>
                                </tr>
                                <tr className="table-align-one">
                                    <td>Complaints Authority</td>
                                    <td>Financial Ombudsman</td>
                                    <td>Financial Ombudsman</td>
                                    <td>Australian AFCA</td>
                                    <td>BVI FSC</td>
                                    <td>FAIS Ombudsman</td>
                                </tr>
                                <tr className="table-align-one">
                                    <td>Client's money</td>
                                    <td colSpan={5}>Clients’ funds kept in segregated bank accounts</td>
                                </tr>
                                <tr className="table-align-one">
                                    <td>Investor Compensation Scheme</td>
                                    <td>Yes – ICF (EUR20,000)</td>
                                    <td>Yes – FSCS (GBP85,000)**</td>
                                    <td>–</td>
                                    <td>–</td>
                                    <td>–</td>
                                </tr>
                            </tbody>
                        </Table>
                        <p>* Due to the operation of our Gibraltar Branch ** Depending on criteria and eligibility</p>
                    </div>
                </Container>
            </div>
        )
    }
}