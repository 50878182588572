import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class AboutSixOne extends Component {
    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
                <Container>
                    <Row>
                    <Col xs="12" md="1" lg="1"></Col>
                        <Col xs="12" md="10" lg="10">

                            <h1 className="text-center txt-cop-h1">Staying Protected Online</h1>
                            <h6 className="text-center stying-pg-hero-cont">Important information about how to stay safe online</h6>

                        </Col>
                        <Col xs="12" md="1" lg="1"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}