import React, { Component } from "react";
import { MiniChart } from "react-ts-tradingview-widgets";
import { Container } from "react-bootstrap";


export default class MiniChartWidget40 extends Component {
    render() {
        return (
            <div>
                <Container>
                <MiniChart colorTheme="light" width="100%" symbol="SPARKS:CANNABIS"></MiniChart>
                </Container>
            </div>
        )
    }
}