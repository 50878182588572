import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';


export default class CommodFive extends Component {
    render() {
        return (
            <div className="forex-re-7">
                <Container>
                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                                <Accordion id="faq-acc">
                                    <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                        <Accordion.Header>What is a commodity?</Accordion.Header>
                                        <Accordion.Body>
                                            A commodity is a basic good used in commerce that is interchangeable with other commodities of the same type. Examples of commodities include agricultural products, such as wheat, coffee, and sugar, as well as metals like gold, silver, and copper.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                        <Accordion.Header>What are the benefits of trading commodities?</Accordion.Header>
                                        <Accordion.Body>
                                            Trading commodities can help you diversify your portfolio, as well as provide greater potential for profits due to their high liquidity and volatility. It can also provide a hedge against inflation.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                        <Accordion.Header>What types of commodities can I trade on WinsorFX?</Accordion.Header>
                                        <Accordion.Body>
                                            You can trade a variety of commodities on WinsorFX, including energy (crude oil, natural gas, and heating oil), agricultural products (grains, sugar, and cocoa), and metals (gold, silver, and copper).
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                        <Accordion.Header>What is the minimum trade size for commodities?</Accordion.Header>
                                        <Accordion.Body>
                                            The minimum trade size for commodities is 1 lot (100 units) with a maximum trade size of 500 lots.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                        <Accordion.Header>What is the margin requirement for commodities?</Accordion.Header>
                                        <Accordion.Body>
                                            The margin requirement for commodities varies depending on the contract you are trading
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move">
                        <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}