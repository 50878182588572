import { React, Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Image, Card } from "react-bootstrap"
import './compstyle.css'
import one from '../images/Group 3.svg';
import two from '../images/Group 4.svg';
import three from '../images/Group 5.svg';
import four from '../images/Group 6.svg';
import five from '../images/Group 7.svg';
export default class DemoContestTwo extends Component {
    render() {
        return (
            <div className="white-bg">
                <Container className="moss">
                    <h5 class="line-1 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> What are the options?</h5>
                </Container>
                <Container className=" black-box">
                    <div className="mo-spend">
                        <h5 className="winner-award">WINNERS OF CASH PRIZES</h5>
                        <h5><b className="per-around">UPTO $3000 Per round</b></h5>
                        <p className="index-winner-top">First 25 Prizes will go to the top 25 finishers in each round! Traders have the chance to put their abilities to the test <br />
                            and perhaps make some extra money. All clients are eligible to enter the contest. Prepare to compete for the top <br />spot and display your trading prowess.</p>

                        <p className="index-winner-top"><b class="top-tr">TOP 25:</b> The top 25 participants in each round of the demo competition will receive guaranteed prizes.</p>
                        <p className="index-winner-top"><b class="top-tr">CASH PRIZES:</b> Each winner will be awarded $3000 per round of the competition. Prize amount for the top
                            <br /> 25 winners are given below:
                        </p>

                        <div>
                            <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                <Button className="btn btn-2">Learn More</Button>
                            </Link>
                        </div>
                    </div>

                    <Row className="move-do">
                        <Col lg="1" md="1" xs="12"></Col>
                        <Col lg="2" md="2" xs="12" className="mobile-center">
                            <Card className="box-card mobile-space">
                                <div className="card-body">
                                    <div>
                                        <Image loading="lazy" src={one} alt="Image" className="first"></Image>
                                    </div>
                                    <h4 class="card-title-s">TOP 1 <br /> WINNER GETS</h4>
                                    <h4 class="rupess">$500</h4>
                                </div>
                            </Card>
                        </Col>
                        <Col lg="2" md="2" xs="12" className="mobile-center">
                            <Card className="box-card move-card mobile-space">
                                <div className="card-body">
                                    <div>
                                        <Image loading="lazy" src={two} alt="Image" className="first"></Image>
                                    </div>
                                    <h4 className="card-title-1">TOP 2-5<br /> WINNERS<br /> EACH<br /> GETS</h4>
                                    <h4 className="rupess">$250</h4>
                                </div>
                            </Card>
                        </Col>
                        <Col lg="2" md="2" xs="12" className="mobile-center">
                            <Card className="box-card mobile-space">
                                <div className="card-body">
                                    <div>
                                        <Image loading="lazy" src={three} alt="Image" className="first"></Image>
                                    </div>
                                    <h4 className="card-title-1">TOP 6-10<br /> WINNERS<br /> EACH<br /> GETS</h4>
                                    <h4 className="rupess">$100</h4>
                                </div>
                            </Card>
                        </Col>
                        <Col lg="2" md="2" xs="12" className="mobile-center">
                            <Card className="box-card move-card mobile-space">
                                <div className="card-body">
                                    <div>
                                        <Image loading="lazy" src={four} alt="Image" className="first"></Image>
                                    </div>
                                    <h4 className="card-title-1">TOP 11-20<br /> WINNERS<br /> EACH<br /> GETS</h4>
                                    <h4 className="rupess">$50</h4>
                                </div>
                            </Card>
                        </Col>
                        <Col lg="2" md="2" xs="12" className="mobile-center">
                            <Card className="box-card mobile-space">
                                <div className="card-body">
                                    <div>
                                        <Image loading="lazy" src={five} alt="Image" className="first"></Image>
                                    </div>
                                    <h4 class="card-title-1">TOP 21-25<br /> WINNERS<br /> EACH<br /> GETS</h4>
                                    <h4 class="rupess">$25</h4>
                                </div>
                            </Card>
                        </Col>
                        <Col lg="1" md="1" xs="12"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
} 