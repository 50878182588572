import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/new-lap-img.png";

export default class CommodThree extends Component {
    render() {
        return (
            <div className="share-bg-3">
                <Container className="">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h1 className="text-center tx-cl-re">Why Trade Commodities CFDs with Winsorfx</h1>
                            <p className="text-center tx-p-re">Access CFDs on 20+ spot and futures Commodity
                                markets, plus commodity stocks and ETFs with
                                the same account.

                                Spreads as low as 0.5 on
                                Gold.

                                Our CFDs on futures are continuous contracts that
                                do not expire. We will automatically roll your
                                positions.</p>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" className="lap-re-img" data-aos="fade-up" data-aos-duration="1200"></Image>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}