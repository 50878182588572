import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Button from "react-bootstrap/Button";
import sec3img from "../images/mt4-commute.svg"


export default class MT4THREE extends Component {
    render() {
        return (
            <div className="mt5-two-bg">
                <Container className="mt5-three-cont">
                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
                            <Image loading="lazy" src={sec3img} alt="Image" className="mt5-three-img" />
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center">
                            <h1 className="text-left text-black text-4xl my-4">Trade using MT4 with<br></br>Winsorfx</h1>
                            <h4 className="text-left text-black text-sm font-normal ml-8">You can access popular and easy-to-use web trading platform MetaTrader 4 with your winsorfx.com account.</h4>
                            <Button className="btn mt5-three-btn">Sign Up</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}