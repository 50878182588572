import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import '../compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Button from "react-bootstrap/Button";
// import img1 from "../../images/mob-trade-img.png";
import img1 from "../../images/ctrade-red-img.png";

export default class Trade extends Component {
    render() {
        return (
            <div className="ourplat-left-bg-new mb-pamm-one">
                <Container>
                    <Row>
                        <Col xs="12" md="5" lg="5" className="">
                            <div>
                                <h1 className="tx-si-c-trade-h">What can you <br></br>trade with CTrader?</h1>
                                <p className="tx-si-c-trade-p">Indices Cash CFDs.</p>
                                <p className="tx-si-c-trade-p">Soft Commodities - Future CFDs.</p>
                                <p className="tx-si-c-trade-p">Forex CFDs - The FX CFDs will have<br></br> floating spreads</p>
                                <p className="tx-si-c-trade-p">Metals and Energy - Spot Contract <br></br>CFDs (Sivler, Gold, Oil, Brent Oil,<br></br> Natural Gas)</p>

                                <Row className="tx-bt-c-trade">
                                    <Col xs="12" md="6" lg="6">
                                        <div>
                                            <Link to="https://app.ctrader.com/" target="_blank">
                                                <Button className="btn c-trade-btn">Learn More</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs="12" md="7" lg="7" className=" d-flex justify-content-center red-col">
                            <Image loading="lazy" src={img1} alt="Image" className="mob-trade-img-1" data-aos="fade-left" data-aos-duration="1500"></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}