import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import mt5star from "../images/start-tra.svg";
import mt5create from "../images/create-acc.svg";
import mt5fund from "../images/fund-acc.svg"



export default class MT4FOUR extends Component {
    render() {
        return (
            <div className="trading3-bg">
                <Container className="trading3-cont">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h1 className="fw-bo-trading3 text-center text-white">Join winsorfx in 3 steps<br></br>and start trading.</h1>
                            <Container>
                                <Row>
                                    <Col xs="4" md="4" lg="4" className="d-flex justify-content-center">
                                        <Row>
                                            <Col xs="12" md="12" lg="12">
                                            <Image loading="lazy" src={mt5create} alt="Image" className="mtcreate-img"></Image>
                                                
                                                <h4 className="text-white text-center text-base">1. Create your account</h4>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="4" md="4" lg="4" className="d-flex justify-content-center">
                                        <Row>
                                            <Col xs="12" md="12" lg="12">
                                            <Image loading="lazy" src={mt5fund} alt="Image" className="mt5fund-img"></Image>
                                                <h4 className="text-white text-center text-base">2. Fund your account</h4>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="4" md="4" lg="4" className="d-flex justify-content-center">
                                        <Row>
                                            <Col xs="12" md="12" lg="12">
                                            <Image loading="lazy" src={mt5star} alt="Image" className="mt5st-img"></Image>
                                                <h4 className="text-white text-center text-base">3. Start trading straight away</h4>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
