import React, { Component } from "react";
import { Screener } from "react-ts-tradingview-widgets";

export default class Screener1Mob extends Component {
    render() {
        return (
            <div>
                <Screener colorTheme="light" width="auto" height={750}></Screener>
            </div>
        )
    }
}