import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from '../components/Navbar';
import Footer from '../components/Footer'
import AboutTwoOne from './Components/AboutTwo-one';
import AboutTwoTwo from './Components/AboutTwo-two';
import AboutTwoThree from './Components/AboutTwo-three';
import AboutTwoFour from './Components/AboutTwo-Four';
import AboutTwoFive from './Components/AboutTwo-Five';
import AboutTwoSix from './Components/AboutTwo-Six';
import AboutTwoSeven from './Components/AboutTwo-Seven';
import AboutTwoEight from './Components/AboutTwo-Eight';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function GlobalOffering() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <AboutTwoOne />
        <AboutTwoTwo />
        <AboutTwoThree />
        <AboutTwoFour />
        <AboutTwoFive />
        <AboutTwoSix />
        <AboutTwoSeven />
        <AboutTwoEight />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default GlobalOffering;
