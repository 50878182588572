import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import blg_img from "../images/bull.png";
import blg_img1 from "../images/bear.png";
import red__img from "../images/red-line.png";
import blue_img from "../images/blue-line.png";

export default class ShareFour extends Component {
  render() {
    return (
      <div className="share-four-align">
        <div></div>
        <Container fluid className="no-pad">
          <Row>
            <Col xs="12" md="12" lg="12">
              <h1 className="text-center section4-head">Powerful Share Analysis Tools</h1>
            </Col>
          </Row>
          <div className="share-four-bg">
            <Container fluid>
            <Row>
              <Col xs="12" md="6" lg="6">
                <div className="bloger-sect text-center">
                  <h4>Bloggers Opinions</h4>
                  <div className="card-1" data-aos="slide-left" data-aos-duration="1300">
                    <Container>
                      <div className="card-out">
                        <div className="card-sp">
                          <Row>
                            <Col xs="6" md="6" lg="6">
                              <div className="blog-fex">
                                <Image loading="lazy"
                                  src={blg_img}
                                  alt="Image"
                                  className="bull-img"
                                ></Image>
                                <p>APPLE SENTIMENT</p>
                                <h5>89%</h5>
                              </div>
                            </Col>
                            <Col xs="6" md="6" lg="6">
                              <div className="blog-fex">
                                <Image loading="lazy"
                                  src={blg_img}
                                  alt="Image"
                                  className="bull-img"
                                ></Image>
                                <p>SECTOR SENTIMENT</p>
                                <h5>71%</h5>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Container>
                  </div>
                  <p className="card-1-cont">
                    Bloggers’ Opinions provides access to the opinions of 50,000
                    financial bloggers so traders can determine sentiment on
                    assets. Traders can follow their stock picks and turn their
                    knowledge into actionable trading opportunities. Using
                    state-of-the-art technology, we identify the experts and
                    bloggers that consistently pick the best stocks to
                    outperform the market.
                  </p>
                </div>
              </Col>
              <Col xs="12" md="6" lg="6">
                <div className="hedge-funds-sect text-center">
                  <h4>Hedge Funds Investment Confidence</h4>
                  <div className="card-1" data-aos="slide-right" data-aos-duration="1300">
                    <Container>
                      <div className="card-out">
                        <div className="card-sp1">
                          <Row>
                            <Col xs="12" md="12" lg="12">
                              <div className="blog-fex">
                                <h5 className="trn-txt">Trend{'\u00A0'}{'\u00A0'}<span className="red-bx"><i class="fa-solid fa-caret-down"></i>{'\u00A0'}Decreased</span></h5>
                                <p className="trend-cont">By 952M shares in the last quarter</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Container>
                  </div>
                  <p className="card-1-cont">
                    The Hedge Funds Confidence tool uses data from the SEC to
                    monitor the stocks hedge funds are buying and selling to
                    indicate sentiment. It shows whether the popularity of a
                    stock has trended higher or lower with fund managers over
                    the past few quarters. Traders can also see how hedge fund
                    managers are trading the stock: whether they changed an
                    existing position or started a new one.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6" lg="6">
                <div className="insider-sect text-center">
                  <h4>Insider Trades</h4>
                  <div className="card-1" data-aos="slide-left" data-aos-duration="1300">
                    <Container>
                      <div className="card-out">
                        <div className="card-sp1">
                          <Row>
                            <Col xs="12" md="12" lg="12">
                              <div className="blog-fex">
                                <h5 className="trn-txt">Trend{'\u00A0'}{'\u00A0'}<span className="red-bx"><i class="fa-solid fa-caret-down"></i>{'\u00A0'}Sold</span></h5>
                                <p className="trend-cont">Worth 1,350,750 in the last 3 months</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Container>
                  </div>
                  <p className="card-1-cont">
                    The Insider Trades tool highlights the increase and decrease
                    in shareholdings for over 36,000 company insiders. This
                    allows traders to make a more informed decision on their
                    positions. It collects, evaluates and presents the latest
                    transactions made by insiders. The transaction history shows
                    you individual buys and sells, who's behind them, what their
                    role is in the company and how their trades rank.
                  </p>
                </div>
              </Col>
              <Col xs="12" md="6" lg="6">
                <div className="analyst-sect text-center">
                  <h4>Analyst Recommendations</h4>
                  <div className="card-1" data-aos="slide-right" data-aos-duration="1300">
                    <Container>
                      <div className="card-out">
                        <div className="card-sp2">
                          <Row>
                            <Col xs="6" md="6" lg="6">
                              <div className="">
                                <Image loading="lazy"
                                  src={blg_img1}
                                  alt="Image"
                                  className="bull-img"
                                ></Image>
                              </div>
                            </Col>
                            <Col xs="6" md="6" lg="6">
                              <div className="blog-fex-end">
                                <Image loading="lazy"
                                  src={blg_img}
                                  alt="Image"
                                  className="bull-img"
                                ></Image>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" md="12" lg="12">
                              <div className="">
                                <Image loading="lazy"
                                  src={red__img}
                                  alt="Image"
                                  className="red-prog"
                                ></Image>
                                <Image loading="lazy"
                                  src={blue_img}
                                  alt="Image"
                                  className="blue-prog"
                                ></Image>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" md="6" lg="6">
                              <div className="">
                                <h5 className="red-sold-txt">Sold 15.5%</h5>
                              </div>
                            </Col>
                            <Col xs="6" md="6" lg="6">
                              <div className="">
                                <h5 className="blue-sold-txt">Sold 85.5%</h5>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Container>
                  </div>
                  <p className="card-1-cont">
                    Analyst Recommendations presents what analysts think of key
                    Stocks. It allows you to search through top Wall Street
                    analysts, filter them by their star rating, and get their
                    position on the biggest stocks. Every single analyst
                    featured on the tool is measured on accuracy to determine
                    their Star Rating. The Star Rating is between 0 and 5, with
                    5 Stars the highest ranking an analyst can have.
                  </p>
                </div>
              </Col>
            </Row>
            </Container>
          </div>
        </Container>
      </div>
    );
  }
}
