import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/trading-img1.png";
import img2 from "../images/trading-img2.png";
import img3 from "../images/trading-img3.png"

export default class TradingTwo extends Component {
    render() {
        return (
            <div>
                <h1 className="text-center tx-cl-re mr-re-trade-2">Why should you choose to <br></br>trade with Winsorfx?</h1>

                <Container fluid className="bg-trading-two">
                    <Row>
                        <Col xs="4" md="4" lg="4" data-aos="fade-right" data-aos-duration="1500">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <h6 className=" text-center mr-tp-trading-5">Global</h6>

                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="zoom-in" data-aos-duration="1800">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img2} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <h6 className="text-center mr-tp-trading-5">Safe</h6>

                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="fade-left" data-aos-duration="1500"> 
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img3} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <h6 className="text-center mr-tp-trading-5">Regulated</h6>

                        </Col>
                    </Row>
                    <h6 className="text-center trading-txt-6">No matter how much you know, how much money you have, or how you trade, we made
                       <br></br> trading a great experience for you...</h6>
                </Container>

            </div>
        )
    }
}