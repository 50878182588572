import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import pamm_img_one from '../images/pamm-one.svg'
import pamm_img_two from '../images/pamm-two.svg'
import pamm_img_three from '../images/pamm-three.svg'

export default class PammFour extends Component {
    render() {
        return (
            <Container>
                <h6 className="text-[#455A64] text-center">An extra source of revenue is provided by Winsorfx Copytrading: create a Master Trader Account
                    and explain your plan then set your incentive so that others can copy your trades.</h6>
                <div className="pamm-card-align">
                    <Row>
                        <Col xs="12" sm="12" md="4" lg="4" className="text-center" data-aos="fade-right" data-aos-duration="1200">
                            <Card className="pamm-card-design">
                                <Card.Img src={pamm_img_one} alt="Image" className="pamm-img text-center" />
                                <Card.Body>
                                    <p className="text-[#455A64]">Establish several Master Accounts for various approaches</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4" className="text-center" data-aos="zoom-in" data-aos-duration="1800">
                            <Card className="pamm-card-design">
                                <Card.Img src={pamm_img_two} alt="Image" className="pamm-img text-center" />
                                <Card.Body>
                                    <p className="text-[#455A64]">Monitor accurate data for your orders and the commission of the Master in the Master Area</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="4" lg="4" className="text-center" data-aos="fade-left" data-aos-duration="1200">
                            <Card className="pamm-card-design">
                                <Card.Img src={pamm_img_three} alt="Image" className="pamm-img text-center" />
                                <Card.Body>
                                    <p className="text-[#455A64]">Experience the advantage of no extra commission imposed by WinsorFx</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}