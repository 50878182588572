import { React, Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Image } from "react-bootstrap"
import './compstyle.css'
import prize from "../images/prize.svg";
export default class DemoContestOne extends Component {
    render() {
        return (
            <div className="index-bg">
                <Container >
                    <Row className="">
                        <Col lg="6" md="6" sm="12" className="">
                            <h6 className="index-trade">TRADE YOUR WAY TO THE TOP AND RECEIVE SPECIAL MEGA PRIZES WITH</h6>
                            <h1 className="global-chat">PRIZE FUND FOR<br />
                                DEMO TRADE COMPETITION <b className="rupesss">$3000</b><br />

                            </h1>
                            <h6 className="top-trade">TOP 25 WILL RECEIVE GUARANTEED PRICES</h6>
                            <div>
                                <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                    <Button className="btn-1">JOIN DEMO COMPETITION</Button>
                                </Link>
                            </div>
                            <p className="start-trade">*Terms and Conditions apply</p>

                        </Col>
                        <Col lg="6" md="6" sm="12" className="rewar-2 left-prize">
                            <Image loading="lazy" src={prize} alt="Image" className="reward"></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}