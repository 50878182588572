import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';


export default class ShareEight extends Component {
    render() {
        return (

            <div className="forex-re-7">
                <Container>

                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                            <Accordion id="faq-acc">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are the main advantages of trading in shares?</Accordion.Header>
                                    <Accordion.Body>
                                    Trading in shares can offer many advantages, including potential for capital growth, income through dividends, and 
                                    access to a wide variety of investments. Additionally, trading in shares can provide access to investments that may
                                     be difficult to access with other asset classes, such as small cap stocks or foreign companies.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What type of fees are associated with trading in shares?</Accordion.Header>
                                    <Accordion.Body>
                                    Fees associated with trading in shares can vary widely, depending on the type of platform and 
                                    broker you use. Generally, there are commission fees charged for each trade and other fees such
                                     as annual platform fees or inactivity fees.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How do I know when to buy and sell shares?</Accordion.Header>
                                    <Accordion.Body>
                                    Knowing when to buy and sell shares can be very difficult and requires a lot of research and 
                                    analysis. It is important to understand the company and the industry it operates in, as well as
                                     consider factors such as price-to-earnings ratio, dividend yield, and potential catalysts.
                                      Additionally, it is important to consider the risk-reward profile of a share and your own risk
                                       tolerance.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What is the best way to diversify my portfolio?</Accordion.Header>
                                    <Accordion.Body>
                                    The best way to diversify your portfolio is to invest in a variety of asset classes and securities.
                                     This can include shares, bonds, commodities, and other investments. Additionally, diversifying 
                                     across different industries and countries can help mitigate risk.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>What types of safety measures are in place to protect my investments?</Accordion.Header>
                                    <Accordion.Body>
                                    Most regulated brokers and exchanges have safety measures in place such as investor protection 
                                    funds, margin requirements, and real-time monitoring of trading activity. Additionally, many 
                                    brokers have additional safety measures such as segregated accounts and additional protection for
                                     large trades.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move">
                    <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}