import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/ib-flow-man2.svg";
import $ from 'jquery/src/jquery';

export default class IBOne extends Component {

    jQuerycode = () => {
        $(document).ready(function () {
            setTimeout(function () {
                $("#ib-link").addClass("nav-links-active");
            }, 500);
        })
    }
    componentDidMount() {
        this.jQuerycode()
    }

    render() {
        return (
            <div className="ib-one-bg bg-white">
                <div className="ib-two-bg-nw flex-class-nw">
                    <Container>
                        <div className="ib-head-align">
                            <h1 className="text-center text-5xl font-bold ib-one-head">
                                WINSORFX AFFILIATE PROGRAM
                            </h1>
                            <h4 className="text-center font-normal text-[18px] ib-one-text">Understand the entire Master Affiliate Commission</h4>
                            <div className="text-center">

                                <Button className="btn ib-trade-btn tes-by"><a href="https://my.winsorfx.com/register.php" className="trade-btn-hyper">Trade Forex</a></Button>
                            </div>
                        </div>
                    </Container>
                </div>

                <div className="ib-three-bg">
                    <Container fluid className="ib-one-cont">
                        <Row className="ib-one-row">
                            <Col xs="12" md="6" lg="6" className="justify-content-center">
                                <Row>
                                    <h4 className="pamm-one-head4 text-4xl font-medium text-left">AFFILIATE COMMISSION <br></br> TIERS</h4>
                                    <Col xs="12" md="4" lg="4" className="justify-content-center d-flex mb-tes">
                                        <Container className="ib-one-cont2" id="ib-one-level">
                                            <h4 className="pamm-one-head2 text-2xl font-medium text-center">LEVEL</h4>
                                            <h4 className="pamm-one-head2 text-7xl font-bold text-center">01</h4>
                                            <h4 className="pamm-one-head3 text-xl font-normal text-right">$6 PER</h4>
                                            <h4 className="pamm-one-head3 text-xl font-normal text-right">LOT</h4>
                                            <h4 className="pamm-one-head3 text-xl font-normal text-right">TRADED</h4>
                                        </Container>
                                    </Col>
                                    <Col xs="12" md="4" lg="4" className="justify-content-center mb-tes">
                                        <Container className="ib-one-cont2" id="ib-one-level">
                                            <h4 className="pamm-one-head2 text-2xl font-medium text-center">LEVEL</h4>
                                            <h4 className="pamm-one-head2 text-7xl font-bold text-center">02</h4>
                                            <h4 className="pamm-one-head3 text-xl font-normal text-right">$3 PER</h4>
                                            <h4 className="pamm-one-head3 text-xl font-normal text-right">LOT</h4>
                                            <h4 className="pamm-one-head3 text-xl font-normal text-right">TRADED</h4>
                                        </Container>

                                    </Col>
                                    <Col xs="12" md="4" lg="4" className="justify-content-center mb-tes">
                                        <Container className="ib-one-cont2" id="ib-one-level">
                                            <h4 className="pamm-one-head2 text-2xl font-medium text-center">LEVEL</h4>
                                            <h4 className="pamm-one-head2 text-7xl font-bold text-center">03</h4>
                                            <h4 className="pamm-one-head3 text-xl font-normal text-right">$1 PER</h4>
                                            <h4 className="pamm-one-head3 text-xl font-normal text-right">LOT</h4>
                                            <h4 className="pamm-one-head3 text-xl font-normal text-right">TRADED</h4>
                                        </Container>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" md="6" lg="6" className="justify-content-center d-flex mb-tes-ib" data-aos="zoom-in-up"
                                data-aos-duration="2000">
                                <Image loading="lazy" src={img1} alt="Image" className="ib-one-img"></Image>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}