import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import INTRO from './components/Ctrader/1-intro';
import BENEFITS from './components/Ctrader/2-benefits';
import TRADE from './components/Ctrader/3-Trade';
import { useEffect } from 'react';
import CTraderAcc from './components/CTrader-Acc';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function Ctrader() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - CTrader';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <INTRO />
        <BENEFITS />
        <TRADE />
        <CTraderAcc />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default Ctrader;
