import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Table from 'react-bootstrap/Table';

export default class AboutTwoFive extends Component {
    render() {
        return (
            <div className="abt-table-align-five">
                <Container>
                    <div className="abt-five-table">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr className="table-align-four">
                                    <td>Company / PMs</td>
                                    <td>Wire Transfer</td>
                                    <td>Credit Card</td>
                                    <td>Skrill/Neteller</td>
                                    <td>PayPal</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="table-align-four">
                                    <td>Europe</td>
                                    <td>100 USD/GBP/ 20 EUR</td>
                                    <td>10 USD/GBP/ 20 EUR</td>
                                    <td>5 USD/GBP/EUR</td>
                                    <td>10 USD/GBP/EUR</td>
                                </tr>
                                <tr className="table-align-four">
                                    <td>UK</td>
                                    <td>100 USD/GBP/ 20 EUR</td>
                                    <td>10 USD/GBP/EUR</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                                <tr className="table-align-four">
                                    <td>Africa</td>
                                    <td>10 USD or 100 ZAR</td>
                                    <td>5 USD/50 ZAR</td>
                                    <td>5 USD/50 ZAR</td>
                                    <td>N/A</td>
                                </tr>
                                <tr className="table-align-four">
                                    <td>Australia</td>
                                    <td>100 USD/GBP/EUR</td>
                                    <td>10 USD/GBP/EUR</td>
                                    <td>5 USD/GBP/EUR</td>
                                    <td>N/A</td>
                                </tr>
                                <tr className="table-align-four">
                                    <td>BVI</td>
                                    <td>100 USD/GBP/EUR</td>
                                    <td>10 USD/GBP/EUR</td>
                                    <td>5 USD/GBP/EUR</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </Table>
                        <p>* Withdrawals</p>
                    </div>
                </Container>
            </div>
        )
    }
}