import { React, Component } from "react";
import { Row, Col, Container, Image, Accordion } from "react-bootstrap";
import './compstyle.css'
import faq from '../images/faqqq.svg'

export default class DemoContestSeven extends Component {
    render() {
        return (
            <div className="">
                <Container>
                    <h5 class="line-1 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> FAQ</h5>
                </Container>
                <div className="con-bg dsd">
                    <Container className="index-last">
                        <Row>
                            <Col lg="6" md="6" xs="12">
                                <Accordion className="acc-change">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="colos">Can monthly prize winners withdraw the cash prize?</Accordion.Header>
                                        <Accordion.Body>
                                            As an AI language model, I don't have specific information
                                            about the policies or terms and conditions of individual contests or competitions.
                                            The ability to withdraw cash prizes can vary depending on the rules set by the organization
                                            running the contest. It's best to consult the official rules or contact the contest organizers
                                            directly to get accurate information about the withdrawal process for monthly prize winners.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <br />
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header className="colos"> How many people won the demo contest?</Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="ul">
                                                <li>Top 25: Prizes are guaranteed for the top 25 competitors in each round of the demo competition.</li>
                                                <br />
                                                <li>Each winner will get a cash prize of $3000 for each competition round.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <br />
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header className="colos">How do I participate?</Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="ul">
                                                <li>You must first register on our website for the demo competition.</li>
                                                <li>Download the multi-award-winning C-Trader.</li>
                                                <li>Start trading with a $1000 virtual balance.</li>
                                                <li>Get to the top and get your reward…</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <br />
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header className="colos"> What is the prize money for the top 25 demo competition winners?</Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="ul">
                                                <li>TOP 1 WINNER RECEIVES $500.</li>
                                                <li>EVERY TOP 2-5 WINNERS RECEIVES $250.
                                                </li>
                                                <li>EVERY TOP 6-10 WINNES RECEIVES $100.</li>
                                                <li>EVERY TOP 11–20 WINNERS GETS $50.</li>
                                                <li>EVERY TOP 21–25 WINNERS RECEIVES $25.</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>
                            </Col>
                            <Col lg="6" md="6" xs="12">

                                <Image loading="lazy" src={faq} alt="Image" className="faq"></Image>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}