import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
// import img2 from "../images/master-three-img.png";
import img2 from "../images/account-setting.svg";

export default class Masterthree extends Component {
  render() {
    return (
      <div className="master-three-bg">
        <Container>
          {" "}
          <Row>
            <Col
              xs="12"
              md="6"
              lg="6"
              className="justify-content-center d-flex"
            >
              <Image loading="lazy" src={img2} alt="Image" className="cmpy-img-copy" data-aos="fade-right" data-aos-duration="1500" />
            </Col>
            <Col
              xs="12"
              md="6"
              lg="6"
              className="justify-content-center cpy-s2-lft"
            >
              <div className="">
                <h4 className="copier-tx-mr-mstr-s3">Account settings</h4>
                <p className="copier-tx-p-mstr">
                  <span className="mstr-bold">Get your Master Account ready for copiers:</span> set your commission
                  amount and describe your strategy.
                </p>
                <p className="copier-tx-p-mstr">
                 <span className="mstr-bold">Set the maximum loss limit:</span> Setting a maximum loss limit for your Master Account will help to protect it from large losses. This will ensure that your account is not exposed to too much risk at any given time.
                </p>
                {/* <p className="copier-tx-p-mstr">
                Set the maximum risk per trade: You can also set a limit for the maximum risk per trade. This will ensure that each trade is not overly risky and that your Master Account is not exposed to too much risk.
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
