import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from '../images/mob-lst-new.png'
import appstore from '../images/down-appstore.svg'
import playstore from '../images/down-playstore.svg'

export default class MobileTradingFive extends Component {
    render() {
        return (
            <div className="mobile5-bg mobile5-top-cut">
                <Row className="mobile5-img-new">
                    <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
                        <Image loading="lazy" src={img1} alt="Image" className="mobile5-img-mobile" data-aos="slide-left" data-aos-duration="1500" />
                    </Col>
                </Row>
                <Container className="mobile5-bg2 mb-8">
                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
                            {/* <Image loading="lazy" src={img1} alt="Image" className="mobile5-img mobile5-img-new"/> */}
                        </Col>
                        <Col xs="12" md="5" lg="5" className="align-items-center">
                            <Container className="dowload-cont-new">
                                <h1 className="text-left text-light">Download the App</h1>
                                <p className="fw-trading text-left text-light">Get started by downloading the app now.</p>
                                <Row id="apps-links">
                                    <Col xs="12" md="4" lg="4" className="justify-content-center">
                                        <a href="https://apps.apple.com/in/app/ctrader/id767428811"><Image loading="lazy" src={appstore} alt="Image" className="app-btn" /></a>
                                    </Col>
                                    <Col xs="12" md="4" lg="4" className="justify-content-center">
                                        <a href="https://play.google.com/store/apps/details?id=com.spotware.ct"><Image loading="lazy" src={playstore} alt="Image" className="app-btn2" /></a>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
