import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer'
import { useEffect } from 'react';
import React from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import DuelContestOne from './components/DuelContestOne';
import DuelContestTwo from './components/DuelContestTwo';
import DuelContestThree from './components/DuelContestThree';
import DuelContestFour from './components/DuelContestFour';

function DuelDemoContest() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - DuelDemoContest';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div className='clsmnu'>
                <DuelContestOne />
                <DuelContestTwo />
                <DuelContestThree />
                <DuelContestFour />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default DuelDemoContest