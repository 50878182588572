import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import phoneone_img from "../images/home2new.png"
import cardimg1 from "../images/card-1.png";
import cardimg2 from "../images/eurocrd.png";
import cardimg3 from "../images/yencrd.png";
import pencil_img from "../images/pencil.svg"
import dollar_one from "../images/dollar.svg"
import trading_img from "../images/trading.svg"
import '../components/compstyle.css'

export default class SecTwo extends Component {
    render() {
        return (
            <div>
                <Container>
                    <Row className="align-items-center mr-new-change">
                        <Col className='d-flex justify-content-center dir-flx' xs="12" md="6" lg="6">
                            <Image loading="lazy" src={cardimg1} alt="Image" className="crd-img1" data-aos="fade-right" data-aos-duration="2000"></Image>
                            <Image loading="lazy" src={cardimg2} alt="Image" className="crd-img3" data-aos="fade-left" data-aos-duration="2000"></Image>
                            <Image loading="lazy" src={phoneone_img} alt="Image" className="open-acc-img" data-aos="fade-down" data-aos-duration="2000"></Image>
                            <Image loading="lazy" src={cardimg3} alt="Image" className="crd-img2" data-aos="fade-right" data-aos-duration="2000"></Image>
                        </Col>
                        <Col className='' xs="12" md="6" lg="6">
                            <h1 className="text-red-500 font-semibold common-mb">Opening Account <b className="text-dark font-medium font-semibold">With</b><br></br><h1 className="text-dark">An Ease</h1></h1>
                            <p className="sec-two-para common-mb">You can easily create an account in Winsorfx by applying these 3 steps given below.</p>
                            <div className=''>
                                <Row className="common-mb">
                                    <Col xs="2" md="2" lg="2">
                                        <Image loading="lazy" src={pencil_img} alt="Image" className="tick"></Image>
                                    </Col>
                                    <Col xs="10" md="10" lg="10">
                                        <div className="border-left">
                                            <h4 className="font-semibold">1)&nbsp; Create Account</h4>
                                            <p>You can register yourself on Winsorfx and create account</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="common-mb">
                                    <Col xs="2" md="2" lg="2">
                                        <Image loading="lazy" src={dollar_one} alt="Image" className="dollar"></Image>
                                    </Col>
                                    <Col xs="10" md="10" lg="10">
                                        <div className="border-left">
                                            <h4 className="font-semibold">2)&nbsp; Make Deposit</h4>
                                            <p>After registration you can deposit the minimum amount for trading.</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="common-mb">
                                    <Col xs="2" md="2" lg="2">
                                        <Image loading="lazy" src={trading_img} alt="Image" className="trading-img"></Image>
                                    </Col>
                                    <Col xs="10" md="10" lg="10" className="common-mb">
                                        <div className="border-left">
                                            <h4 className="font-semibold">3)&nbsp; Start Trading</h4>
                                            <p>Start trading on Winsorfx….</p>
                                        </div>
                                    </Col>
                                    <Col xs="12" md="12" lg="12">
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn open-acc-btn">Start Trading</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }
}