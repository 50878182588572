import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer'
import CopierThree from './components/Copier-three';
import CopierSix from './components/Copier-six';
import CopierSeven from './components/Copier-seven';
import CopierFive from './components/Copier-five';
import CopierOne from './components/Copier-one';
import CopierTwo from './components/Copier-two';
import CopierEight from './components/Copier-eight';
import CopierNine from './components/Copier-nine';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function CopierAccountPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Copier Account';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
      <CopierOne />
      <CopierTwo />
      <CopierThree />
      <CopierFive />
      <CopierSix />
      <CopierEight />
      <CopierNine />
      <CopierSeven />
      <Footer />
      <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default CopierAccountPage;
