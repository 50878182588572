import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import mt5lo from "../images/mt5-logo.svg"


export default class MT5ONE extends Component {
    render() {
        return (
            <div className="mt-5-one">
                <Container className="mt-5-sp">
                    <Row>
                        <Col xs="12" md="6" lg="6">
                            <h1 className="fw-bo-mt5">MetaTrader 5</h1>
                            <p className="mr-mt-5">Metatrader 5 is a powerful upgrade and the most advanced online trading platform from
                                MetaQuotes Software ever. MT5 is a multi-asset derivatives platform for trading on CFDs.
                                It's a tuned-up, faster version of MT4 which enables winsorfx.com traders to perform hedging
                                and netting, and delivers more technical indicators as well as more insight with market depth
                                and a wider number of timeframes.</p>
                            <Row>
                                <Col xs="12" md="4" lg="4">
                                    <div>
                                        <Button className="btn mt5-sing">Sign Up</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
                            <Image loading="lazy" src={mt5lo} alt="Image" className="mt-5-logo-img" />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}