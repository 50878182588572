import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from '../components/Navbar';
import Footer from '../components/Footer'
import AboutThreeOne from './Components/AboutThree-one'
import AboutThreeTwo from './Components/AboutThree-two'
// import AboutThreeThree from './Components/AboutThree-three'
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function LegalPack() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Legal Pack';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
      <AboutThreeOne />
      <AboutThreeTwo />
      {/* <AboutThreeThree /> */}
      <Footer />
      <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default LegalPack;
