import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Table from 'react-bootstrap/Table';

export default class AboutTwoFour extends Component {
    render() {
        return (
            <div className="abt-table-align-four">
                <Container>
                    <h1 className="abt-txt-red">Deposits & Withdrawals</h1>
                    <h6 className="abt-txt-mr-re">Fast, safe and secure deposits and withdrawals assured</h6>
                    <div className="abt-four-table">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr className="table-align-three-new">
                                    <td>Company / PMs</td>
                                    <td>Wire Transfer</td>
                                    <td>Credit Card</td>
                                    <td>Skrill/Neteller</td>
                                    <td>PayPal</td>
                                    <td>Local Bank transfer</td>
                                    <td>Ideal</td>
                                    <td>Sofort</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="table-align-three-new">
                                    <td>Europe</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                </tr>
                                <tr className="table-align-three-new">
                                    <td>UK</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                                <tr className="table-align-three-new">
                                    <td>Africa</td>
                                    <td>100 USD / 1,000 ZAR</td>
                                    <td>100 USD / 1,000 ZAR</td>
                                    <td>100 USD / 1,000 ZAR</td>
                                    <td>N/A</td>
                                    <td>100 USD / 1,000 ZAR</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                                <tr className="table-align-three-new">
                                    <td>Australia</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>N/A</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                                <tr className="table-align-three-new">
                                    <td>BVI</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>N/A</td>
                                    <td>100 USD/ EUR/ GBP</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </Table>
                        <p>* Deposits</p>
                    </div>
                </Container>
            </div>
        )
    }
}