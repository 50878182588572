import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class AboutSevenTwo extends Component {
    render() {
        return (
            <div className="bg-white faq2-bg">
                <Container>
                    {/* <Row>
                        <Col xs="12" md="12" lg="12">
                           <h1 className="text-center">Still have a question?</h1>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xs="12" md="1" lg="1" className="justify-content-center"></Col>

                        <Col xs="12" md="5" lg="5" className="justify-content-center">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="justify-content-end d-flex text-btn-center" data-aos="fade-down" data-aos-duration="1000">
                                    <a href="javascript:;" className="faq-2-text text-center text-dark">About Us</a>
                                </Col>
                                <Col xs="12" md="12" lg="12" className="justify-content-end d-flex text-btn-center" data-aos="fade-down" data-aos-duration="1200">
                                    <a href="javascript:;" className="faq-2-text text-center text-dark">Data Protection</a>
                                </Col>
                                <Col xs="12" md="12" lg="12" className="justify-content-end d-flex text-btn-center" data-aos="fade-down" data-aos-duration="1200">
                                    <a href="javascript:;" className="faq-2-text text-center text-dark">Trading & Platform</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="5" lg="5" className="justify-content-center d-flex">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="justify-content-start d-flex text-btn-center" data-aos="fade-up" data-aos-duration="1000">
                                    <a href="javascript:;" className="faq-2-text text-center text-dark">Account & Verification</a>
                                </Col>
                                <Col xs="12" md="12" lg="12" className="justify-content-start d-flex text-btn-center" data-aos="fade-up" data-aos-duration="1200">
                                    <a href="javascript:;" className="faq-2-text text-center text-dark">Deposits</a>
                                </Col>
                                <Col xs="12" md="12" lg="12" className="justify-content-start d-flex text-btn-center" data-aos="fade-up" data-aos-duration="1200">
                                    <a href="javascript:;" className="faq-2-text text-center text-dark">Withdrawals</a>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs="12" md="1" lg="1" className="justify-content-center"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}