import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default class AboutSixTwo extends Component {
    render() {
        return (
            <div className="bg-white-two-stay about6-two-bg">
                <Container>
                    <h5 className="text-center head-styinf-title font-medium">
                        How to protect yourself online?
                    </h5>
                    <Row data-aos="fade-up" data-aos-duration="1200">
                        <h5 className="text-left text-black  font-normal">Phishing</h5>
                        <p className="text-left text-black text-base font-normal">Fraudsters commonly attempt to obtain your personal information by impersonating a trusted company and/or presenting themselves as its
                            employees, using similar email domains. Watch out for suspicious emails where the domains appear similar to an official source but with a
                            tweak. These emails often contain spelling mistakes or improper formatting.</p>
                    </Row>


                    <Row data-aos="fade-up" data-aos-duration="1200">
                        <h5 className="text-left text-black font-normal">Secure browsing</h5>
                        <p className="text-left text-black  font-normal">Check that the websites domain always starts with ‘https:// secured’.</p>
                        <p className="text-left text-black  font-normal">Viruses and malware</p>
                        <p className="text-left text-black text-base font-normal">Do not download files or programs from unknown sources to any of your devices.</p>
                    </Row>



                    <Row data-aos="fade-up" data-aos-duration="1200">
                        <h5 className="text-left text-black  font-normal">Disclosing data</h5>
                        <p className="text-left text-black text-base font-normal">Never share your password details or sensitive account information with anyone (such as credit card details). winsorfx.com or other
                            reputable companies would never ask for these details. Further, make sure that your personal data is not kept on unprotected hardware
                            where it can become available to third party malware or hackers.</p>

                    </Row>


                    <Row data-aos="fade-up" data-aos-duration="1200">
                        <h5 className="text-left text-black  font-normal">Consult Regulators</h5>
                        <p className="text-left text-black text-base font-normal">We urge you to consult the respective country’s regulator website, before conducting business with any investment firms, in order to ascertain
                            which entities are licensed to provide investment services and/or investment activities and their approved domains. Additionally, most
                            Regulators maintain approved and non approved domains which will further assist you to ascertain if a domain is approved by the regulator.
                        </p>
                    </Row>


                    <Row data-aos="fade-up" data-aos-duration="1200">

                    </Row>

                    <h5 className="text-center head-styinf-title">
                        Suspicious contacts impersonating winsorfx.com
                    </h5>

                    <Row data-aos="fade-up" data-aos-duration="1200">
                        <p className="text-left text-black  font-normal">
                            winsorfx.com does not engage in any type of investment or trading advice. Our services do not extend to asset management or guaranteeing
                            profits or any types of returns on investments. Employees of winsorfx.com will never contact you to persuade you to invest or open a specific
                            position or guarantee any profits. If you receive this type of communication, you should cross check the information provided by them with
                            our company details available in the footer of our website and inform them you will report it to the local authorities.
                        </p>
                    </Row>

                    <Row data-aos="fade-up" data-aos-duration="1200">
                        <p className="text-left text-black  font-normal">
                            Under no circumstances any employees of winsorfx.com will ever contact you requesting to make a deposit and wire your money anywhere. The
                            decision to fund your account is entirely yours and we can assist you with your request by contacting Customer Support. The only time we will
                            ever contact you directly about payment is if your account funds fall below the necessary margin requirements (margin call). In the event you
                            are contacted by anyone who asks you to make a deposit or wire your money anywhere very likely, this is an attempt to defraud you.
                            winsorfx.com does not manage your account, your account is managed solely by you. It is important to mention that in relation to any payment
                            to fund your account with us, the bank account details will have as billing descriptor the company’s legal name. We note that the company’s
                            payment details and solutions are available online via our cashier.
                        </p>
                    </Row>
                    <Row data-aos="fade-up" data-aos-duration="1200">
                        <p className="text-left text-black  font-normal">
                            Furthermore, no profits are promised, and as our High Risk Investment Warning clearly states, CFDs are complex instruments and come with
                            a high risk of losing money.
                        </p>
                    </Row>

                    <Row data-aos="fade-up" data-aos-duration="1200">
                        <p className="text-left text-black  font-normal">
                            Kindly note that our contact details can always be found in the footer of our
                            website.
                        </p>
                    </Row>

                    <Row data-aos="fade-up" data-aos-duration="1200">
                        <p className="text-left text-black text-base font-normal">
                            We provide below a list with the fraudulent websites that we have identified over the years, however it is important to note that this list is not exhaustive.
                        </p>
                    </Row>


                    {/* <h5 className="text-center head-styinf-title">
                    Fraudulent Websites
                    </h5>                    
                    <div className="fardu-ul">
                        <ul className="fardulant-list-sty">
                            <li>forexgrand.com</li>
                            <li>marketsx.co.uk</li>
                            <li>asiafxi.com</li>
                            <li>getfinancial.com</li>
                            <li>iforex24.com</li>
                            <li>marketgbp.com</li>
                            <li>profxmarket.com</li>
                            <li>marketsx.fr</li>
                            <li>platform-Winsorfx.com</li>
                            <li>markets-x.com</li>
                            <li>marketsi.es</li>        
                        </ul>
                        <ul>
                            <li>bitmax4u.com</li>
                            <li>bndfin.com</li>
                            <li>marketseu.com</li>
                            <li>marketsi.co.uk</li>
                            <li>safeforex.org</li>
                            <li>marketscapital.co.uk</li>
                            <li>5markets.co</li>
                            <li>markets-prime.com</li>
                            <li>markets-vip.com</li>
                        </ul>
                    </div>
                    <div className="fraud-padd">
                    <p className="text-left text-black text-base font-normal mt-5">
                        Below we have listed some of the most common methods used by impersonators for your attention and awareness:
                    </p>

                    <ul className="fardulant-list-sty">
                        <li>
                            <p className="text-left text-black text-sm font-normal">
                                They promise guaranteed profit returns and urge investors to deposit funds via wire transfers to various bank accounts with
                                different beneficiaries.
                            </p>
                        </li>

                        <li>
                            <p className="text-left text-black text-sm font-normal">
                                They are requesting investors to make additional deposits for tax purposes so that they can proceed with the withdrawal of their
                                account balance or their deposits.
                            </p>
                        </li>

                        <li>
                            <p className="text-left text-black text-sm font-normal">
                                They provide fake documentation such as brochures, contracts and/or bank guarantees that bear unauthorised and unverified
                                electronic signatures derived from publicly available information
                            </p>
                        </li>

                        <li>
                            <p className="text-left text-black text-sm font-normal">
                                They are asking investors to share with them bank account details and/or credit card details or other personal data over the phone, which
                                is something that we will never ask you to do.
                            </p>
                        </li>

                        <li>
                            <p className="text-left text-black text-sm font-normal">
                                They present themselves as being the client’s assigned asset manager and contact person. Noting that only clients manage their
                                own accounts no accounts are managed by the Company nor its employees.
                            </p>
                        </li>

                        <li>
                            <p className="text-left text-black text-sm font-normal">
                                They falsely inform investors that the Company is closing its retail client accounts and it s upgrading all accounts to corporate and/
                                or professional accounts and therefore being qualified to receive a refund in order to entice investors to deposit funds.
                            </p>
                        </li>

                        <li>
                            <p className="text-left text-black text-sm font-normal">
                                They falsely inform you that the Company is transferring your account to another group entity, and they request your bank account
                                details and other personal information to complete the transfer.
                            </p>
                        </li>
                    </ul>
                    </div> */}
                    {/* <h5 className="text-center head-styinf-title">
                            Report a Suspicion
                        </h5>

                        <ul className="fardulant-list-sty">
                        <li>
                            <p className="text-left text-black text-sm font-normal">
                            Immediately report any suspicious activity
                            to <a className="about6-a-tag-1">legal@winsorfx.com</a>, <a className="about6-a-tag-1">privacy@winsorfx</a> and your local police.
                            </p>
                        </li>

                        <li>
                            <p className="text-left text-black text-sm font-normal">
                            This is in case you receive any unsolicited telephone calls, emails or SMS from those trying to impersonate Markets or any other reputable
                            third parties we are engaged with. For further details on the third parties in which we provide some of your information to, please see our <a className="about6-a-tag-1">Privacy Policy</a>
                            </p>
                        </li>

                        <li>
                            <p className="text-left text-black text-sm font-normal">
                            Please also contact us should you notice any unauthorised transactions in your account or suspect that a third party may have access to your
                            account information.
                            </p>
                        </li>
                        </ul> */}
                </Container>
            </div>
        )
    }
}