import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img2 from '../images/tarding-sec-5-img.png';
import Button from "react-bootstrap/Button";



export default class TradingThree extends Component {
    render() {
        return (
            <div className="tradingpg-s3-bg tradingpg-s3-bg-mobile">
                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h4 className="text-center font-semibold text-lg trading-pg-head">Brilliant tools and insights</h4>
                        </Col>
                    </Row>
                    <Row className="mobile-cont-spacenew1">
                        <Col xs="12" md="5" lg="5" className="d-flx-mobtrading d-flex justify-content-center">
                            <Image loading="lazy" src={img2} alt="Image" className="tradingpg-mob-img" data-aos="fade-left" data-aos-duration="1500" />
                        </Col>
                        <Col xs="12" md="7" lg="7" className="justify-content-center">
                            <Container className="mobile-three-1bg-tradings">
                                <ul className="trading-ul-sec5-s">
                                    <li className="bobile-txt-trading text-left">Get access to 14 integrated tools</li>
                                    <li className="bobile-txt-trading text-left">Stream live news from the trading floor as it happens</li>
                                    <li className="bobile-txt-trading text-left">Interact with the leading market experts directly on the platform</li>
                                </ul>
                                <Row>
                                    <Col xs="12" md="12" lg="12">
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn text-center our-btn-new-trading">Get</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
