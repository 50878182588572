import React, { Component } from "react";
import { SingleTicker } from "react-ts-tradingview-widgets";
import { Container } from "react-bootstrap";


export default class SingleTicker2 extends Component {
    render() {
        return (
            <div>
                <Container>
                <SingleTicker colorTheme="light" width="100%" symbol="OANDA:USDCAD"></SingleTicker>
                </Container>
            </div>
        )
    }
}