import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class IBSeven extends Component {
    render() {
        return (
            <div className="bg-black ib-seven-bg">
                <Container>
                    <Row data-aos="fade-up" data-aos-duration="2000">
                        <Col xs="12" md="12" lg="12" className="text-center">
                            <Container>
                                <h4 className="text-[#C2223D] text-2xl font-medium">SUPPORT OFFERED BY WINSORFX AFFILIATE</h4>
                                <p className="text-left font-normal text-base text-light">We always create a path for affiliates who sign up with us. In order to help you become a better affiliate, Winsorfx has a team of affiliate managers who are enthusiastically ready to discuss your campaign or throw ideas around. Send them a message via the portal in order to contact your affiliate manager.</p>
                                <Button className="btn ib-seven-btn"><a className="ib-seven-hyper" href="https://my.winsorfx.com/register.php">Become an affiliate</a></Button>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}