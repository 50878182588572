import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';


export default class TradePlatformSix extends Component {
    render() {
        return (

            <div className="forex-re-7">
                <Container>

                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                        <Container>
                            <Accordion id="faq-acc">
                                <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                    <Accordion.Header>What features does WinsorFX offer?</Accordion.Header>
                                    <Accordion.Body>
                                    WinsorFX offers a variety of features such as the ability to trade on multiple asset classes, access to advanced market analysis tools, integrated charting, and a range of trading strategies.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                    <Accordion.Header>How secure is my account with WinsorFX?</Accordion.Header>
                                    <Accordion.Body>
                                    WinsorFX takes security very seriously and uses industry standard measures to protect its clients' funds. All accounts are secured with advanced encryption techniques and stored in highly secure servers.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                    <Accordion.Header>How can I access the WinsorFX trading platform?</Accordion.Header>
                                    <Accordion.Body>
                                    WinsorFX offers a web-based trading platform that can be accessed from any internet-enabled device. The platform is easy to use and offers a comprehensive set of features tailored to the needs of the individual trader.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                    <Accordion.Header>How quickly can I withdraw money from my WinsorFX account?</Accordion.Header>
                                    <Accordion.Body>
                                    Withdrawals from WinsorFX accounts are processed quickly and securely. Depending on the method of withdrawal, it can take anywhere from a few hours to a few days for funds to be made available.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                    <Accordion.Header>What customer service options are available for WinsorFX users?</Accordion.Header>
                                    <Accordion.Body>
                                    WinsorFX provides a 24/7 customer service team who are available
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="2500">
                    <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}