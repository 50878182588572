import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer'
import TradingOne from './components/Trading-one';
import TradingThree from './components/Trading-three';
import TradingTwo from './components/Trading-two';
import TradeFour from './components/trading-four';
import TradeFive from './components/trading-five';
import TradeSix from './components/trading-six';
import TradeSeven from './components/trading-seven';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function Trading() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Trading';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <TradingOne />
        <TradingTwo />
        <TradingThree />
        <TradeFour />
        <TradeFive />
        <TradeSix />
        <TradeSeven />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default Trading;
