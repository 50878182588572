import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Ing_One from "../images/ing-img-one3.svg"

export default class IBFive extends Component {
    render() {
        return (
            <div className="ib-five-align">
                <Container>
                    <Row>
                        <Col xs="12" md="6" lg="6" data-aos="fade-right" data-aos-duration="2000">
                            <Image loading="lazy" src={Ing_One} alt="Image"></Image>
                        </Col>
                        <Col xs="12" md="6" lg="6">
                            <div className="text-section">
                                <p className="des-one grey-txt">Via the Master Affiliate Link, One Trader(IB1)
                                    visits Winsorfx website and creates an account, starts trading and sells
                                    his trades, Mastter Affiliate gets $6 per lot traded automatically.</p>
                                <p className="des-two grey-txt">Trader(IB1) wishes to refer to another trader who wants to become an
                                    affiliate(IB2) MA(Master Affiliate) would gain $3 of the lot traded</p>
                                <p className="des-three grey-txt">Trader IB2 refers to another IB3 trader who also wishes to become a
                                    'MA' affiliate who will gain $1 of the traded lot.</p>
                                <p className="des-four grey-txt">Keep a note that the said example only highlights the Master Affiliates'
                                    commission structure in order to refer to further affiliate sign-ups.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}