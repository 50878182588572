import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import ETFOne from './components/ETF-one';
import ETFTwo from './components/ETF-two';
import ETFThree from './components/ETF-three';
import ETFFive from './components/ETF-five';
import ETFFour from './components/ETF-four';
import ForexFive from './components/forex-five';
import ForexEight from './components/forex-eight'
import { useEffect } from 'react';
import ETFSix from './components/ETF.six';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function ETFsPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - ETFs';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div className='clsmnu'>
            <ETFOne />
            <ETFTwo />
            <ETFThree />
            <ETFFour />
            <ETFFive />
            <ForexFive />
            <ETFSix />
            <ForexEight />
            <Footer />
            <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
          
        </div>
    );
}

export default ETFsPage;