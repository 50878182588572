import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from '../images/Tradingplat-red-img.png';
import Button from "react-bootstrap/Button";

export default class TradingPlatformTwo extends Component {
    render() {
        return (
            <div className="saftysix-bg-new">
                <Container className="container-fluid">
                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center tradeplatform-two">
                            <Container className="dowload-cont">
                                <h1 className="fw-bo-trading-new text-left">Straight forward <br></br>Trading
                                </h1>
                                <p className="fw-trading-new text-left">Our most complete platform yet - everything you need to trade.
                                    The winsorfx.com multi-asset trading platform places you in control of every trade. The trading platform
                                    is packed with features like News & Analysis as well as a suite of powerful Sentiment, Fundamental and
                                    Technical tools for making more informed decisions. You'll also be able to watch our live News & Analysis
                                    video sessions, where our in-house experts and guests share their deep knowledge of the financial markets
                                    exclusively with winsorfx.com traders.</p>
                                <Row>
                                    <Col xs="6" md="6" lg="6" className="text-left">
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn text-center our-btn">Start Trading</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center d-flex red-col">
                            <Image loading="lazy" src={img1} alt="Image" className="mob-trade-img-2" data-aos="fade-left" data-aos-duration="1500" />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
