import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/trad-plat3-img1.png";
import img2 from "../images/trad-plat3-img2.png";
import img3 from "../images/trad-plat3-img3.png";


export default class TradePlatformThree extends Component {
    render() {
        return (
            <div>
                <Container fluid className="bg-forex-five-new">
                    <Row>
                        <Col xs="4" md="4" lg="4" data-aos="fade-right" data-aos-duration="1500">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <h4 className=" text-center mr-tp-fx-5">Multi-Asset Platform</h4>
                            <p className="text-center mr-p-fx-5">Upgraded platform rich<br></br> with features &<br></br> thousands of assets to<br></br> speculate on.</p>
                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="zoom-in" data-aos-duration="2500">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img2} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <h4 className="text-center mr-tp-fx-5">World-Class Trading Tools</h4>
                            <p className="text-center mr-p-fx-5">Market-leading fundamental,<br></br> technical and sentiment <br></br>tools.</p>
                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="fade-left" data-aos-duration="1500">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img3} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <h4 className="text-center mr-tp-fx-5">Low Spreads, Fast Execution</h4>
                            <p className="text-center mr-p-fx-5">Institutional liquidity, fast<br></br> execution.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}