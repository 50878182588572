import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import BondOne from './components/Bond-one';
import BondThree from './components/Bond-three';
import BondFour from './components/Bond-four';
import ForexFive from './components/forex-five';
import BondFive from './components/Bond-five';
import ForexEight from './components/forex-eight'
import { useEffect } from 'react';
import BondTwo from './components/Bond-two';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function BondCFDpage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Bond CFDs';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className="clsmnu">
        <BondOne />
        <BondTwo />
        <BondThree />
        <BondFour />
        <ForexFive />
        <BondFive />
        <ForexEight />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default BondCFDpage;