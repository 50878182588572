import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/new-lap-img.png";


export default class ForexFour extends Component {
    render() {
        return (
            <div className="forex-bg-4">
                <Container className="">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h1 className="text-center tx-cl-re">Why Trade Forex CFDs with Winsorfx</h1>
                            <p className="text-center tx-p-re">Competitive spreads from 0.6 pips on EUR/USD Powerful charting tools integral to the winsorfx.com intuitive
                                platform Powerful charting tools integral to the winsorfx.com intuitive platform You can trade Forex 24/5 Currency
                                pairing CFDs enables trade at a fraction of the trade's total value Trade on margin and go long or short.</p>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" className="lap-re-img" data-aos="zoom-in-up" data-aos-duration="1400"></Image>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}