import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';


export default class CTraderAcc extends Component {
    render() {
        return (
            <div className="forex-re-7">
                <Container>
                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                                <Accordion id="faq-acc">
                                    <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                        <Accordion.Header>What is CTrader?</Accordion.Header>
                                        <Accordion.Body>
                                            CTrader is a powerful and intuitive trading platform that offers advanced charting, automated trading, and fast order execution. It is available for desktop, web, and mobile versions, making it one of the most versatile trading platforms on the market.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                        <Accordion.Header>What are the advantages of using CTrader? </Accordion.Header>
                                        <Accordion.Body>
                                            CTrader offers many advantages for traders, such as enhanced charting capabilities, automated trading strategies, extensive backtesting tools, and fast order execution. It also has a wide variety of indicators and signals, which can be used to analyze the markets and make informed trading decisions.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                        <Accordion.Header>What types of orders can I place with CTrader?</Accordion.Header>
                                        <Accordion.Body>
                                            CTrader offers a wide range of order types, including market orders, limit orders, stop orders, trailing stops, and OCO (one-cancels-other) orders.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                        <Accordion.Header>Is CTrader free to use?</Accordion.Header>
                                        <Accordion.Body>
                                            CTrader is free to download and use with a supported broker. However, some brokers may charge a commission or other fees for using the platform.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                        <Accordion.Header>Is CTrader secure?</Accordion.Header>
                                        <Accordion.Body>
                                            Yes, CTrader is a highly secure trading platform. It uses SSL encryption to protect user data, and all sensitive information is securely stored on servers located in secure data centers.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="2500">
                        <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}