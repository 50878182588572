import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
// import crypto_hro_1 from "../images/etf-1.png";
import crypto_hro_1 from "../images/new-reimg-2.png";

export default class IntroOne extends Component {
  render() {
    return (
      <div className="forex-bg" id="fo-bg-re">
        <Container>
          <Row>
            <Col xs="12" md="5" lg="5">
              <h1 className="tx-cl-re-1">Introducing CFDs on Blends</h1>
              <p className="tx-cl-re-1-p">
                Expand your trading reach with the exclusive winsorfx.com stock
                Blends, which are baskets of related stocks handpicked to
                provide you with exposure to specific sectors and investment
                themes, from ESG to eCommerce, energy and tech sectors to
                investment gurus like Warren Buffett.
              </p>

              <Row>
                <Col xs="12" md="12" lg="12">
                  <Link to="https://my.winsorfx.com/register.php" target="_blank">
                    <Button className="btn text-center forex-our-btn">Trade Blends</Button>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col
              xs="12"
              md="7"
              lg="7"
              className="justify-content-center d-flex"
            >
              <Image loading="lazy"
                src={crypto_hro_1}
                alt="Image"
                className="hero-img-forex" data-aos="zoom-in-left" data-aos-duration="1200"
              ></Image>
            </Col>
          </Row>
        </Container >
      </div >
    );
  }
}
