import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';

export default class ETFSix extends Component {
    render() {
        return (
            <div className="forex-re-7">
                <Container>
                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                                <Accordion id="faq-acc">
                                    <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                        <Accordion.Header>What is an ETF?</Accordion.Header>
                                        <Accordion.Body>
                                            An ETF (Exchange Traded Fund) is a type of financial instrument that can be traded like a stock, but which contains a basket of assets such as stocks, bonds, commodities, and so on. ETFs provide investors with broad diversification and potential cost savings.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                        <Accordion.Header>How do ETFs work?</Accordion.Header>
                                        <Accordion.Body>
                                            ETFs are traded like stocks on exchanges, and the prices are determined by the supply and demand of the underlying assets in the ETF. When an investor buys shares in an ETF, they are buying a portion of the underlying assets in the ETF.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                        <Accordion.Header>What are the advantages of ETFs?</Accordion.Header>
                                        <Accordion.Body>
                                            ETFs are a cost-effective way to gain broad exposure to a variety of asset classes, such as stocks, bonds, commodities, and more. ETFs also offer tax advantages and are more liquid than many other investments.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                        <Accordion.Header>What are the risks associated with ETFs?</Accordion.Header>
                                        <Accordion.Body>
                                            ETFs involve risks similar to those of other investments, such as market, sector, or currency risk. Additionally, ETFs may be subject to tracking error, which occurs when the performance of the ETF does not match the performance of the underlying assets.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                        <Accordion.Header> Are ETFs suitable for all investors?</Accordion.Header>
                                        <Accordion.Body>
                                            While ETFs can be a good choice for many investors, it is important to make sure that they are suitable for your specific investment goals. You should always consult with a financial professional before investing in any type of security.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="2500">
                        <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}