import React, { Component } from "react";
import './compstyle.css'
import Container from 'react-bootstrap/Container';
import { Col, Row, Form, InputGroup, Image } from "react-bootstrap";
import LogoImg from '../images/logo/winsorfx-logo.svg';
import axios from "axios";
import emailjs from "@emailjs/browser";

export default class Footersection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            showMessage: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    sendEmail(email) {
        emailjs.send('service_u3g6zsa', 'winsorfx_newsletter', {
            to_email: email,
        }, 'fVybre478E2KgYL-C')
            .then(() => {
                console.log('Email sent successfully!');
                const fd = new FormData();
                fd.append('email', this.refs.email.value);
                // axios.post('http://localhost:8080/email_form.php', fd
                // ).then(res=>
                axios.post('https://my.winsorfx.com/api/contact_form_live.php', fd
                ).then(res => {
                    this.setState({ showMessage: true });
                    this.setState({ email: '' });

                }
                );
            }, (error) => {
                console.error('Error sending email:', error);
                alert('Error sending email');
            });
    }


    handleSubmit(event) {
        event.preventDefault();
        const { email } = this.state;
        this.sendEmail(email);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { email } = this.state;
        return (
            <div className="footer-bg">
                <Container>
                    <Row>
                        <Col className="justify-content-center" xs="12" lg="3" md="4">
                            <Row>
                                <Col xs="12" lg="12" md="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={LogoImg} alt="Logo" className="logo-img" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" lg="12" md="12" className="d-flex justify-content-center">
                                    <Row>
                                        <Col xs="1" lg="1" md="1"></Col>
                                        <Col xs="2" lg="2" md="2">
                                            <a href="https://www.facebook.com/winsorfx.fx" target="_blank"><i class="fa-brands fa-facebook-f brand-icon"></i></a>
                                        </Col>
                                        <Col xs="2" lg="2" md="2">
                                            <a href="https://twitter.com/winsorfx" target="_blank"><i class="fa-brands fa-twitter brand-icon"></i></a>
                                        </Col>
                                        <Col xs="2" lg="2" md="2">
                                            <a href="https://www.instagram.com/winsorfx_fx/"><i class="fa-brands fa-instagram brand-icon"></i></a>
                                        </Col>
                                        <Col xs="2" lg="2" md="2">
                                            <a href="https://www.linkedin.com/company/winsorfx-commercial-broker/"><i class="fa-brands fa-linkedin brand-icon"></i></a>
                                        </Col>
                                        <Col xs="2" lg="2" md="2">
                                            <i class="fa-brands fa-youtube brand-icon"></i>
                                        </Col>
                                        <Col xs="1" lg="1" md="1"></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="justify-content-center" xs="6" lg="3" md="4">
                            <h3 className="footer-forex">Useful Links</h3>
                            <ul className="footer-ul">
                                <li><a href="https://www.winsorfx.com/Pamm" className="footer-liks" target="_blank">PAMM</a></li>
                                <li><a href="https://www.winsorfx.com/Trading" className="footer-liks" target="_blank">Trading</a></li>
                                <li><a href="https://www.winsorfx.com/CopierAccount" className="footer-liks" target="_blank">Copy Trading</a></li>
                                <li><a href="https://www.winsorfx.com/IB" className="footer-liks" target="_blank">Promotions</a></li>
                                <li><a href="https://www.winsorfx.com/about/WhyWinsorfx" className="footer-liks" target="_blank">About Us</a></li>
                            </ul>
                        </Col>
                        <Col className="justify-content-center" xs="6" lg="3" md="4">
                            <h3 className="footer-forex">Contact</h3>
                            <ul className="footer-ul2">
                                <li><span><i class="fa-solid fa-envelope links-icons"></i></span><a href="mailto:support@winsorfx.com" className="footer-liks">support@winsorfx.com</a></li>
                                <li><span><i class="fa-solid fa-phone links-icons"></i></span><a href="tel:+44 7915 608657" className="footer-liks2">+44 7915 608657</a></li>
                                <li id="address-sm"><span><i class="fa-solid fa-location-dot links-icons"></i></span><a className="footer-liks2">Richmond Hill Road, Kingstown, St. Vincent and Grenadines</a></li>
                                <li id="address-lg"><span><i class="fa-solid fa-location-dot links-icons"></i></span><a className="footer-liks2">Richmond Hill Road, Kingstown, <br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;St. Vincent and Grenadines</a></li>
                            </ul>
                        </Col>
                        <Col className="justify-content-center" xs="12" lg="3" md="4">
                            <h3 className="footer-forex">Newsletter</h3>
                            <Form className="mb-7" id="Support-form" onSubmit={this.handleSubmit} ref={(el) => this.myFormRef = el}>
                                <InputGroup className="mb-3" id="Email-Form">
                                    <Form.Control
                                        placeholder="Enter your email"
                                        required
                                        aria-label="Enter your email"
                                        aria-describedby="basic-addon2"
                                        type="email" name="email" ref="email" value={email} onChange={this.handleChange}
                                    />
                                    <InputGroup.Text id="basic-addon2" onClick={this.handleSubmit}><a ><i class="fa-solid fa-paper-plane plane-icon text-white"></i></a></InputGroup.Text>
                                    {this.state.showMessage && <div className="col-12"><h6 className="text-white mt-2 text-left">successfully Subscribed</h6></div>}
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>

                    <Row className="footer-bootom-align">
                        <Col className="justify-content-center" xs="12" lg="12" md="12">
                            <p className="footer-para">
                                Winsorfx website provides links that will provide the best support. As an organization, we do not support any products or services or recommend them. The data held on this platform is for the sole purpose of providing information. As a consequence, a proposal or solicitation should not be used as an invitation or request to anyone in any jurisdictions where such an offer or solicitation is not allowed, nor as a suggestion to anyone whose offer or solicitation is illegal for the sell, buy or otherwise engaging with any particular currency or valuable metal business.
                            </p>
                        </Col>
                    </Row>
                    <Row className="footer-bootom-align risk-position">
                        <Col className="justify-content-center" xs="6" lg="2" md="2">
                            <a className="text-white risk-warning">RISK WARNING:</a>
                        </Col>
                    </Row>
                    <Row className="footer-bootom-align">
                        <Col className="justify-content-center" xs="12" lg="12" md="12">
                            <Container className="footer-cont-sm">
                                <p className="footer-para2 text-black">
                                    When you trade on Forex, Indices or CFDs it comes with high risk. You should assess your trading targets, level of experience and risk appetite carefully before financial decision making. Realize the risks and take action to monitor the potential risks. Before making any high investment decisions, it is recommended to take a piece of independent financial advice from the Financial Experts                                </p>
                            </Container>
                        </Col>
                    </Row>
                    <Row className="footer-bootom-align">
                        <Col className="justify-content-center" xs="12" lg="12" md="12">
                            <Container className="copyright-cont">
                                <h4 className="footer-liks-copy text-center">2023 WINSORFX | All Rights Reserved</h4>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}