import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Button from "react-bootstrap/Button";
import sec3img from "../images/mt5-commute.svg"





export default class Mt5Three extends Component {
    render() {
        return (
            <div className="mt5-two-bg">
                <Container className="mt5-three-cont">
                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
                            <Image loading="lazy" src={sec3img} alt="Image" className="mt5-three-img" />
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center">
                            <h1 className="text-left text-black text-4xl my-4">What can you trade<br></br>with MetaTrader5?</h1>

                            <h4 className="text-left text-black text-sm font-normal ml-8">Indices - Cash CFDs</h4>
                            <h4 className="text-left text-black text-sm font-normal mb-4 ml-8">Soft Commodities - Future CFDs</h4>
                            <h4 className="text-left text-black text-sm font-normal ml-8">Forex CFDs - The FX CFDs will have flooting <br></br>spreads</h4>
                            <h4 className="text-left text-black text-sm font-normal ml-8">Metals and Energy - Spot Contract CFDs <br></br>(Sivler, Gold, Oil, Brent Oil, Natural Gas)</h4>
                            <Button className="btn mt5-three-btn">Sign Up</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}