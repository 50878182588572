import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import reteimg from "../images/green-stars.svg";
import tradeimg from "../images/mt5-laptop.svg";
import googimg from "../images/google-logo.svg";
import fbimg from "../images/facebook-logo.svg";
import appleimg from "../images/apple-logo.svg";



export default class MT4SIX extends Component {
    render() {
        return (
            <div className="mt5-two-bg">
                <Container className="mt5-six-cont1 d-flex justify-content-center">
                    <span><h4 className="text-center text-black text-lg">Our customers say Excellent </h4>
                    </span><Image loading="lazy" src={reteimg} alt="Image" className="mt5-six-rate" /><span>
                        <h4 className="text-center text-black text-lg"> 4.7 out of 5 based on 794 review</h4></span>
                </Container>
                <Container className="mt5-six-cont">
                    <Row>
                        <Col xs="12" md="6" lg="6" className="d-flex justify-content-center">
                            <Container>
                                <Image loading="lazy" src={tradeimg} alt="Image" className="mt5-six-img" />
                            </Container>
                        </Col>
                        <Col xs="12" md="6" lg="6" id="mt5-form">
                            <Container className="mt5-six-cont2">
                                <h1 className="text-left text-black text-4xl my-4">Ready to trade?<br></br>Create an account!</h1>
                                <Row>
                                    <Col xs="4" md="2" lg="2" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={googimg} alt="Image" className="mt5-six-social" />
                                    </Col>
                                    <Col xs="4" md="2" lg="2" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={fbimg} alt="Image" className="mt5-six-social" />
                                    </Col>
                                    <Col xs="4" md="2" lg="2" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={appleimg} alt="Image" className="mt5-six-social" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="justify-content-center">
                                        <Form className="my-4">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label className="text-sm font-medium">Email</Form.Label>
                                                <Form.Control type="email" placeholder="" />
                                                {/* <Form.Text className="text-muted">
                                                    We'll never share your email with anyone else.
                                                </Form.Text> */}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label className="text-sm font-medium">Password</Form.Label>
                                                <Form.Control type="password" placeholder="" />
                                            </Form.Group>
                                            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="Check me out" />
                                            </Form.Group> */}
                                            <Button variant="primary" type="submit" className="create-account">
                                                Create an Account
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}