import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'

export default class AboutSixFive extends Component {
    render() {
        return (
            <div className="bg-white about6-five-bg-11">
                <Container className="container-fluid">
                    <h5 className="text-left text-black text-4xl font-medium leading-snug mb-5">
                        Summary of how to stay safe online
                    </h5>
                    <ul className="fardulant-list-sty">
                        <li data-aos="fade-up" data-aos-duration="1200">
                            <p className="text-left text-black text-sm font-normal">
                                Be cautious of unsolicited approaches via email, calls, social media etc.
                            </p>
                        </li>
                        <li data-aos="fade-up" data-aos-duration="1200">
                            <p className="text-left text-black text-sm font-normal">
                                Never give out your personal and financial information via telephone, social media platforms or emails.
                            </p>
                        </li>
                        <li data-aos="fade-up" data-aos-duration="1200">
                            <p className="text-left text-black text-sm font-normal">
                                Never transfer money to a bank account at the request of a person who states they are acting on behalf of winsorfx.com.
                            </p>
                        </li>
                        <li data-aos="fade-up" data-aos-duration="1200">
                            <p className="text-left text-black text-sm font-normal">
                                Always check whether the email address appears slightly different to the official one
                            </p>
                        </li>
                        <li data-aos="fade-up" data-aos-duration="1200">
                            <p className="text-left text-black text-sm font-normal">
                                Always check that the website is ‘https://secured’.
                            </p>
                        </li>
                        <li data-aos="fade-up" data-aos-duration="1200">
                            <p className="text-left text-black text-sm font-normal">
                                Always consult the Regulator’s website for licensed entities websites.
                            </p>
                        </li>
                        <li data-aos="fade-up" data-aos-duration="1200">
                            <p className="text-left text-black text-sm font-normal">
                                Do not download any files or programs from unknown sources to any of your devices.
                            </p>
                        </li>
                        <li data-aos="fade-up" data-aos-duration="1200">
                            <p className="text-left text-black text-sm font-normal">
                                Do not share your password and account details with anyone.
                            </p>
                        </li>
                        <li data-aos="fade-up" data-aos-duration="1200">
                            <p className="text-left text-black text-sm font-normal">
                                Reputable companies will never request for personal account or payment information.
                            </p>
                        </li>
                    </ul>
                    <Container className="about6-six-cont my-5" data-aos="zoom-in" data-aos-duration="1200">
                        <h4 className="text-left text-black text-sm font-normal">
                            Help us tackle online fraud by getting in touch with us immediately if you have any suspicions.
                        </h4>
                    </Container>
                    <h5 className="text-left text-black text-4xl font-medium leading-snug mb-5">
                        How does winsorfx.com protect your information?
                    </h5>
                    <p className="text-left text-black text-base3 font-normal" data-aos="fade-up" data-aos-duration="1200">
                        We have a strong team of professionals that are constantly monitoring unusual and irregular activities in online activities and payments. They
                        are ready to respond to any request or incident that may occur. Please contact
                    </p>
                    <p className="text-left text-black text-base3 font-normal" data-aos="fade-up" data-aos-duration="1200">
                        Our Data Protection Officer is ensuring GDPR compliance within winsorfx.com. The company is regularly audited by internal and external
                        parties against all controls and monitoring tools used to protect our systems and data. Please contact our Data Protection Office at
                        <a className="about6-a-tag"> privacy@winsorfx.com</a>
                    </p>
                    <p className="text-left text-black text-base3 font-normal" data-aos="fade-up" data-aos-duration="1200">
                        Keep monitoring this page for additional fraudulent websites identified to be impersonating the Company, the winsorfx.com brand or our
                        holding company.
                    </p>
                </Container>
            </div>
        )
    }
}