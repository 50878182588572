import { React, Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import { Row, Col } from "react-bootstrap";

export default class RiskOne extends Component {
    render() {
        return (
            <div className="copier1-bg terms-bg" id="co-bg-re">
                <Container>
                    <Row>
                        <Col xs="12" md="1" lg="1"></Col>
                        <Col xs="12" md="10" lg="10">
                            <h1 className="text-center txt-cop-h1">PRIVACY POLICY STATEMENT</h1>
                            <h6 className="text-center txt-cop-p">Privacy Policy Statement for Winsorfx Trading Platform:</h6>
                        </Col>
                        <Col xs="12" md="1" lg="1"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}