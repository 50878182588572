import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class AboutFourTwo extends Component {
    render() {
        return (
            <div className="ab-fo-two">
                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Query - Usually resolved within 48 hours</h5>
                                <p className="about-para">If you are dissatisfied with the service provided by winsorfx.com, please contact
                                    our Customer Service as soon as possible by email at <a className="blu-clo-re"> support@winsorfx.com</a> , Live Chator via our <a className="blu-clo-re">Online Queries Form </a>
                                    for immediate and prompt assistance.</p>

                                <p className="about-para-one">Our representatives are available to assist you through your concerns to
                                    reach to a fair <br></br>conclusion.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Trading Query - Usually resolved within 48 hours</h5>
                                <p className="about-para">If you have a trading query.</p>

                                <p className="about-para-one">Relating for example to your trading account and the operation of this on
                                    our trading platform winsorfx.com or with respect to our Terms and Conditions, you can submit a trade
                                    enquiry by submitting the.</p>

                                <p className="about-para-one">A dedicated team of professionals is available to research and resolve
                                    your trading query the soonest - we are committed to responding the latest within 48 hours of receipt
                                    of your Trading Query. Once your trading query has been examined you will receive a full explanation
                                    of the circumstances and the outcome.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Formal Complaint - Process can take up to 2 months to resolve</h5>
                                <p className="about-para">You are entitled to submit a complaint at any time in your trading experience
                                    with us, where you may feel that our service has not met your satisfaction. Where any trading or other
                                    query has not been addressed or when you wish to submit a formal complaint</p>

                                <p className="about-para-one">Note that all the information included on the Online Complaint Form must be
                                    completed in order for our dedicated Quality Control Department to examine your complaint in an informed
                                    manner. We may request further information and/or supportive documentation during the review process. We
                                    ask for your valued cooperation in the review process of your complaint in order to complete our work and
                                    provide you with our feedback and response the soonest possible.</p>
                            </Row>

                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <p className="about-para-one abt-compla-re">The process that we envisage will be followed in dealing with your complaint is as follows:</p>

                                <p className="about-para-one">We will acknowledge receipt of your complaint within 5 days, providing you
                                    with the recorded URN which will be quoted in all communications directed to you from the Company
                                    regarding the Complaint, while informing you that the Complaint is being investigated;</p>
                                <p className="about-para-one">We will attempt to have your complaint resolved within 2 months from the
                                    submission date of your complaint. However, it should be noted that the full completion of our review and
                                    response to your complaint shall not exceed 3 months from the submission date.</p>
                                <p className="about-para-one">In the event that we are unable to complete our investigation within 2
                                    months and provide you with a full written response, we shall inform you of the reasons for the delay.
                                    Full completion of our review and response to your complaint shall not exceed 3 months from the
                                    submission of your complaint.</p>

                                {/* <p className="about-para-one">If upon receipt of our final response on your complaint you are not
                                satisfied or in case no response is received within the 3 months' timeframe, you can refer your
                                complaint to the BVI Financial Services Commission. For more information please visit
                                <a className="blu-clo-re">http://www.bvifsc.vg</a> </p> */}
                            </Row>

                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Data Protection or Privacy Queries/Complaints</h5>
                                <p className="about-para">If you are having concerns regarding the privacy and safety of your personal
                                    data, you are entitled to submit a query or a complaint at any time during your business relationship
                                    with us. To submit a query, you may complete <a className="blu-clo-re">Online Queries Form</a></p>

                                <p className="about-para-one">formal complaint at any stage of time where concerns regarding your data
                                    privacy or data safety.</p>

                                <p className="about-para-one">To visit our Online Complaints Form please {'\u00A0'}<a className="blu-clo-re">click here.</a></p>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}