import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';


export default class CommodFive extends Component {
    render() {
        return (

            <div className="forex-re-7">
                <Container>

                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                        <Container>
                            <Accordion id="faq-acc">
                                <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                    <Accordion.Header>What is a Master Account?</Accordion.Header>
                                    <Accordion.Body>
                                    A Master Account is a trading account that is connected to a Copier Account. The Master Account is where the trading signals are generated and the Copier Account is where the signals are automatically copied.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                    <Accordion.Header>How does a Master Account work?</Accordion.Header>
                                    <Accordion.Body>
                                    A Master Account works by generating trading signals which are then automatically copied by the Copier Account. This allows the Copier Account to replicate the exact trading strategy of the Master Account.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                    <Accordion.Header>Who can open a Master Account?</Accordion.Header>
                                    <Accordion.Body>
                                    Any experienced trader who has an understanding of the markets and trading strategies can open a Master Account.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                    <Accordion.Header>What are the benefits of a Master Account?</Accordion.Header>
                                    <Accordion.Body>
                                    A Master Account allows experienced traders to share their trading strategies with other traders. This provides a great opportunity to learn from the strategies of more experienced traders.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                    <Accordion.Header>How secure is a Master Account?</Accordion.Header>
                                    <Accordion.Body>
                                    The security of a Master Account is paramount and is protected by multiple layers of encryption. All trading signals are securely transmitted to the Copier Account, ensuring that all trading activities are secure and private.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="2200">
                    <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}