import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import our_platf_s3_img from "../images/ourplatf-sec3-img.png";

export default class OurPlatThree extends Component {
  render() {
    return (
      <div>
        <Container>
          <h1 className="head-our-platf text-center t-b-s">Trade other platforms<br />
            with Winsorfx</h1>
        </Container>
        <div className="ourplatf-rit-mob-bg-new">
          <Container className="container-fluid">
            <Row>
              <Col
                xs="12"
                md="7"
                lg="7"
                className="justify-content-center tradeplatform-two"
              >
                <Container className="dowload-cont-ourplatf">
                  <h1 className="ourplatf-s3-head text-left">
                    CTrader. Your essential trading platform
                  </h1>
                  <p className="ourplat-s3-cont text-left">
                    Built with Forex trading in mind, MT4 remains one of the
                    most popular and easy-to-use trading platforms. Boasting
                    Expert Advisors, micro-lots, hedging and one-click trading,
                    our MT4 offering has everything you would expect and much
                    more.
                  </p>
                  <Row>
                    <Col
                      xs="12"
                      md="6"
                      lg="6"
                      className="text-left cent-ou7rpltf"
                    >
                      <Button className="btn text-center our-btn">
                        Learn More
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col
                xs="12"
                md="5"
                lg="5"
                className="justify-content-center d-flex"
              >
                <Image loading="lazy"
                  src={our_platf_s3_img}
                  alt="Image"
                  className="ourplatf-mobile2-img"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
