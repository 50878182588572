import React, { Component } from "react";
import { MarketOverview } from "react-ts-tradingview-widgets";
import { Container } from "react-bootstrap";


export default class MarketOverviewWidget1 extends Component {
    render() {
        return (
            <div>
                <Container>
                    <MarketOverview colorTheme="light" width="1200" height={550}></MarketOverview>
                </Container>
            </div>
        )
    }
}