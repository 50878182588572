import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';

export default class ForexFour extends Component {
    render() {
        return (
            <div className="forex-re-7">
                <Container>
                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                                <Accordion id="faq-acc">
                                    <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                        <Accordion.Header>What is a forex index?</Accordion.Header>
                                        <Accordion.Body>
                                            A forex index is a measure of the performance of a group of currencies in relation to each other.
                                            It is used to measure the relative strength of each currency against the others, and can also be
                                            used as a benchmark for trading strategies.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                        <Accordion.Header>How are forex indices created?</Accordion.Header>
                                        <Accordion.Body>
                                            Forex indices are created by combining the weighted average of a group of currencies. The weighting
                                            is usually based on the liquidity of the currency, the performance of the currency, and other
                                            factors.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                        <Accordion.Header>What are the most popular forex indices?</Accordion.Header>
                                        <Accordion.Body>
                                            The most popular forex indices are the US Dollar Index, the Euro Index, and the Japanese Yen Index.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                        <Accordion.Header>Are forex indices tradable?</Accordion.Header>
                                        <Accordion.Body>
                                            Yes, forex indices are tradable in both the spot and futures markets.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                        <Accordion.Header>How can I use forex indices to my advantage?</Accordion.Header>
                                        <Accordion.Body>
                                            Forex indices can be used to measure the relative strength of each currency in the index,
                                            as well as to inform trading strategies. By using the data provided by forex indices,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move">
                        <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}