import { React, Component } from "react";
import { Row, Col, Container, Form, InputGroup } from "react-bootstrap";
import './compstyle.css'

export default class DemoContestFive extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col lg="4" md="4" xs="12">
              <h5 class="line-1 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> Leaderboard</h5>
            </Col>
            <Col lg="4" md="4" xs="12">
            </Col>
            <Col lg="4" md="4" xs="12" className="sear">
              <InputGroup className="mb-3">

                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className="input-design input-design-bg place"
                /><InputGroup.Text id="basic-addon1" className="btn  btn-search"><i class="fa-solid fa-magnifying-glass ser text-white"></i></InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </Container>

        <Container>
          <div className="table-responsive mt-5">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Ranking</th>
                  <th scope="col">Name</th>
                  <th scope="col">Account</th>
                  <th scope="col">NoEquity</th>
                  <th scope="col">Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr className="lite-1 chnage-red">
                  <td>1</td>
                  <td>Jhony</td>
                  <td>90144971</td>
                  <td>4230448.9</td>
                  <td>4230448.9</td>
                </tr>
                <tr className="lite-pnk">
                  <td>2</td>
                  <td>Mohammad Athar</td>
                  <td>90142445</td>
                  <td>1770559.5</td>
                  <td>2223450.74</td>
                </tr>
                <tr className="lite-1">
                  <td>3</td>
                  <td>Razel</td>
                  <td>90143355</td>
                  <td>1602814.07</td>
                  <td>1802574.06</td>
                </tr>
                <tr className="lite-pnk">
                  <td>4</td>
                  <td>Alberto Luis</td>
                  <td>90145291</td>
                  <td>1188465.87</td>
                  <td>1188465.87</td>
                </tr>
                <tr className="lite-1">
                  <td>5</td>
                  <td>Ivan Arturo</td>
                  <td>90145562</td>
                  <td>1065379.22</td>
                  <td>1065379.22</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Container>
      </div>
    )
  }
}