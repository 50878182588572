import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import img4 from "../images/rank1.png";
import Button from "react-bootstrap/Button";

export default class Mastereight extends Component {
  render() {
    return (
      <div className="bg-white copier-six-bg">
        <Container className="no-pad">
          <h1 className="fw-bo-copy-head1-mstr text-center">
            Automatically copy leading traders and forget about long hours of
            building your own trading strategy
          </h1>
        </Container>
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              lg="12"
              className="justify-content-center no-pad1 mstr-1234"
            >
              <Container xs="12" md="4" lg="4" className="cpy-trd-boxx d-flex">
                <Row>
                  <Col>
                    {/* <div className="cpy-trade-pers">
                      <div className="d-flex cpy-trd-box1">
                        <div>
                          <Image loading="lazy" src={img1} alt="Image" className="op1" />
                        </div>
                        <div className="bp-box-s1">
                          <p className="personname">David Jacks</p>
                          <p className="no-mrg">Canada</p>
                        </div>
                        <div className="bp-box-s2">
                          <p className="line">| Profit</p>
                          <p className="cpy-trade-pers-grn no-mrg">$5,700</p>
                        </div>
                        <div className="bp-box-s3-mstr">
                          <p className="line">| Weekly Profit</p>
                          <p className="cpy-trade-pers-grn no-mrg">$8,700</p>
                        </div>
                        <div className="bp-box-s3-mstr">
                          <p className="line">| Gain</p>
                          <p className="cpy-trade-pers-grn no-mrg">$1,700</p>
                        </div>
                      </div>
                    </div> */}
                    <Image loading="lazy" src={img4} alt="Image" className="cmpy-img-copy-2-scr" />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="bnt-bnt1">
                <Link to="https://my.winsorfx.com/register.php" target="_blank">
                  <Button className="btn text-center our-btn">Start As Copier</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
