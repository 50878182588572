import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../../components/compstyle.css'
import Image from 'react-bootstrap/Image';
import img1 from "../../images/facebook.svg";
import img2 from "../../images/instagram.svg";
import img3 from "../../images/twitter.svg";
import img4 from "../../images/youtube.svg";

export default class AboutSixThree extends Component {
    render() {
        return (
            <div className="bg-white">
                {/* <div className="bg-white about6-three-bg">
                    <h5 className="text-center head-styinf-title1">
                        Official Websites
                    </h5>
                    <div className="about6-three-cont-1">
                        <ul className="officialweb-list-sty">
                            <li>Safecap Investments Limited</li>
                            <li>Finalto Trading Ltd</li>
                            <li>Finalto (Australia) Pty Limited</li>
                            <li>Finalto (BVI) Ltd</li>
                            <li>Finalto (South Africa) Pty Ltd</li>
                        </ul>
                    </div>
                </div> */}
                <div className="">
                    <h5 className="text-center head-styinf-title1">
                        Official Channels of Communication
                    </h5>
                </div>
                <div className="about6-three-cont off-commuin">
                    <Row className="social-links-mob">
                        <Col xs="4" md="4" lg="4" className="stying-btn justify-content-cener" data-aos="fade-right" data-aos-duration="1500">

                            <Row>
                                <Col xs="12" md="6" lg="4" className=" d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" />
                                </Col>
                                <Col xs="12" md="16" lg="8">
                                    <a href="https://www.facebook.com/winsorfx.ltd/" className="fw-pamm-1">Facebook</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="4" md="4" lg="4" className="stying-btn justify-content-cener" data-aos="fade-right" data-aos-duration="1200">

                            <Row>
                                <Col xs="12" md="6" lg="4" className=" d-flex justify-content-center">
                                    <Image loading="lazy" src={img2} alt="Image" />
                                </Col>
                                <Col xs="12" md="16" lg="8">
                                    <a href="https://www.instagram.com/winsor_fx" className="fw-pamm-1">Instagram</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="4" md="4" lg="4" className="stying-btn justify-content-cener" data-aos="fade-left" data-aos-duration="1200">

                            <Row>
                                <Col xs="12" md="6" lg="4" className=" d-flex justify-content-center">
                                    <Image loading="lazy" src={img3} alt="Image" />
                                </Col>
                                <Col xs="12" md="16" lg="8">
                                    <a href="https://twitter.com/winsorfx" className="fw-pamm-1">Twitter</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="4" md="4" lg="4" className="stying-btn justify-content-cener" data-aos="fade-left" data-aos-duration="1500">

                            <Row>
                                <Col xs="12" md="6" lg="4" className=" d-flex justify-content-center">
                                    <Image loading="lazy" src={img4} alt="Image" />
                                </Col>
                                <Col xs="12" md="16" lg="8">
                                    <a href="javascript:void(0);" className="fw-pamm-1">You Tube</a>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Button className="btn text-center stying-btn">Facebook</Button>
                    <Button className="btn text-center stying-btn">Instagram</Button>
                    <Button className="btn text-center stying-btn">Twitter</Button>
                    <Button className="btn text-center stying-btn">You Tube</Button>
                    <Button className="btn text-center stying-btn">LinkedIn</Button> */}
                    </Row>
                </div>
            </div>
        )
    }
}