import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import ForexFive from './components/forex-five';
import CryptoOne from './components/Crypto-one';
import CryptoTwo from './components/Crypto-two';
import CryptoThree from './components/Crypto-three';
import CryptoFive from './components/Crypto-five';
import ForexEight from './components/forex-eight';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function CryptoCurrencyPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - Crypto Currency';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div className='clsmnu'>
                <CryptoOne />
                <CryptoTwo />
                <CryptoThree />
                <ForexFive />
                <CryptoFive />
                <ForexEight />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default CryptoCurrencyPage;