import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class PammHero extends Component {
    render() {
        return (
            <div className="tr-pl-sec-one-pamm">
                <Container className="mr-pamm-cont">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h1 className="text-center ab-tx-si">GET START WITH WINSORFX INVEST</h1>
                            <p className="text-center abt-tx-p-si-pamm">Whenever an Investor chooses a Strategy Manager, the Investor
                                immediately copies whatever trade <br></br>opened by that Strategy Manager at about the similar price and time.
                                As per the given ratio, size of <br></br>the investor's position is known.</p>
                            <Row className="text-center" >
                                <Col xs="12" md="12" lg="12">
                                    <div>
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn trpla-sing-pamm mb-pamm">Invest Now</Button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
