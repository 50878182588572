import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import mt5lo from "../images/mt4-logo.svg"




export default class MT4ONE extends Component {
    render() {
        return (
            <div className="mt-5-one">
                <Container className="mt-5-sp">
                    <Row>
                        <Col xs="12" md="6" lg="6">
                            <h1 className="fw-bo-mt5">MetaTrader 4</h1>
                            <p className="mr-mt-5">Metatrader 4 remains one of the most popular and easy-to-use trading platforms. Boasting Expert Advisors, micro-lots,
                                hedging and one-click trading, the winsorfx.com MT4 offering is everything you would expect from this platform and much more.
                                As we are responsible for pricing and the infrastructure behind the execution of trades, you’ve got every confidence
                                that your trades will be executed fast and with low spreads.</p>
                            <Row>
                                <Col xs="12" md="4" lg="4">
                                    <div>
                                        <Button className="btn mt5-sing">Sign Up</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
                            <Image loading="lazy" src={mt5lo} alt="Image" className="mt-5-logo-img" />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}