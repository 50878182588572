import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import img2 from "../images/master-redesign.svg";

export default class Masterfour extends Component {
  render() {
    return (
      <div className="master-four-bg">
        <Container>
          {" "}
          <Row className="">
            <Col
              xs="12"
              md="6"
              lg="6"
              className="justify-content-center d-flex"
            >
              <Image loading="lazy" src={img2} alt="Image" className="cmpy-img-copy-2" data-aos="fade-left" data-aos-duration="1500" />
            </Col>
            <Col
              xs="12"
              md="6"
              lg="6"
              className="justify-content-center cpy-s2-lft align-items-center d-flex"
            >
              <div className="">
                <Container fluid className="no-pad">
                  <h4 className="copier-tx-mr-mstr">Monitor and profit!</h4>
                </Container>
                <p className="copier-tx-p-mstr">
                  Use your Master Area to view detailed statistics on your
                  trading, change your account settings, and view the amount of
                  commission you've earned.
                </p>
                <div className="bnt-bnt">
                  <Link to="https://my.winsorfx.com/register.php" target="_blank">
                    <Button className="btn text-center our-btn">Become a master</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
