import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import $ from 'jquery/src/jquery';



export default class TradingOne extends Component {

    jQuerycode = () => {
        $(document).ready(function () {
            setTimeout(function () {
                $("#trading-link").addClass("nav-links-active");
            }, 500);
        })
    }
    componentDidMount() {
        this.jQuerycode()
    }

    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
                <Container>
                    <Row>
                        <Col xs="12" md="2" lg="2"></Col>
                        <Col xs="12" md="8" lg="8">
                            <h1 className="text-center txt-cop-h1">Trade like you</h1>
                            <h6 className="text-center txt-cop-p">Access the global financial markets from a single platform designed
                                around you. Whether you want to trade daily or hourly, from your desktop or on the go, with basic charts or
                                comprehensive reports - makes it simple.</h6>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="text-center">
                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                        <Button className="btn text-center copier-btn">Start Trading</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="2" lg="2"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
