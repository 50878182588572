import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';

export default class CommodFive extends Component {
    render() {
        return (
            <div className="forex-re-7">
                <Container>
                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                                <Accordion id="faq-acc">
                                    <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                        <Accordion.Header>What is Bond Trading?</Accordion.Header>
                                        <Accordion.Body>
                                            Bond trading is the buying and selling of bonds on the secondary market. Bonds are debt instruments issued by governments and corporations to borrow money from investors. By trading bonds, investors can gain exposure to fixed income investments and diversify their portfolios.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                        <Accordion.Header>What are the benefits of trading bonds?</Accordion.Header>
                                        <Accordion.Body>
                                            Trading bonds can provide investors with a steady stream of income and long-term capital appreciation. Bonds also offer diversification benefits, as they tend to have lower volatility than stocks. Additionally, bond trading can provide a hedge against inflation.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                        <Accordion.Header>What is the difference between bond trading and stock trading?</Accordion.Header>
                                        <Accordion.Body>
                                            Bond trading involves buying and selling debt instruments issued by governments and corporations, while stock trading involves buying and selling equities or shares of ownership in a company. Bond prices tend to be more stable than stock prices, and bonds typically offer a steady stream of income.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                        <Accordion.Header>What types of bonds can I trade on WinsorFX?</Accordion.Header>
                                        <Accordion.Body>
                                            WinsorFX offers a wide range of bonds, including government bonds, corporate bonds, municipal bonds, and convertible bonds.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                        <Accordion.Header>What are the risks associated with bond trading?</Accordion.Header>
                                        <Accordion.Body>
                                            Bond trading involves the risk of losses due to changes in market conditions, credit ratings, and interest rates. Additionally, the value of bonds can be affected by inflation. Investors should be aware of all the risks associated with bond trading before entering into any transaction.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="2500">
                        <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}