import { React, Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import email from "../../images/mail.png";
import phone from '../../images/phone.png';
import message from '../../images/chat.png';

export default class AboutFiveThree extends Component {
    render() {
        return (
            <div className="contact-suupot-sec-2bg">
                <Container className="">
                    <Row>
                        <Col xs="12" md="4" lg="4" className="text-center" data-aos="fade-right" data-aos-duration="1200">
                            <Container className="justidy-center">
                                <Container className="justify-content-center d-flex">
                                    <Image loading="lazy" src={email} alt="Image" className="about5-one-img"></Image>
                                </Container>
                                <p className="text-center text-white text-sm font-normal">Get in touch with our customer support team via email anytime.</p>
                                <a href="mailto:support@winsorfx.com" className="text-center text-base font-normal about5-links3">support@winsorfx.com</a>
                            </Container>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="text-center" data-aos="zoom-in" data-aos-duration="1800">
                            <Container className="justidy-center">
                                <Container className="justify-content-center d-flex">
                                    <Image loading="lazy" src={phone} alt="Image" className="about5-one-img"></Image>
                                </Container>
                                <p className="text-center text-white text-sm font-normal">Call our team around the clock, from 10pm UK Sunday to 10pm UK Friday.</p>
                                <a href="tel:+44 2080 979 794" className="text-center text-base font-normal about5-links3">+44 2080 979 794</a>
                            </Container>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="text-center" data-aos="fade-left" data-aos-duration="1200">
                            <Container className="justidy-center">
                                <Container className="justify-content-center d-flex">
                                    <Image loading="lazy" src={message} alt="Image" className="about5-one-img"></Image>
                                </Container>
                                <p className="text-center text-white text-sm font-normal">Chat directly to our customer support team via the live chat function below.</p>
                                <a href="javascript:void(Tawk_API.toggle())" className="text-center text-base font-normal about5-links3">Launch Live Chat</a>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}