import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export default class AboutSevenOne extends Component {
    render() {
        return (


            <div className="copier1-bg" id="co-bg-re">
                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <Container className="faq1-cont">
                                <h1 className="text-center txt-cop-h1-new">FAQs</h1>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="faq-icon"><i class="fa-solid fa-magnifying-glass ic-se-si"></i></InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Our FAQ"
                                        aria-label="Search Our FAQ"
                                        aria-describedby="basic-addon1"
                                        id="faq-form"
                                    />
                                </InputGroup>
                                <h6 className="text-center txt-cop-p text-dark">We're dedicated to providing the information you need, when you need it. Because opportunities can only<br></br> be found when you have the insight and expertise you need.</h6>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}