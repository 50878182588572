import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';


export default class ContSupp extends Component {
    render() {
        return (
            <div className="forex-re-7">
                <Container>
                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                                <Accordion id="faq-acc">
                                    <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                        <Accordion.Header>What trading platforms does WinsorFX offer?</Accordion.Header>
                                        <Accordion.Body>
                                            WinsorFX is available on cTrader. cTrader is a powerful online trading platform that provides access to global financial markets. It provides advanced trading functionality, such as high speed order execution, one-click trading, and a range of technical analysis tools.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                        <Accordion.Header>What accounts are available at WinsorFX?</Accordion.Header>
                                        <Accordion.Body>
                                            WinsorFX offers a range of trading accounts, including Classic- minimum deposit of $50, Pro-$10 and VIP-$50 accounts.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                        <Accordion.Header>What is the minimum deposit required to open an account?</Accordion.Header>
                                        <Accordion.Body>
                                            The minimum deposit required to open an account with WinsorFX is $10.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                        <Accordion.Header>Does WinsorFX offer customer support?</Accordion.Header>
                                        <Accordion.Body>
                                            Yes, WinsorFX offers 24/7 customer support via email, telephone, and live chat.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                        <Accordion.Header>Are there any fees associated with trading at WinsorFX?</Accordion.Header>
                                        <Accordion.Body>
                                            Yes, there are fees associated with trading at WinsorFX. These include spreads, commissions, and overnight funding fees.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5" data-aos="fade-up" data-aos-duration="2500">
                                        <Accordion.Header>What payment methods are accepted at WinsorFX?</Accordion.Header>
                                        <Accordion.Body>
                                            WinsorFX accepts payments via bank transfer, debit/credit card, and e-wallets.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6" data-aos="fade-up" data-aos-duration="3000">
                                        <Accordion.Header>Is WinsorFX regulated?</Accordion.Header>
                                        <Accordion.Body>
                                            The WINSORFX Group is registered and regulated in Saint Vincent and the Grenadines. We adhere to stringent regulatory requirements in all aspects of our operations, including financial reporting, client asset management, and the segregation of client and firm funds.
                                            <br></br>
                                            We are excited to announce that we are in the process of obtaining a license from the Financial Services Commission in Mauritius.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="3000">
                        <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}