import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class TradeSeven extends Component {
    render() {
        return (
            <div className="trade-seven">
                <Container>
                    <Row className=" mb-sicom">
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <h1 className="fw-bo text-center">Join the winsorfx trading community, where <br></br>everyone is welcome</h1>
                            <h6 className="mr-trading-seven text-center">Start your trading journey with our simple and dynamic online account application.</h6>
                            <div className="d-flex justify-content-center">
                                <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                    <Button className="btn tra-acc">Open an Account</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}