import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class AboutEightSix extends Component {
    render() {
        return (
            <div className="about5-four-bg-abt-why">
                <Container className="">
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <h4 className="text-center text-black font-medium text-4xl">Join winsorfx</h4>
                            <p className="text-center text-black font-normal">
                                We’ve been giving people the power to trade since 2009. Join winsorfx.com to get all of your<br></br>
                                trading and investing in one place. Long term, short term, do it on your own terms with<br></br>
                                winsorfx.com</p>
                            <div className="text-center">
                                <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                    <Button variant="" type="submit" className="btn why-winsor-btn">Open An Account</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }
}