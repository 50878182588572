import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css';
import './magic.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import img1 from '../images/first-one-mobi.png';
import crd21 from '../images/eurusdcrd.png';
import crd22 from '../images/gbpusdcrd.png';
import crd23 from '../images/eurjpycrd.png';

import $ from 'jquery/src/jquery';

export default class SecOne extends Component {

    jQuerycode = () => {
        $(document).ready(function () {
            setTimeout(function () {
                $("#home-link").addClass("nav-links-active");
            }, 500);
        })
    }
    componentDidMount() {
        this.jQuerycode()
    }

    render() {
        return (
            <div className="sec-one">
                <Container>
                    <Row>
                        <Col xs="12" md="6" lg="6">
                            <h1 className="fw-bo">Get Wide Range Of Instruments To Trade With</h1>
                            <h1 className="fw-bo-1">Winsorfx</h1>
                            <p className="fw-bo-2 width-setting">Winsorfx is a renowned name and have got proven track record of offering high standards of trading environment to our customers</p>

                            <Row>
                                <Col xs="12" md="4" lg="4">
                                    <div>
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn broker-btn">Get Started</Button>
                                        </Link>
                                    </div>.
                                </Col>
                            </Row>
                        </Col>

                        <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
                            <div>

                            </div>
                            <div className="crd-grp first-col">
                                <div className="container-fluid d-flex eursd justify-content-center">
                                    <Image loading="lazy" src={crd21} alt="Image" className="first-slide1" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="150" />
                                </div>
                                <Row className="eursd">
                                    <Container className="justify-content-center d-flex">
                                        <hr className="new1"></hr>
                                    </Container>
                                </Row>
                                <div className="container-fluid d-flex eursd justify-content-center">
                                    <Image loading="lazy" src={crd22} alt="Image" className="first-slide1" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500" />
                                </div>
                                <Row className="eursd">
                                    <Container className="justify-content-center d-flex">
                                        <hr className="new1"></hr>
                                    </Container>
                                </Row>
                                <div className="container-fluid d-flex eursd justify-content-center">
                                    <Image loading="lazy" src={crd23} alt="Image" className="first-slide1" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000" />
                                </div>
                            </div>
                            <Image loading="lazy" src={img1} alt="Image" className="sec-one-p first-col" />

                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
