import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class ConflictsTwo extends Component {
    render() {
        return (
            <div className="ab-fo-two">
                <Container>
                    <h5 className="about-head abt-compla-re text-[28px]">Elements found in Conflicts of Interest policies of Winsorfx may include:</h5>
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Disclosure of potential conflicts:</h5>
                                <p className="about-para">The policy should outline the types of conflicts that may arise and disclose any interests or relationships that could influence the platform's actions or decisions.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Mitigation measures:</h5>
                                <p className="about-para">Trading platforms often have procedures in place to manage conflicts of interest. These may include internal controls, information barriers, segregation of duties, or enhanced transparency.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Fair treatment of clients:</h5>
                                <p className="about-para">The policy should emphasize the platform's commitment to treating all clients fairly and ensuring that no client is given preferential treatment over others.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Client communication:</h5>
                                <p className="about-para">Trading platforms usually maintain clear lines of communication with their clients to inform them about potential conflicts of interest and any relevant actions taken to address them.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Technical and System Risks:</h5>
                                <p className="about-para">The Winsorfx trading platform relies on cutting-edge technological systems, which are prone to technical problems like connectivity failures, server outages, and software bugs. These technical problems may cause incorrect pricing, delayed order fulfillment, or other operational problems.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Regulatory and Legal Risks:</h5>
                                <p className="about-para">Trading on Winsorfx is governed by all relevant laws, rules, and market regulations. Regulation or legal requirement changes may have an effect on market accessibility, trading conditions, and the accessibility of particular financial products. It is vital to keep up with regulatory developments and obey all applicable rules.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Information and Analysis:</h5>
                                <p className="about-para">The information, analysis, and research provided on the Winsorfx platform are for informational purposes only and should not be considered as financial advice. While reasonable efforts are made to ensure the accuracy of the information, there is no guarantee of its completeness or reliability.</p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}