import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import img1 from "../images/img-forex-ni.png";


export default class ForexEight extends Component {
    render() {
        return (
            <div className="forex-bg-9">
                <Container className="forex-bg-cl9">
                    <Row>
                        <Col xs="12" md="5" lg="5">
                            <h1 className="tx-cl-re-8">Ready to trade?</h1>
                            <h2 className="text-balck">Create an account!</h2>
                            <div className="bg-ni-wh" data-aos="zoom-in" data-aos-duration="1200">
                                <Row>
                                    <Col xs="12" md="12" lg="12">
                                        <InputGroup size="lg" className="mt-3">
                                            <Form.Control
                                                aria-label="Large"
                                                aria-describedby="inputGroup-sizing-sm"
                                                placeholder="Full name"
                                                id="name-nine"
                                            />
                                        </InputGroup>
                                        <InputGroup size="lg" className="mt-3">
                                            <Form.Control
                                                aria-label="Large"
                                                aria-describedby="inputGroup-sizing-sm"
                                                placeholder="Email Address"
                                                id="name-nine"
                                            />
                                        </InputGroup>
                                        <InputGroup size="lg" className="mt-3">
                                            <Form.Control
                                                aria-label="Large"
                                                aria-describedby="inputGroup-sizing-sm"
                                                placeholder="Phone Number"
                                                id="name-nine"
                                            />
                                        </InputGroup>
                                        <div className="bt-nine-mr text-center">
                                            <a className="bt-nine btn ">Create Account</a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Col>

                        <Col xs="12" md="7" lg="7" className="d-flex justify-content-center">
                            <Image loading="lazy" src={img1} alt="Image" className="per-re-img" data-aos="fade-left" data-aos-duration="1200"></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}