import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer'
import SecOne from './components/first';
import SecTwo from './components/section-two';
import Secthird from './components/third';
import Secfour from './components/fourth';
import SecFifth from './components/sec-fifth';
import SecSix from './components/sec-six';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function App() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Home';
  }, []);

  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <SecOne />
        <SecTwo />
        <Secthird />
        <Secfour />
        <SecFifth />
        <SecSix />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default App;
