import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../../components/compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import img1 from "../../images/surface-tab-corrected.png";

export default class AboutEightFour extends Component {
  render() {
    return (
      <div className="bg-white pt-5 abut-eiht">
        <Container className="">
          <Row className="my-5">
            <Col xs="12" md="6" lg="6" className="justify-content-center">
              <Container className=" justify-content-center">
                <Image loading="lazy" src={img1} alt="Image" className="lap-re-img-why-winsor" data-aos="slide-right" data-aos-duration="2000"></Image>
              </Container>
            </Col>
            <Col xs="12" md="6" lg="6" className="justify-content-center">
              <Container className=" justify-content-center">
                <h4 id="tes-mr-1" className="text-left text-black text-xl font-normal ourplatf-s3-head">
                  Effective and useful tools
                </h4>
                <p className="text-left text-black font-normal fnt-wght">
                  So, what does a trader need to trade <br /> confidently, aside from{'\u00A0'}
                  <span className="txt-rd-clo">having a trading account ? </span><br /> You require the following
                  tools:
                </p>
                <div className="why-wins-sec3-cont">
                  <p>
                    Fundamental tools are macro- and micro-economic factors that
                    can impact asset performance.
                  </p>
                  <p>
                    Technical tools to assist in decisions on assets based on past
                    market data.
                  </p>
                  <p>
                    Sentimental tools provide an indication on how the market
                    feels about an asset.
                  </p>
                  <p>All these and more are provided by Winsorfx.com</p>
                </div>
                <Link to="https://my.winsorfx.com/register.php" target="_blank">
                  <button type="button" class="btn text-center our-btn">Learn More</button>
                </Link>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
