import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class IBFour extends Component {
    render() {
        return (
            <div className="bg-white ib-four-bg">
                <div className="bg-[#F3E8E5] ib-four-bg-two">
                    <Container>
                        <Row data-aos="fade-up" data-aos-duration="2000">
                            <Col xs="12" md="12" lg="12" className="text-center">
                                <Container>
                                    <h4 className="pamm-one-head3 text-2xl font-medium">WINSORFX MULTI-TIER SCHEME</h4>
                                    <p className="text-left font-normal text-base grey-txt">We have finely built our multi-tier affiliate structure at Winsorfx so that you can create your down line smoothly letting your sub-affiliates do all the hard work for you. In order to ensure that you have a wide selection range of sub affiliates, our tier hierarchy covers 5 levels.</p>
                                    <Button className="btn ib-four-btn"><a className="ib-four-hyper" href="https://my.winsorfx.com/register.php">Become an affiliate</a></Button>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="ib-four-align-two">
                    <Container>
                        <Row data-aos="fade-up" data-aos-duration="2000">
                            <Col xs="12" md="12" lg="12">
                                <Container>
                                    <h4 className="text-dark text-2xl font-medium text-center">AFFILIATE COMMISSION TIERS</h4>
                                    <Row className="ib-four-row-align">
                                        <Col xs="12" md="4" lg="4" className="justify-content-center d-flex">
                                            <Container className="ib-one-cont2" id="ib-one-level">
                                                <h4 className="pamm-one-head2 text-2xl font-medium text-center">LEVEL</h4>
                                                <h4 className="pamm-one-head2 text-7xl font-bold text-center">01</h4>
                                                <h4 className="pamm-one-head3 text-xl font-normal text-right">$6</h4>
                                                <h4 className="pamm-one-head3 text-xl font-normal text-right">LOT</h4>
                                                <h4 className="pamm-one-head3 text-xl font-normal text-right">TRADED</h4>
                                            </Container>
                                        </Col>
                                        <Col xs="12" md="4" lg="4" className="justify-content-center">
                                            <Container className="ib-one-cont2" id="ib-one-level">
                                                <h4 className="pamm-one-head2 text-2xl font-medium text-center">LEVEL</h4>
                                                <h4 className="pamm-one-head2 text-7xl font-bold text-center">02</h4>
                                                <h4 className="pamm-one-head3 text-xl font-normal text-right">$3 PER</h4>
                                                <h4 className="pamm-one-head3 text-xl font-normal text-right">LOT</h4>
                                                <h4 className="pamm-one-head3 text-xl font-normal text-right">TRADED</h4>
                                            </Container>

                                        </Col>
                                        <Col xs="12" md="4" lg="4" className="justify-content-center">
                                            <Container className="ib-one-cont2" id="ib-one-level">
                                                <h4 className="pamm-one-head2 text-2xl font-medium text-center">LEVEL</h4>
                                                <h4 className="pamm-one-head2 text-7xl font-bold text-center">03</h4>
                                                <h4 className="pamm-one-head3 text-xl font-normal text-right">$1 PER</h4>
                                                <h4 className="pamm-one-head3 text-xl font-normal text-right">LOT</h4>
                                                <h4 className="pamm-one-head3 text-xl font-normal text-right">TRADED</h4>
                                            </Container>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}