import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/pamm-red-img.png";
import Button from "react-bootstrap/Button";
import $ from 'jquery/src/jquery';

export default class PammOne extends Component {

    jQuerycode = () => {
        $(document).ready(function () {
            setTimeout(function () {
                $("#pamm-link").addClass("nav-links-active");
            }, 500);
        })
    }
    componentDidMount() {
        this.jQuerycode()
    }

    render() {
        return (
            <div className="ourplat-three-align pamm1-bg-new mb-pamm-one">
                <Container>
                    <Row>
                        <Col xs="12" md="6" lg="6" className="">
                            <div className="pamm-align">
                                <h1 className="tx-si-pamm-h">YOU CAN EASILY BECOME <br></br>A STRATEGY MANAGER</h1>
                                <p className="tx-si-pamm-p-new1">As a manager, when you open an account, you will have one execution price with no fees confidential
                                    for all your copied trades. On any social networks you can also post your trading stats
                                    conveniently.For our strategy managers, we have built a designated space whereby their trading
                                    stats are immediately presented in desirable information and available for you to share on your
                                    social networks. What you are required to is simply trade and make profits and leave the rest on
                                    us.</p>
                                <Row className="tx-bt-pamm">
                                    <Col xs="12" md="4" lg="4">
                                        <div>
                                            <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                                <Button className="btn mt5-sing">Start Now</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="d-flex justify-content-center pamm-mob-col">
                            <Image loading="lazy" src={img1} alt="Image" className="our-plat-two-img" data-aos="fade-right" data-aos-duration="1500"></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
