import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from '../components/Navbar';
import Footer from '../components/Footer'
import AboutSixOne from './Components/AboutSix-one';
import AboutSixTwo from './Components/AboutSix-two';
import AboutSixThree from './Components/AboutSix-three';
import AboutSixFour from './Components/AboutSix-four';
import AboutSixFive from './Components/AboutSix-five';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function SafetyOnline() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Safety Online';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <AboutSixOne />
        <AboutSixTwo />
        <AboutSixThree />
        <AboutSixFour />
        <AboutSixFive />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default SafetyOnline;
