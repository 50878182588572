import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import OurPlatOne from './components/our-platone';
import OurPlatTwo from './components/our-plattwo'
import OurPlatThree from './components/our-platthree';
import OurPlatFour from './components/our-platfour';
import OurPlatFive from './components/our-platfive';
import ForexEight from './components/forex-eight';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function OurPlat() {
    return (
        <div className="App">
            <NavbarComp />
            <div className='clsmnu'>
                <OurPlatOne />
                <OurPlatTwo />
                <OurPlatThree />
                <OurPlatFour />
                <OurPlatFive />
                <ForexEight />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default OurPlat;