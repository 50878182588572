import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image"
import "./compstyle.css";
import Button from "react-bootstrap/Button";
import img2 from '../images/tarding-sec-4-img.png';

export default class TradeFour extends Component {
    render() {
        return (
            <div className="tradingpg-s4-bg">
                <Container>
                    <Row className="mobile-cont-space">
                        <Col xs="12" md="7" lg="7" className="justify-content-center">
                            <Container className="mobile-three-1bg-tradings">
                                <h4 className="text-left font-semibold text-lg trading-pg-head">Trade global markets</h4>
                                <ul className="trading-ul-s">
                                    <li className="bobile-txt-trading text-left">Access 1000's of markets</li>
                                    <li className="bobile-txt-trading text-left">Trade our exclusive range Of blends</li>
                                    <li className="bobile-txt-trading text-left">Trade up-and-coming IPOs before they happen</li>
                                </ul>
                                <Row>
                                    <Col xs="12" md="12" lg="12">
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn text-center our-btn-new">Trade Now</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs="12" md="5" lg="5" className="d-flex justify-content-center">
                            <Image loading="lazy" src={img2} alt="Image" className="tardingpg-s4-mob-img" data-aos="fade-right" data-aos-duration="1500" />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}