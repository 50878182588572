import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class AboutThreeOne extends Component {
    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
            <Container>
                <Row>
                <Col xs="12" md="1" lg="1"></Col>
                    <Col xs="12" md="10" lg="10">
                        <h1 className="text-center txt-cop-h1">Regulation and Legal Pack</h1>
                        <h6 className="text-center txt-cop-p">WINSORFX Group registered and regulated in SAINT VINCENT AND THE GRENADINES. We practice strict regulatory compliance requirements in each and every aspect of the firm’s operations including financial reporting, client asset management, segregation of client/firm funds and more. </h6>
                        <h6 className="text-center txt-cop-p">We are glad to announce Our Upcoming license Mauritius, FSC license were underwriting. </h6>
                    </Col>
                    <Col xs="12" md="1" lg="1"></Col>
                </Row>
            </Container>
        </div>


        )
    }
}