import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class AboutFivFIve extends Component {
    render() {
        return (
            <div className="contact-support-bg ">
                <Container className="">
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <h4 className="text-center text-black font-medium text-4xl">Join the winsorfx.com trading community,</h4>
                            <h4 className="text-center text-black font-medium text-4xl">where everyone is welcome</h4>
                            <p className="text-center text-black font-normal">Start your trading journey with our simple and dynamic online account application</p>
                            <div className="text-center">
                                <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                    <Button variant="" type="submit" className="btn about5-five-submit">Open An Account</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }
}