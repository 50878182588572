import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class AboutThreeTwo extends Component {
    render() {
        return (
            <div className="bg-white faq2-bg">
                <Container>
                    <Row>
                        <Col xs="12" md="2" lg="2" className="justify-content-center"></Col>
                        <Col xs="12" md="4" lg="4" className="justify-content-center">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="justify-content-center d-flex" data-aos="fade-left" data-aos-duration="1200">
                                    <a href="TermsandConds" className="leg-2-text text-center text-dark" target="_blank">Terms & Conditions</a>
                                </Col>
                                <Col xs="12" md="12" lg="12" className="justify-content-center d-flex" data-aos="fade-left" data-aos-duration="1200">
                                    <a href="PrivacyPolicy" className="leg-2-text text-center text-dark" target="_blank">Privacy Policy Statement</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="justify-content-center d-flex">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="justify-content-center d-flex" data-aos="fade-right" data-aos-duration="1200">
                                    <a href="RiskDisk" className="leg-2-text text-center text-dark" target="_blank">Risk Disclosure Statement</a>
                                </Col>
                                <Col xs="12" md="12" lg="12" className="justify-content-center d-flex" data-aos="fade-right" data-aos-duration="1200">
                                    <a href="Conflicts" className="leg-2-text text-center text-dark" target="_blank">Conflicts of Interest Policy</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="2" lg="2" className="justify-content-center"></Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="4" lg="4" className="justify-content-center"></Col>
                        <Col xs="12" md="4" lg="4" className="justify-content-center">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="justify-content-center d-flex" data-aos="fade-up" data-aos-duration="1200">
                                    <a href="ClientsComplaints" className="leg-2-text text-center text-dark" target="_blank">Clients Complaints Handling Policy</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="justify-content-center"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}