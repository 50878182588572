import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import IntroOne from './components/introducing-one';
import IntroTwo from './components/introducing-two';
import IntroThree from './components/introducing-three';
import ForexFive from './components/forex-five';
import IntroNine from './components/introducing-nine';
import ForexEight from './components/forex-eight';
import IntroFour from './components/introducing-four';
import IntroFive from './components/introducing-five';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function IntroducingCFDs() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - Blends';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div className="clsmnu">
                <IntroOne />
                <IntroTwo />
                <IntroThree />
                <IntroFour />
                <IntroFive />
                <ForexFive />
                <IntroNine />
                <ForexEight />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default IntroducingCFDs;