import React, { Component } from "react";
import { MarketData } from "react-ts-tradingview-widgets";
import { Container } from "react-bootstrap";


export default class MArketDataWidget1 extends Component {
    render() {
        return (
            <div>
                <Container>
                    <MarketData colorTheme="light" width="100%" height={400} symbolsGroups={[
                        {
                            "name": "Bonds",
                            "originalName": "Bonds",
                            "symbols": [
                                {
                                    "name": "CME:GE1!",
                                    "displayName": "Eurodollar"
                                },
                                {
                                    "name": "CBOT:ZB1!",
                                    "displayName": "T-Bond"
                                },
                                {
                                    "name": "CBOT:UB1!",
                                    "displayName": "Ultra T-Bond"
                                },
                                {
                                    "name": "EUREX:FGBL1!",
                                    "displayName": "Euro Bund"
                                },
                                {
                                    "name": "EUREX:FBTP1!",
                                    "displayName": "Euro BTP"
                                },
                                {
                                    "name": "EUREX:FGBM1!",
                                    "displayName": "Euro BOBL"
                                }
                            ]
                        }
                    ]}></MarketData>
                </Container>
            </div>
        )
    }
}