import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer'
import { useEffect } from 'react';
import React from 'react';
import DemoContestOne from './components/DemoContestOne'
import DemoContestTwo from './components/DemoContestTwo';
import DemoContestThree from './components/DemoContestThree';
import DemoContestFour from './components/DemoContestfour';
import DemoContestFive from './components/DemoContestFive';
import DemoContestSix from './components/DemoContestSix';
import DemoContestSeven from './components/DemoContestSeven';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function DemoContest() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - DemoContest';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <DemoContestOne />
        <DemoContestTwo />
        <DemoContestThree />
        <DemoContestFour />
        {/* <DemoContestFive /> */}
        <DemoContestSix />
        <DemoContestSeven />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default DemoContest