import { React, Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Image } from "react-bootstrap"
import './compstyle.css'
import prize from "../images/prize.svg";
export default class DuelContestOne extends Component {
    render() {
        return (
            <div className="index-bg">
                <Container >
                    <Row className="">
                        <Col lg="6" md="6" sm="12" className="">
                            <h1 className="global-chat-two">GET READY FOR AN<br />
                                ADRENALINE-PUMPING <br />
                                TRADING SHOWDOWN <br />
                                IN OUR LAST EVENT.
                            </h1>
                            <h1 className="top-trade-two">DOUBLING DUEL!</h1>
                            <p className="start-trade">*Terms and Conditions apply</p>

                        </Col>
                        <Col lg="6" md="6" sm="12" className="rewar-2 left-prize">
                            <Image loading="lazy" src={prize} alt="Image" className="reward"></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}