import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/new-lap-img.png";

export default class BondThree extends Component {
    render() {
        return (
            <div className="share-bg-3">
                <Container className="">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h1 className="text-center tx-cl-re">Why Trade Bonds CFDs with Winsorfx</h1>
                            <p className="text-center tx-p-re">First derivative: Gain more direct exposure to central bank decisions than in Forex.

                                Trade inverse and leveraged bond ETFs depending on your strategy and goals.

                                Take a position on where you think interest rates are going and what central banks are doing.</p>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" className="lap-re-img" data-aos="fade-up" data-aos-duration="1200"></Image>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}