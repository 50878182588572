import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { FormErrors } from '../FormErrors';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import img1 from "../../images/popup-success.svg";

export default class AboutFiveTwo extends Component {
    state = {
        myUsername: '',
        email: '',
        phone: '',
        typeofquery: '',
        Subject: '',
        description: '',
        fileInput: '',
        formErrors: {email: '', phone: ''},
        emailValid: false,
        phoneValid: false,
        formValid: false,
        openModal : false

    }
    constructor(props)
    {
      super(props);
    //   this.addFormData = this.addFormData.bind(this);
      this.myRef = React.createRef();
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    // onClickButton = e =>{
    //     e.preventDefault()
    //     this.setState({openModal : true})
    // }

    onCloseModal = ()=>{
        this.setState({openModal : false})
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
                      () => { this.validateField(name, value) });
      }

      validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let phoneValid = this.state.phoneValid;
    
        switch(fieldName) {
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : ' is invalid';
            break;
          case 'phone':
            phoneValid = value.match(/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/);
            fieldValidationErrors.phone = phoneValid ? '': ' is invalid';
            break;
          default:
            break;
        }
        if(emailValid)
        {
            fieldValidationErrors.phone = phoneValid ? '': ' is invalid';
        }
        if(phoneValid)
        {
            fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        }
        if(emailValid && phoneValid)
        {

        }
        this.setState({formErrors: fieldValidationErrors,
                        emailValid: emailValid,
                        phoneValid: phoneValid
                      }, this.validateForm);
      }
    
      validateForm() {
        this.setState({formValid: this.state.emailValid && this.state.phoneValid});
      }
    
      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
      }

    

  //Form Submission
  handleSubmit(evt) {
    evt.preventDefault();
    const fd = new FormData();
    const formData = { image: this.state.selectedImage }
    fd.append('myUsername', this.refs.myUsername.value);
    fd.append('email', this.refs.email.value);  
    fd.append('phones', this.refs.phones.value);  
    fd.append('typofquery', this.myRef.current.value);   
    fd.append('subjects', this.refs.subjects.value);  
    fd.append('descriptions', this.refs.descriptions.value); 
  //   fd.append('fileInput', this.refs.fileInput.value);
    // axios.post('http://localhost:8080/enquiery.php', fd, formData
    // ).then(res=>
      axios.post('https://my.winsorfx.com/api/enquiery_live.php', fd, formData
      ).then(res=>
    {
     

  this.setState({openModal : true})
  this.myFormRef.reset();
  this.setState({email: ''});
  this.setState({phone: ''});
  }
  );
  }
//   addFormData(evt)
//     {
//       evt.preventDefault();
//       const fd = new FormData();
//       const formData = { image: this.state.selectedImage }
//       fd.append('myUsername', this.refs.myUsername.value);
//       fd.append('email', this.refs.email.value);  
//       fd.append('phones', this.refs.phones.value);  
//       fd.append('typofquery', this.myRef.current.value);   
//       fd.append('subjects', this.refs.subjects.value);  
//       fd.append('descriptions', this.refs.descriptions.value); 
//     //   fd.append('fileInput', this.refs.fileInput.value);
//       axios.post('http://localhost:8080/enquiery.php', fd, formData
//       ).then(res=>
//         // axios.post('https://my.winsorfx.com/api/enquiery_live.php', fd, formData
//         // ).then(res=>
//       {
       

//     // this.myFormRef.reset();
    
//     }
//     );
//     }


    render() {
        return (
            <div className="bg-white about5-two-bg-13">
                <Container className="contact-form-cont">
                    <Row>
                        <Col xs="12" md="3" lg="3" className="justify-content-center"></Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center">
                            <Container className="contact-form-bg" data-aos="zoom-in" data-aos-duration="1000">
                                <h2 className="text-center text-black font-medium text-4xl mt-4 mb-5">Online Query Form</h2>
                                <FormErrors formErrors={this.state.formErrors} />
                                <Form   noValidate className="mb-7" id="Support-form" ref={(el) => this.myFormRef = el}>

                                    <Row>
                                        <Col xs="12" md="6" lg="6">
                                            <Form.Group className="mb-3" controlId="fullName">
                                                <Form.Label className="font-medium text-sm">Full Name</Form.Label>
                                                <Form.Control type="text" className="input-design" placeholder="" ref="myUsername"  />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" md="6" lg="6"  className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
                                            <Form.Group className="mb-3" controlId="Email">
                                                <Form.Label className="font-medium text-sm">Email<span className="text-red-600">*</span></Form.Label>
                                                <Form.Control required type="email" className="input-design" placeholder=""  ref="email" id="emil" name="email" value={this.state.email} onChange={this.handleUserInput}  />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="6" lg="6" className={`form-group ${this.errorClass(this.state.formErrors.phone)}`}>
                                            <Form.Group className="mb-3" controlId="Phone">
                                                <Form.Label className="font-medium text-sm">Phone<span className="text-red-600">*</span></Form.Label>
                                                <Form.Control required type="number" className="input-design" placeholder="" ref="phones" name="phone" value={this.state.phone}  onChange={this.handleUserInput} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" md="6" lg="6">
                                            <Form.Group className="mb-3" controlId="QueryType">
                                                <Form.Label className="font-medium text-sm">Type of Query</Form.Label>
                                                <Form.Select aria-label="Default select example" id="querytype-select" ref={this.myRef}>
                                                    {/* <option>Select the type of query</option> */}
                                                    <option value="Sales" className="query-opt">Sales</option>
                                                    <option value="Support" className="query-opt">Support</option>
                                                    <option value="Office Visit" className="query-opt">Office Visit</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="12" lg="12">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label className="font-medium text-sm">Subject</Form.Label>
                                                <Form.Control type="text" className="input-design" placeholder="" ref="subjects"  />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="12" lg="12">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label className="font-medium text-sm">Description</Form.Label>
                                                <Form.Control as="textarea" type="text" className="input-design" rows={3} ref="descriptions"  />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {/* <h4 className="font-medium text-sm">Drag and Drop or attach files</h4>
                                    <span>
                                        <button onClick={() => this.refs.fileInput.click()} className="btn about-upload btn-sm">Choose Files</button>
                                    <input ref="fileInput"  name="fileInput"  type="file"  style={{ display: "" }} />
                                    </span><span className="font-medium text-sm"> No Choosen files</span>
                                    <h4 className="font-medium text-sm mt-2">Files must be less than 5MB. Allowed file types: .GIF, .JPG, .JPEG,<br></br> .PNG, .TXT, .RTF, .PDF, .DOC, DOCX.</h4> */}
                                    {/* <Button variant="" type="submit" className="btn about5-submit"  onClick={this.addFormData}>
                                        Submit
                                    </Button> */}
                                    <Button variant="" type="submit" className="btn about5-submit" disabled={!this.state.formValid} onClick={this.handleSubmit}>
                                        Submit
                                    </Button>                                   
                                </Form>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center"></Col>
                    </Row>
                </Container>
                <>
                    <Modal show={this.state.openModal} onHide={this.onCloseModal}  id="query-popup" className="modal-align">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Container className="d-flex justify-content-center">
                                <Image loading="lazy" src={img1} alt="Image" className="popup-img" />
                            </Container>
                            <h2 className="text-center popup-head">Thank You!</h2>
                            <h6 className="text-center">Your query has been sent.</h6>
                        </Modal.Body>


                        <Modal.Footer className="text-center justify-content-center">
                            <Button variant="secondary"  className="query-popup-btn" onClick={this.onCloseModal}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            </div>
        )
    }
}