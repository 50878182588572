import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import img1 from "../images/bond-tra-lap.svg";
import MarketOverviewWidget1 from "./Widgets/MarketOverviewWidget1";
import SingleTicker5 from "./Widgets/SingleTicker5";
import SingleTicker6 from "./Widgets/SingleTicker6";

export default class IntroFour extends Component {
  render() {
    return (
      <div className="commod-bg-4">
        <Container className="container-fluid commod-pad">
          <Row>
            <Col xs="12"
              md="6"
              lg="6"
              className="justify-content-center d-flex trde-intro">
              <Row>
                <Col xs="12" md="12" lg="12" data-aos="slide-right" data-aos-duration="1600">
                  <SingleTicker5 />
                </Col>
                <Col xs="12" md="12" lg="12" data-aos="slide-right" data-aos-duration="2200">
                  <SingleTicker5 />
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="6" lg="6" className="justify-content-center trde-intro">
              <h1 className="commod-4-txt-hh">Trade Like an Investing Legend</h1>
              <p className="commod-4-txt-pp">
                Guru Blends mimic the key portfolio choices of legends like
                Warren Buffett, Carl Icahn, George Soros, and Bill Ackman.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6" lg="6" className="justify-content-center trde-intro">
              <h1 className="commod-4-txt-hh">
                Gain Exposure to Political Events
              </h1>
              <p className="commod-4-txt-pp">
                Allow us to provide you with position options on major macro and
                geopolitical themes with handpicked stocks that stand to win or
                lose depending on the course of global events.
              </p>
            </Col>
            <Col xs="12" md="6" lg="6" className="justify-content-center d-flex trde-intro">
              <Row>
                <Col xs="12" md="12" lg="12" data-aos="slide-left" data-aos-duration="1600">
                  <SingleTicker6 />
                </Col>
                <Col xs="12" md="12" lg="12" data-aos="slide-left" data-aos-duration="2200">
                  <SingleTicker6 />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
