import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import Image from 'react-bootstrap/Image';
import img13 from "../images/new-lap-img.png";

export default class IntroThree extends Component {
    render() {
        return (
            <div className="forex-bg-4">
                <Container className="">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h1 className="text-center tx-cl-re">Why Trade Blends with Winsorfx</h1>
                            <p className="text-center tx-p-re">Use one instrument to take a position on multiple
                                stocks - including the world's largest companies.</p>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img13} alt="Image" className="lap-re-img" data-aos="fade-up" data-aos-duration="1200"></Image>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}