import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/mas-six-1.png";
import img2 from "../images/mas-six-2.png";
import img3 from "../images/mas-six-3.png"


export default class MasterSix extends Component {
    render() {
        return (
            <div>
                <Container fluid className="bg-master-six">
                    <Row>
                        <Col xs="4" md="4" lg="4" data-aos="fade-right" data-aos-duration="1500">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>

                            <p className="text-center mr-p-cp-5">Create multiple Master Accounts <br></br>for different strategies</p>
                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="zoom-in" data-aos-duration="1800">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img2} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>

                            <p className="text-center mr-p-cp-5">Track detailed statistics for your<br></br>
                            orders and earned Master's <br></br>commission in the Master Area</p>
                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="fade-left" data-aos-duration="1500">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img3} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>

                            <p className="text-center mr-p-cp-5">Enjoy the benefit of no additional <br></br>commission charged by Winsorfx</p>
                        </Col>
                    </Row>

                  
                </Container>

            </div>
        )
    }
}