import React, { Component } from "react";
import { Screener } from "react-ts-tradingview-widgets";
import { Container } from "react-bootstrap";


export default class Screener1 extends Component {
    render() {
        return (
            <div>
                <Container fluid>
                    <Screener colorTheme="light" width="100%"></Screener>
                </Container>
            </div>
        )
    }
}