import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import Image from 'react-bootstrap/Image';
import img1 from "../images/etf2-img.png";

export default class ETFThree extends Component {
    render() {
        return (
            <div className="etf-three-mr">
                <Container>
                    <Row>
                        <h1 className="txt-etf-h-1 text-center">What is an ETF?</h1>
                        <p className="text-center">ETF is short for: Exchange Traded Fund</p>
                        <Col xs="12" md="5" lg="5" className="mr-etf-1">
                            <div className="para-wit">
                                <h2 className="txt-etf-h">ETFs opportunities</h2>
                                <p>ETFs combine the features of funds and equities into one instrument. Like other investment funds,
                                    they group together various assets, such as stocks or commodities. This helps the ETF track the value
                                    of its underlying market as closely as possible.</p>

                                <p className="txt-etf-p">There are ETFs that track the FTSE 100, containing constituents of that index proportional to the
                                    FTSE's price. Other exchange traded funds may group together companies working in certain sectors,
                                    like Lithium producers, or follow an asset like Gold.</p>
                            </div>
                        </Col>
                        <Col xs="12" md="7" lg="7" className="justify-content-center d-flex mr-etf-2">
                            <Image loading="lazy" src={img1} alt="Image" className="etf-ser" data-aos="zoom-in" data-aos-duration="1200"></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}