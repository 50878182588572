import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import img1 from "../images/new-reimg-3.png"

export default class CommodOne extends Component {
    render() {
        return (
            <div className="forex-bg" id="fo-bg-re">
                <Container >
                    <Row>
                        <Col xs="12" md="5" lg="5">
                            <h1 className="tx-cl-re-1">Commodities</h1>
                            <p className="tx-cl-re-1-p">Speculate on the raw materials driving the global
                                economy. Sign up to trade CFDs on energy markets like
                                Oil and Gas, metals like gold and silver, and soft
                                commodities like corn and cocoa.</p>
                            <Row>
                                <Col xs="12" md="12" lg="12" >
                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                        <Button className="btn text-center forex-our-btn">Trade Commodities</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="7" lg="7" className="justify-content-center d-flex">
                            <Image loading="lazy" src={img1} alt="Image" className="hero-img-forex" data-aos="zoom-in-left" data-aos-duration="1200"></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}