import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import img1 from '../../images/faq-mobile.png';
import Button from "react-bootstrap/Button";

export default class AboutSevenThree extends Component {
    render() {
        return (
            <div className="mobile5-bg">
                <Row className="">
                    <Col xs="12" md="12" lg="12" className="justify-content-center d-flex">
                        <h4 className="text-center text-dark font-semibold text-4xl mb-4">Still have a question?</h4>
                    </Col>
                </Row>
                <Container className="faq3-bg mb-8">
                    <Row>
                        <Col xs="12" md="6" lg="6" className="align-items-center">
                            <Container className="dowload-cont-new">
                                <Form id="faq3-form" data-aos="fade-up" data-aos-duration="1200">
                                    <Form.Group className="mb-3" controlId="FName">
                                        <Form.Control type="text" placeholder="First Name" className="faq-form"/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="LName">
                                        <Form.Control type="text" placeholder="Last Name" className="faq-form"/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="PhoneNumber">
                                        <Form.Control type="phone" placeholder="Phone Number" className="faq-form"/>
                                    </Form.Group>
                                    <Button variant="primary" className="btn faq3-btn" type="submit">Submit</Button>
                                </Form>
                            </Container>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="faq-image-align d-flex">
                            <Image loading="lazy" src={img1} alt="Image" className="mobile5-img-mobile-new faq3-inmg-new" data-aos="slide-left" data-aos-duration="1300"/>
                        </Col>
                        {/* <Col xs="12" md="1" lg="1" className="justify-content-center d-flex"></Col> */}
                    </Row>
                </Container>
            </div>
        )
    }
}
