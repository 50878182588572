import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import MT5ONE from './components/mt5-one';
import Mt5Two from './components/mt5-two';
import MT5FOUR from './components/mt5-four'
import Mt5Three from './components/mt5-three';
import Mt5Five from './components/mt5-five';
import Mt5Six from './components/mt5-six';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function Mt5page() {
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <MT5ONE />
        <Mt5Two />
        <Mt5Three />
        <MT5FOUR />
        <Mt5Five />
        <Mt5Six />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default Mt5page;
