import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/stra-man.svg";
import img2 from "../images/fund-your-acc.svg";
import img3 from "../images/pamm-soc.svg"
import img4 from "../images/pamm-percen.svg"
import img5 from "../images/phone-trade.svg"

export default class PammTwo extends Component {
    render() {
        return (
            <div className="trading3-bg tr-four">
                <Container fluid className="trading3-cont">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <h1 className="fw-bo-trading3 text-center text-white">GET START WITH WINSORFX INVEST</h1>
                            <Container fluid>
                                <Row>
                                <Col xs="" md="1" lg="1">
                                </Col>
                                    <Col xs="4" md="2" lg="2" data-aos="zoom-in" data-aos-duration="1000">
                                        <Row>
                                            <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                                <Image loading="lazy" src={img1} alt="Image" className="spa-pamm"></Image>
                                            </Col>
                                        </Row>
                                        <h4 className="text-white text-center text-base">As a Strategy Manager create an ECN or ECN Zero Account</h4>
                                    </Col>
                                    <Col xs="4" md="2" lg="2" data-aos="zoom-in" data-aos-duration="1500">
                                        <Row>
                                            <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                                <Image loading="lazy" src={img2} alt="Image" className="spa-pamm"></Image>
                                            </Col>
                                        </Row>
                                        <h4 className="text-white text-center text-base">Fund your account, trade and make potential profits</h4>
                                    </Col>
                                    <Col xs="4" md="2" lg="2" data-aos="zoom-in" data-aos-duration="2000">
                                        <Row>
                                            <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                                <Image loading="lazy" src={img3} alt="Image" className="spa-pamm"></Image>
                                            </Col>
                                        </Row>
                                        <h4 className="text-white text-center text-base">On your social networks you can share your strategies</h4>
                                    </Col>
                                    <Col xs="6" md="2" lg="2" data-aos="zoom-in" data-aos-duration="2500">
                                        <Row>
                                            <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                                <Image loading="lazy" src={img4} alt="Image" className="spa-pamm"></Image>
                                            </Col>
                                        </Row>
                                        <h4 className="text-white text-center text-base">Percentage should be taken of your investor's profits</h4>
                                    </Col>
                                    <Col xs="6" md="2" lg="2" data-aos="zoom-in" data-aos-duration="3000">
                                        <Row>
                                            <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                                <Image loading="lazy" src={img5} alt="Image" className="spa-pamm"></Image>
                                            </Col>
                                        </Row>
                                        <h4 className="text-white text-center text-base">To make your stats higher, keep on trading good</h4>
                                    </Col>
                                    <Col xs="" md="1" lg="1">
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
