import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Table from 'react-bootstrap/Table';

export default class AboutTwoThree extends Component {
    render() {
        return (
            <div className="abt-table-align-two">
                <Container>
                    <h1 className="abt-txt-red">Trading Platforms</h1>
                    <h6 className="abt-txt-mr-re">See what accounts and platforms are on offer</h6>
                    <div className="abt-three-table">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr className="table-align-two">
                                    <td></td>
                                    <td>Europe</td>
                                    <td>UK</td>
                                    <td>BVI</td>
                                    <td>Australia</td>
                                    <td>South Africa</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="table-align-two">
                                    <td>Trading Products</td>
                                    <td>CFDs</td>
                                    <td>CFDs, Spread Bets</td>
                                    <td>CFDs</td>
                                    <td>CFDs</td>
                                    <td>CFDs</td>
                                </tr>
                                <tr className="table-align-two">
                                    <td>Account Types</td>
                                    <td>Retail, Professional, GSL (French)*</td>
                                    <td>Retail, Professional</td>
                                    <td>Retail</td>
                                    <td>Retail, Wholesale</td>
                                    <td>Retail</td>
                                </tr>
                                <tr className="table-align-two">
                                    <td>Incentives and Loyalty awards</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>Yes</td>
                                    <td>-</td>
                                    <td>Yes</td>
                                </tr>
                                <tr className="table-align-two">
                                    <td>Negative Balance Protection</td>
                                    <td colSpan={5}>You cannot lose more than your deposit</td>
                                </tr>
                                <tr className="table-align-two">
                                    <td>Range of Markets</td>
                                    <td colSpan={5}>2,000+ assets, including forex, indices, commodities, shares and cryptos</td>
                                </tr>
                                <tr>
                                    <td className="text-[14px]">Trading Platforms</td>
                                    <td className="text-[14px]" colSpan={5}>winsorfx.com Trader & MetaTrader 5 available for Desktop, Mobile (iOS, Android), Tablet (iOS, Android)</td>
                                </tr>
                            </tbody>
                        </Table>
                        <p className="text-[14px]">* Retail clients residents of France receive a trading account with limited risk on a per position basis in accordance with French regulation</p>
                    </div>
                </Container>
            </div>
        )
    }
}