import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from '../components/Navbar';
import Footer from '../components/Footer'
import AboutEightOne from './Components/AboutEight-one';
import AboutEightTwo from './Components/AboutEight-two';
import AboutEightThree from './Components/AboutEight-three';
import AboutEightFour from './Components/AboutEight-four';
import AboutEightFIve from './Components/AboutEight-five';
import AboutEightSix from './Components/AboutEight-six';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function WhyWinsorfx() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Why Winsorfx?';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <AboutEightOne />
        <AboutEightTwo />
        <AboutEightThree />
        <AboutEightFour />
        <AboutEightFIve />
        <AboutEightSix />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default WhyWinsorfx;
