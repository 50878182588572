import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class AboutEightOne extends Component {
    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
                <Container>
                    <Row>
                        <Col xs="12" md="1" lg="1"></Col>
                        <Col xs="12" md="10" lg="10">

                            <h1 className="text-center txt-cop-h1">Why Winsorfx?</h1>
                            <h6 className="text-center txt-cop-p">We are the place to go if you are looking for an accessible & easy to use trading platform — available online and via
                                our app. If you are looking for a place where all traders feel at home, from professionals, to those trading for the
                                very first time then you have come to the right place. Winsorfx.com is trusted by both experts and beginners alike.</h6>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="text-center tes-mr-bt">
                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                        <Button className="btn text-center copier-btn">Sign Up</Button>
                                    </Link>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs="12" md="1" lg="1"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}