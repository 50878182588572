import React, { Component } from "react";
import { MarketOverview } from "react-ts-tradingview-widgets";
import { Container } from "react-bootstrap";


export default class MarketOverviewIndicesWidget extends Component {
    render() {
        return (
            <div>
                <Container>
                    <MarketOverview colorTheme="light" width="100%" height={550}

                        tabs={[
                            {
                                "title": "Bonds",
                                "symbols": [
                                    {
                                        "s": "CME:GE1!",
                                        "d": "Eurodollar"
                                    },
                                    {
                                        "s": "CBOT:ZB1!",
                                        "d": "T-Bond"
                                    },
                                    {
                                        "s": "CBOT:UB1!",
                                        "d": "Ultra T-Bond"
                                    },
                                    {
                                        "s": "EUREX:FGBL1!",
                                        "d": "Euro Bund"
                                    },
                                    {
                                        "s": "EUREX:FBTP1!",
                                        "d": "Euro BTP"
                                    },
                                    {
                                        "s": "EUREX:FGBM1!",
                                        "d": "Euro BOBL"
                                    }
                                ],
                                "originalTitle": "Bonds"
                            },
                            {
                                "title": "Commodities",
                                "symbols": [
                                    {
                                        "s": "CME_MINI:ES1!",
                                        "d": "S&P 500"
                                    },
                                    {
                                        "s": "CME:6E1!",
                                        "d": "Euro"
                                    },
                                    {
                                        "s": "COMEX:GC1!",
                                        "d": "Gold"
                                    },
                                    {
                                        "s": "NYMEX:CL1!",
                                        "d": "Crude Oil"
                                    },
                                    {
                                        "s": "NYMEX:NG1!",
                                        "d": "Natural Gas"
                                    },
                                    {
                                        "s": "CBOT:ZC1!",
                                        "d": "Corn"
                                    }
                                ],
                                "originalTitle": "Commodities"
                            },
                            {

                                "title": "Indices",
                                "symbols": [
                                    {
                                        "s": "FOREXCOM:SPXUSD",
                                        "d": "S&P 500"
                                    },
                                    {
                                        "s": "FOREXCOM:NSXUSD",
                                        "d": "Nasdaq 100"
                                    },
                                    {
                                        "s": "FOREXCOM:DJI",
                                        "d": "Dow 30"
                                    },
                                    {
                                        "s": "INDEX:NKY",
                                        "d": "Nikkei 225"
                                    },
                                    {
                                        "s": "INDEX:DEU30",
                                        "d": "DAX Index"
                                    },
                                    {
                                        "s": "FOREXCOM:UKXGBP",
                                        "d": "UK 100"
                                    }
                                ],
                                "originalTitle": "Indices"
                            },
                            {
                                "title": "Crypto",
                                "symbols": [
                                    {
                                        "s": "CRYPTOCAP:TOTAL3",
                                        "d": "TOTAL3"
                                    },
                                    {
                                        "s": "CBOT:ZB1!",
                                        "d": "T-Bond"
                                    },
                                    {
                                        "s": "CBOT:UB1!",
                                        "d": "Ultra T-Bond"
                                    },
                                    {
                                        "s": "EUREX:FGBL1!",
                                        "d": "Euro Bund"
                                    }
                                ],
                                "originalTitle": "Crypto"
                            },
                            {
                                "title": "Forex",
                                "symbols": [
                                    {
                                        "s": "FX:EURUSD"
                                    },
                                    {
                                        "s": "FX:GBPUSD"
                                    },
                                    {
                                        "s": "FX:USDJPY"
                                    },
                                    {
                                        "s": "FX:USDCHF"
                                    },
                                    {
                                        "s": "FX:AUDUSD"
                                    },
                                    {
                                        "s": "FX:USDCAD"
                                    }
                                ],
                                "originalTitle": "Forex"

                            }
                        ]}

                    ></MarketOverview>
                </Container>
            </div>
        )
    }
}