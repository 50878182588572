import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';


export default class CryptoFive extends Component {
    render() {
        return (
            <div className="forex-re-7">
                <Container>
                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                                <Accordion id="faq-acc">
                                    <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                        <Accordion.Header>What is Cryptocurrency?</Accordion.Header>
                                        <Accordion.Body>
                                            Cryptocurrency is a digital or virtual currency that uses cryptography for security. It is not issued by any central authority, rendering it theoretically immune to government interference or manipulation.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                        <Accordion.Header>How does Cryptocurrency work?</Accordion.Header>
                                        <Accordion.Body>
                                            Cryptocurrency is a digital asset that is exchanged between two parties via a secure, encrypted ledger. The ledger is maintained by a distributed network of computers and is secured through the use of cryptography. Transactions are verified by the network, and the ledger is updated with each new transaction.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                        <Accordion.Header>What are the advantages of Cryptocurrency?</Accordion.Header>
                                        <Accordion.Body>
                                            Cryptocurrency offers several advantages such as lower transaction fees, faster transaction times, and increased security and privacy. In addition, the decentralized nature of cryptocurrencies makes them resistant to censorship and manipulation.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                        <Accordion.Header>Are there any risks associated with Cryptocurrency?</Accordion.Header>
                                        <Accordion.Body>
                                            As with any investment, there are risks associated with investing in Cryptocurrency. These include the risk of market volatility, the risk of theft or fraud, and the risk of regulatory changes that could affect the value of the currency.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                        <Accordion.Header>How can I get started with Cryptocurrency trading on WinsorFX?</Accordion.Header>
                                        <Accordion.Body>
                                            To get started with Cryptocurrency trading on WinsorFX, you will need to create an account and deposit funds. After that, you can begin trading cryptocurrencies by selecting the currency pair you wish to trade and setting your preferred order type.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="2500">
                        <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}