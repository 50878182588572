import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from '../components/Navbar';
import Footer from '../components/Footer'
import AboutFourOne from './Components/AboutFour-one'
import AboutFourTwo from './Components/AboutFour-two'
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'


function Complaints() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Complaints';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <AboutFourOne />
        <AboutFourTwo />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default Complaints;
