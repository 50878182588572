import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Button from "react-bootstrap/Button";
import img1 from '../images/copier-redesign.svg';

export default class CopierTwo extends Component {
    render() {
        return (
            <div className="sec-one-cpy-3">
                <Container>
                    <Container>
                        <h1 className="fw-bo-copy-head-cpy text-center">How It Works</h1>
                    </Container>
                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center cpy-s2-lft00">
                            <div className="">
                                <h4 className="copier-tx-mr-cp1">Create an account and make a deposit</h4>
                                <p className="copier-tx-p-cpy">Sign up in one easy step and make a deposit to your Wallet via any payment
                                    method you like. If you already have funds in your trading account at winsorFx, you can add money to your
                                    Wallet from it using Internal Transfer. Your Wallet balance shows your uninvested funds.</p>
                                <div className="bnt-bnt">
                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                        <Button className="btn text-center our-btn-cpy">Create Account</Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-end d-flex just-cent">
                            <Image loading="lazy" src={img1} alt="Image" className="cmpy-img-copy" data-aos="fade-right" data-aos-duration="1500" />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}