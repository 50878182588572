import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import td_tick from '../../images/check-solid.svg'

export default class AboutTwoSix extends Component {
    render() {
        return (
            <div className="abt-table-align-six">
                <Container>
                    <h1 className="abt-txt-red">Registration Process</h1>
                    <h6 className="abt-txt-mr-re">Signing up can vary depending on where you live</h6>
                    <div className="abt-six-table">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr className="table-align-five">
                                    <td></td>
                                    <td>Europe</td>
                                    <td>UK</td>
                                    <td>Australia</td>
                                    <td>BVI</td>
                                    <td>South Africa</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="table-align-five">
                                    <td>Documents required</td>
                                    <td><Image loading="lazy" src={td_tick} className="abt-tick-img"></Image></td>
                                    <td><Image loading="lazy" src={td_tick} className="abt-tick-img"></Image></td>
                                    <td><Image loading="lazy" src={td_tick} className="abt-tick-img"></Image></td>
                                    <td><Image loading="lazy" src={td_tick} className="abt-tick-img"></Image></td>
                                    <td><Image loading="lazy" src={td_tick} className="abt-tick-img"></Image></td>
                                </tr>
                                <tr className="table-align-five">
                                    <td>Electronic Verification*</td>
                                    <td><Image loading="lazy" src={td_tick} className="abt-tick-img"></Image></td>
                                    <td><Image loading="lazy" src={td_tick} className="abt-tick-img"></Image></td>
                                    <td><Image loading="lazy" src={td_tick} className="abt-tick-img"></Image></td>
                                </tr>
                            </tbody>
                        </Table>
                        <p>* Available in selected countries</p>
                    </div>
                </Container>
            </div>
        )
    }
}