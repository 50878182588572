import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Table from 'react-bootstrap/Table';

export default class AboutTwoEight extends Component {
    render() {
        return (
            <div className="abt-table-align-eight">
                <Container>
                    <h1 className="abt-txt-red">Customer Support</h1>
                    <h6 className="abt-txt-mr-re">We offer customer support in a number of languages</h6>
                    <div className="abt-seven-table">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr className="table-align-seven">
                                    <td></td>
                                    <td>Europe</td>
                                    <td>UK</td>
                                    <td>Australia</td>
                                    <td>BVI</td>
                                    <td>South Africa</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="table-align-seven">
                                    <td>Languages</td>
                                    <td colSpan={5}>English, French, Spanish, Italian, Arabic, German, Bulgarian</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        )
    }
}