import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button";
import "./compstyle.css"
import timer_img from "../images/timer.svg"
import browse_img from "../images/browse.svg"

export default class SecSix extends Component {
    render() {
        return (
            <div className="sec-six-align">
                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <Container className="cont-bg-color">
                                <Row>
                                    <Col xs="12" md="6" lg="6">
                                        <h3 className="font-semibold text-red-500 text-4xl">WINSORFX OFFERS</h3>
                                        <h5>A VARIETY OF TRADING OPPORTUNITIES</h5>
                                        <br></br>
                                        <p>WinsorFX provides powerful trading tools and resources to help traders maximize their potential.</p>
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="off-btn">Start Trading</Button>
                                        </Link>
                                    </Col>
                                    <Col xs="12" md="6" lg="6">
                                        <div className="sec-six-left-align">
                                            <Row>
                                                <Col xs="2" md="2" lg="2" data-aos="fade-up" data-aos-duration="1200">
                                                    <Image loading="lazy" src={timer_img} alt="Image" className="timer"></Image>
                                                </Col>
                                                <Col xs="10" md="10" lg="10" data-aos="fade-up" data-aos-duration="1200">
                                                    <div className="border-right">
                                                        <h4 className="font-semibold text-2xl">1)&nbsp; Speedy order Execution</h4>
                                                        <p>With Winsorfx our clients experience instant execution of trades without any delay.</p>
                                                    </div>
                                                </Col>
                                                <Col xs="2" md="2" lg="2" data-aos="fade-up" data-aos-duration="1500">
                                                    <Image loading="lazy" src={browse_img} alt="Image" className="timer"></Image>
                                                </Col>
                                                <Col xs="10" md="10" lg="10" data-aos="fade-up" data-aos-duration="1500">
                                                    <div className="border-right">
                                                        <h4 className="font-semibold text-2xl">2)&nbsp; Raw Spreads</h4>
                                                        <p>Winsorfx offers raw tight spreads in the market so you can trade at your ease.</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}