import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer'
import IBOne from './components/IB-one';
import IBTwo from './components/IB-two';
import IBThree from './components/IB-three';
import IBFour from './components/IB-four';
import IBEight from './components/IB-eight';
import IBSeven from './components/IB-seven';
import IBFive from './components/IB-five';
import IBSix from './components/IB-Six';
import { useEffect } from 'react';
import IBTen from './components/ib-ten';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function IBPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - Introducing Broker';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div className='clsmnu'>
            <IBOne />
            <IBTwo />
            <IBThree />
            <IBFour />
            <IBFive />
            <IBSix />
            <IBSeven />
            <IBTen />
            <IBEight />
            <Footer />
            <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default IBPage