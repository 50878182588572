import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/Objects 7.svg";
import img2 from "../images/Objects 8.svg";
import img3 from "../images/Objects 9.svg"
import Button from "react-bootstrap/Button";

export default class CopierFive extends Component {
    render() {
        return (
            <div>
                <Container fluid className="bg-copier-five">
                    <Row>
                        <Col xs="4" md="4" lg="4" data-aos="fade-right" data-aos-duration="1200">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <p className="text-center mr-p-cp-5">The most profitable traders to <br></br>follow</p>
                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="zoom-in" data-aos-duration="1800">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img2} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <p className="text-center mr-p-cp-5">You don't have to be an expert in
                                <br></br> Forex</p>
                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="fade-left" data-aos-duration="1200">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img3} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <p className="text-center mr-p-cp-5">Stable income from a diversified <br></br> portfolio</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="12" lg="12" className="text-center">
                            <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                <Button className="btn text-center copier-btn">Join Trading</Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}