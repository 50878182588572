import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import TradePlatformThree from './components/tradingplatform-three';
import TradePlatformFive from './components/tradingplatform-five';
import ForexFive from './components/forex-five';
import TradePlatformSix from './components/tradingplatform-six';
import ForexEight from './components/forex-eight';
import TradePlatformTwo from './components/tradingplatform-two';
import TradePlatformOne from './components/tradingplatform-one';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function Tradingplatform() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Trading Platform';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <TradePlatformOne />
        <TradePlatformTwo />
        <TradePlatformThree />
        <ForexFive />
        <TradePlatformFive />
        <TradePlatformSix />
        <ForexEight />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default Tradingplatform;
