import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from '../components/Navbar';
import Footer from '../components/Footer'
import AboutFiveOne from './Components/AboutFive-one';
import AboutFiveTwo from './Components/AboutFive-two';
import AboutFiveThree from './Components/AboutFive-three';
// import AboutFiveFour from './Components/AboutFive-four';
import AboutFivFIve from './Components/AboutFive-five';
// import ForexSeven from '../components/forex-seven';
import { useEffect } from 'react';
import ContSupp from '../components/Contact-support';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
// import axios from 'axios';

function ContactSupport() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Contact Support';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
        <AboutFiveOne />
        <AboutFiveThree />
        <AboutFiveTwo />
        {/* <AboutFiveFour /> */}
        <ContSupp />
        <AboutFivFIve />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default ContactSupport;
