import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import chartimg from "../images/bar-chart.svg"
import timerimg from "../images/timer-two.svg"
import pichartimg from "../images/pie-chart.svg"
import blocksimg from "../images/blocks.svg"


export default class MT4TWO extends Component {
    render() {
        return (
            <div className="mt5-two-bg">
                <Container className="mt5-two-cont">
                    <Row>
                        <h1 className="text-center text-black text-4xl">Benefits of MT4</h1>
                        <Col xs="12" md="3" lg="3"></Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="mt5-card">
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={chartimg} alt="Image" className="mt5-two-img" />
                                    </Col>
                                </Row>
                                <h4 className="text-black text-center text-lg">
                                    Algorithmic Trading
                                </h4>
                                <h4 className="text-black text-center text-sm font-normal">
                                    Automate your trading with Expert Advisors that you can plug-in to the platform
                                </h4>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="mt5-card">
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={timerimg} alt="Image" className="mt5-two-img" />
                                    </Col>
                                </Row>
                                <h4 className="text-black text-center text-lg">
                                    Fast Execution, Low Spreads
                                </h4>
                                <h4 className="text-black text-center text-sm font-normal">
                                    Execute trades fast and with low costs based on our pricing and trade infrastructure
                                </h4>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3"></Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="3" lg="3"></Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="mt5-card">
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={pichartimg} alt="Image" className="mt5-two-img" />
                                    </Col>
                                </Row>
                                <h4 className="text-black text-center text-lg">
                                    Intuitive Charts
                                </h4>
                                <h4 className="text-black text-center text-sm font-normal">
                                    Fully customisable charts and a range of technical indicators
                                </h4>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3" className="justify-content-center">
                            <Container className="mt5-card">
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                        <Image loading="lazy" src={blocksimg} alt="Image" className="mt5-two-img" />
                                    </Col>
                                </Row>
                                <h4 className="text-black text-center text-lg">
                                    Trade Micro Lots
                                </h4>
                                <h4 className="text-black text-center text-sm font-normal">
                                    Use hedging tools & trade micro-lots as small as 0.01 lots to manage risks better
                                </h4>
                            </Container>
                        </Col>
                        <Col xs="12" md="3" lg="3"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}