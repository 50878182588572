import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import CommodOne from './components/Commod-one';
import CommodTwo from './components/Commod-two';
// import ShareTwo from './components/share-two';
import CommodThree from './components/Commod-three';
import ForexFive from './components/forex-five';
import CommodFour from './components/Commod-four';
import CommodFive from './components/Commod-five';
import ForexEight from './components/forex-eight';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function CommoditiesPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - Commodities';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div className='clsmnu'>
                <CommodOne />
                <CommodTwo />
                {/* <ShareTwo /> */}
                <CommodThree />
                <CommodFour />
                <ForexFive />
                <CommodFive />
                <ForexEight />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default CommoditiesPage;