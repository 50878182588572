import { React, Component } from "react";
import { Row, Col, Container, Button, Image, Card } from "react-bootstrap"
import './compstyle.css'
import phone from '../images/Group 2.svg';
export default class DemoContestThree extends Component{
    render(){
        return(
                 <div className="">
                        <Container className="moss">
                        <h5 class="line-1 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> How can I take part?</h5>
                </Container>
                <Container>
                    <Row>
                        <Col lg="6" md="6" xs="12">
                        <Image loading="lazy" src={phone} alt="Image" className="mobile-tab"></Image>
                        </Col>

                        <Col lg="6" md="6" xs="12" >
                          <Row>
                            <Col xs="3">
                            <h4 className="one">1</h4>
                            <h5 className="linesa"></h5>
                            </Col>
                            <Col className="co-move" xs="9">
                            <h5 className="reg">Register</h5>
                            <h6 className="are-ok-trade">Create an account on our website by visiting <a href="https://my.winsorfx.com/login.php" className="acc_color1">https://my.winsorfx.com/login.php</a> after logging in, select the Dashboard page and then scroll down to find and open the Demo account.</h6>
                            <h6 className="join">Join Today...</h6>
                            </Col>
                          </Row>

                          <Row className="mos">
                            <Col xs="3">
                            <h4 class="one">2</h4>
                            <h5 class="linesas"></h5>
                            </Col>
                            <Col  className="co-move"  xs="9" >
                            <h5 class="reg">Download</h5>
                            <h6 class="are-ok-trade">Next, download the multiple award-winning cTrader.
                            </h6>
                            </Col>
                          </Row>

                          <Row className="mos">
                            <Col xs="3">
                            <h4 class="one">3</h4>
                            <h5 class="linesas"></h5>
                            </Col>
                            <Col  className="co-move" xs="9">
                            <h5 class="reg">Trade</h5>
                            <h6 class="are-ok-trade">Start Trading with a virtual balance of $1000.</h6>

                            </Col>
                          </Row>

                          <Row className="mos">
                            <Col xs="3">
                             <h4 class="one">4</h4>
                            </Col>
                            <Col className="co-move" xs="9">
                            <h5 class="reg">Win</h5>
                            <h6 class="are-ok-trade">Reach the summit and collect your reward…</h6>

                            </Col>
                          </Row>
                        
                        </Col>
                    </Row>
                </Container>
                 </div>
        )
    }
}