import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import MT4ONE from './components/mt4-one';
import MT4TWO from './components/mt4-two';
import MT4THREE from './components/mt4-three'
import MT4FOUR from './components/mt4-four';
import MT4FIVE from './components/mt4-five';
import MT4SIX from './components/mt4-six';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function Mt4page() {
    return (
        <div className="App">
            <NavbarComp />
            <div className='clsmnu'>
                <MT4ONE />
                <MT4TWO />
                <MT4THREE />
                <MT4FOUR />
                <MT4FIVE />
                <MT4SIX />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default Mt4page;