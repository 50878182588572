import React, { Component } from "react";
import { Link } from "react-router-dom"
import './compstyle.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import LogoImg from '../images/logo/winsorfx-logo.svg'
import $ from 'jquery/src/jquery';

export default class NavbarComp extends Component {

    jQuerycode = () => {
        $(function () {
            $(window).on("scroll", function () {
                if ($(window).scrollTop() > 50) {
                    $("#site-navbar").removeClass("nav-bg");
                    $("#site-navbar").addClass("nav-bg2").style.top = "0";
                } else {
                    $("#site-navbar").removeClass("nav-bg2");
                    $("#site-navbar").addClass("nav-bg").style.top = "-50px";
                }
            });
        });

        $('.clsmnu').click(function () {
            $("#responsive-navbar-nav").addClass('nvhide');
        });


        // $(document).ready(function () {
        //     if ($("responsive-navbar-nav").hasClass('show'));
        //     {
        //         $('body').click(function () {
        //             $("#responsive-navbar-nav").addClass('hide');
        //         });
        //     }
        // });

    }
    componentDidMount() {
        this.jQuerycode()
    }

    render() {
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" variant="light" className="nav-bg fixed-top" id="site-navbar">
                    <Container fluid className="navbar-space">
                        <Navbar.Brand href="./"><label className="logo-head-align"><Image loading="lazy" src={LogoImg} alt="Logo" className="logo-img" /></label></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto"></Nav>
                            <Nav>
                                <Link to="../" className="nav-space nav-links" id="home-link">Home</Link>

                                <NavDropdown title="About" id="collasible-nav-dropdown" className="nav-links nav-space">
                                    {/* <Link className="dropdown-item" to="../aboutonepage">why winsorfx.com</Link> */}
                                    <Link className="dropdown-item" to="../about/WhyWinsorfx">
                                        Why winsorfx.com
                                    </Link>
                                    {/* <Link className="dropdown-item" to="../about/GlobalOffering">
                                        Global Offering
                                    </Link> */}
                                    <Link className="dropdown-item" to="../about/LegalPack">Legal Pack</Link>
                                    {/* <NavDropdown.Divider /> */}
                                    <Link className="dropdown-item" to="../about/Complaints">
                                        Complaints
                                    </Link>
                                    <Link className="dropdown-item" to="../about/ContactSupport">
                                        Contact Support
                                    </Link>
                                    <Link className="dropdown-item" to="../about/SafetyOnline">
                                        Safety Online
                                    </Link>
                                    <Link className="dropdown-item" to="../about/FAQ">
                                        FAQs
                                    </Link>

                                </NavDropdown>
                                <NavDropdown title="Instruments" id="collasible-nav-dropdown" className="nav-links nav-space">
                                    {/* <NavDropdown.Divider /> */}
                                    <Link className="dropdown-item" to="../Forex">
                                        Forex
                                    </Link>
                                    <Link className="dropdown-item" to="../Shares">
                                        Shares
                                    </Link>
                                    <Link className="dropdown-item" to="../Commodities">
                                        Commodities
                                    </Link>
                                    <Link className="dropdown-item" to="../IndicesCFDs">
                                        Indices CFDs
                                    </Link>
                                    <Link className="dropdown-item" to="../CryptoCurrency">Crypto Currency</Link>
                                    <Link className="dropdown-item" to="../ETFs">
                                        ETFs
                                    </Link>
                                    <Link className="dropdown-item" to="../BondCFDs">Bond CFDs</Link>
                                    <Link className="dropdown-item" to="../Blends">
                                        Blends
                                    </Link>
                                    {/* <Link className="dropdown-item" to="../IB">
                                        IB
                                    </Link> */}
                                </NavDropdown>
                                <NavDropdown title="Platforms" id="collasible-nav-dropdown" className="nav-links nav-space">
                                    {/* <Link to="../mt5page" className="dropdown-item">Mt5</Link>
                                    <Link className="dropdown-item" to="../mt4page">
                                        Mt4
                                    </Link> */}

                                    <Link className="dropdown-item" to="../CTrader">
                                        CTrader
                                    </Link>
                                    {/* <Link className="dropdown-item" to="../OurPlatform">Our Platform</Link> */}
                                    {/* <NavDropdown.Divider /> */}
                                    <Link className="dropdown-item" to="../TradingPlatform">
                                        Trading Platform
                                    </Link>
                                    <Link className="dropdown-item" to="../MobileTrading">
                                        Mobile trading
                                    </Link>
                                </NavDropdown>

                                <NavDropdown title="Promotions" id="collasible-nav-dropdown" className="nav-links nav-space" >
                                    <Link className="dropdown-item" to="../IB">
                                        Introducing Broker
                                    </Link>
                                    <Link className="dropdown-item" to="../DemoContest">
                                        Demo Contest
                                    </Link>
                                    <Link className="dropdown-item" to="../DuelDemoContest">
                                        Doubling Duel
                                    </Link>
                                </NavDropdown>


                                <Link to="../Trading" className="nav-space nav-links" id="trading-link">Trading</Link>

                                <NavDropdown title="Copy Trading" id="collasible-nav-dropdown" className="nav-links nav-space" >
                                    <Link className="dropdown-item" to="../CopierAccount">
                                        Copier Account
                                    </Link>
                                    <Link className="dropdown-item" to="../MasterAccount">
                                        Master Account
                                    </Link>
                                </NavDropdown>
                                <Link to="../Pamm" className="nav-space nav-links" id="pamm-link">Pamm</Link>
                                {/* <Link to="../IB" className="nav-space nav-links" id="ib-link">IB</Link> */}

                                <Link to="https://app.ctrader.com/" target="_blank">
                                    <button className="btn nav-btn1">CTrader</button>
                                </Link>
                                <Link to="https://mt5.winsorfx.com/user/login" target="_blank">
                                    <button className="btn nav-btn">MT5</button>
                                </Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        )
    }
}
