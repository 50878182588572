import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from '../images/affilliate.png'
import img2 from '../images/master-affiliate.png'
import img3 from '../images/trader.png'

export default class IBTwo extends Component {
    render() {
        return (
            <div className="ib-two bg-black">
                <Container>
                    <Row data-aos="fade-up" data-aos-duration="2000">
                        <Col xs="12" md="4" lg="4" className="justify-content-center">
                            <Container xs="12" md="4" lg="4" className="justify-content-center d-flex">
                                <Image loading="lazy" src={img1} alt="Image" className="" />
                            </Container>
                            <h3 className=" ib-two-h text-center white-txt">MASTER AFFILIATE</h3>
                            <p className="  text-center white-txt">The one who hires other affiliates to join us is the Master affiliate and he
                                earns profits by recruiting more affiliates for Winsorfx.</p>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="justify-content-center">
                            <Container xs="12" md="4" lg="4" className="justify-content-center d-flex">
                                <Image loading="lazy" src={img2} alt="Image" className="" />

                            </Container>
                            <h3 className=" ib-two-h-2 text-center white-txt">AFFILIATE</h3>
                            <p className=" text-center white-txt">The one who acts as an agent and introduces the new customers to Winsorfx is an
                                Affiliate or the IB (Introducing Broker).</p>
                        </Col>
                        <Col xs="12" md="4" lg="4" className="justify-content-center">
                            <Container xs="12" md="4" lg="4" className="justify-content-center d-flex">
                                <Image loading="lazy" src={img3} alt="Image" className="" />

                            </Container>
                            <h3 className="ib-two-h-3 text-center white-txt">TRADER</h3>
                            <p className=" text-center white-txt">Person who trades (buys or sells) Indices, Stocks, Currencies, Metals or
                                Cryptocurrencies is called a Trader.</p>
                        </Col>
                    </Row>

                    <p className="text-center ib-two-p-1 white-txt" data-aos="fade-up" data-aos-duration="3000">The multi-tier affiliate program offered by Winsorfx is built to allow
                        affiliates to introduce traders and sub affiliates to Winsorfx. We provide monthly automatic payouts of earnings
                        quickly and easily and allow you to monitor your earnings inside your affiliate back-office account at any time.
                        To assist you with any questions, our support team is always by your side. Contact us 24/7 for any questions or
                        concerns. When working directly with one of our affiliate managers and marketing specialists to create creative
                        campaigns to maximize your earnings, use any of our proven marketing methods to appeal to potential customers.</p>
                </Container>
                <Container className="justify-content-center d-flex" data-aos="fade-up" data-aos-duration="2000">
                    <Button className="btn button-rounded"><a className="button-rounded-hyper" href="https://my.winsorfx.com/register.php">Become an affiliate</a></Button>
                </Container>

                {/* <hr className="ib-line"></hr> */}
            </div>
        )
    }
}