import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import '../compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class intro extends Component {
    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
                <Container>
                    <Row>
                        <Col xs="12" md="1" lg="1"></Col>
                        <Col xs="12" md="10" lg="10">
                            <h1 className="text-center txt-cop-h1">Ctrader</h1>
                            <h6 className="text-center txt-cop-p">CTrader, developed and maintained by Spotware Systems Ltd., is an
                                innovative, multi-asset online trading platform. It provides traders with the ability to trade in forex,
                                CFDs, indices, commodities and cryptocurrencies from any location in the world. Boasting an impressive
                                suite of features and tools, CTrader is an ideal choice for experienced traders who require a
                                comprehensive trading platform. The platform is available as a desktop version, a web version, and a
                                mobile app.</h6>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="text-center">
                                    <Link to="https://app.ctrader.com/" target="_blank">
                                        <Button className="btn text-center copier-btn tes-mr-bt">Learn More</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="1" lg="1"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
