import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from '../images/phone22.png';


export default class MasterSeven extends Component {
    render() {
        return (
            <div className="sec-one-cpy-3">
                <Container>
                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center cpy-s2-lfttttt">
                            <div className="">
                            <h1 className="txt-cp-eg">Your Master Area In The Winsorfx Trading App For Android</h1>
                           <ul className="list-cp-eg">
                            <li className="mr-tp-li-cp">Create Master Accounts on the go</li>
                            <li className="mr-tp-li-cp">View statistics on your copiers and commissions wherever you are</li>
                            <li className="mr-tp-li-cp">Manage copying conditions for your new followers on the spot</li>
                           
                           </ul>
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
                            <Image loading="lazy" src={img1} alt="Image" className="mb-master-img" data-aos="fade-left" data-aos-duration="1500" />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}