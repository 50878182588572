import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from '../images/mob-sec-1-new.png';
import appstore from '../images/down-appstore.svg';
import playstore from '../images/down-playstore.svg';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

export default class MobileTradingTwo extends Component {
    render() {
        return (
            <div className="mobile-one-bg-new">
                <Container className="container-fluid">
                    <Row>
                        <h1 className="text-center red-text text-3xl mb-5 pb-4">Where design and functionality work together
                        </h1>
                    </Row>
                    <Row>
                        <Col xs="12" md="1" lg="1" className="justify-content-center d-flex"></Col>
                        <Col xs="12" md="5" lg="5" className="justify-content-center d-flex">
                            <div className="container justify-content-start d-flex align-items-center">
                                <Image loading="lazy" src={img1} alt="Image" className="mobile2-img-new" data-aos="fade-right" data-aos-duration="1500" />
                            </div>
                        </Col>
                        <Col xs="12" md="5" lg="5" className="justify-content-center">
                            <Container className="dowload-cont2">
                                <p className="text-left text-light text-lg">The winsorfx.com app was created for traders
                                    seeking quick access to the markets. Our simple
                                    interface and quick execution put you straight into
                                    the trade with a single click.</p>
                                <Row>
                                    <Col xs="12" md="12" lg="12">
                                        <ButtonToolbar aria-label="Toolbar with button groups">
                                            <ButtonGroup className="me-2" aria-label="Second group">
                                                <a href="https://apps.apple.com/in/app/ctrader/id767428811"><Image loading="lazy" src={appstore} alt="Image" className="app-btn-new" /></a>
                                            </ButtonGroup>
                                            <ButtonGroup aria-label="Third group">
                                                <a href="https://play.google.com/store/apps/details?id=com.spotware.ct"><Image loading="lazy" src={playstore} alt="Image" className="app-btn2-new" /></a>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs="12" md="1" lg="1" className="justify-content-center d-flex"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
