import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MarketOverviewForexWidget from "./Widgets/MarketOverviewForexWidget";
import Image from "react-bootstrap/Image";
import img_rect from "../images/Rectangle7687.png";

export default class ForexTwo extends Component {
  render() {
    return (
      <div className="shares3-bg">
        <div className="mobile-role">
          <Container fluid className="justify-content-center d-flex">
            <Row>
              <Col xs="12" md="12" lg="12" className="justisy-content-center indx">
                <div className="trading3-widget mt-8" data-aos="slide-up" data-aos-duration="1200">
                  <MarketOverviewForexWidget />
                </div>
                <Image loading="lazy" src={img_rect} alt="Image" className="rect-img"></Image>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
