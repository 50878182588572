import { React, Component } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import './compstyle.css'
import layer from '../images/Layer_1.svg';

export default class DemoContestSix extends Component {
    render() {
        return (
            <div>
                <Container className="moss">
                    <h5 class="line-2 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> Trading Conditions</h5>
                    <p className="below-index">To prevent disqualification, please read the material below. To take part in the DEMO COMPETITION 2023 hosted by Winsorfx, please make sure you
                        read the trading guidelines thoroughly and adhere to them. I wish you luck! </p>
                    <Row className="mo-down">
                        <Col lg="6" md="6" xs="12">
                            <Image loading="lazy" src={layer} alt="Image" className="trade-laptop"></Image>
                        </Col>
                        <Col lg="6" md="6" xs="12" className="list-one">
                            <ul className="ul">
                                <li className="li">All demo accounts will start at $2000 capital (+$1000 if you have coupon code)</li>
                                <br />
                                <li className="li">If you reach 50% of the given capital you will automatically lose the contest.</li>
                                <br />
                                <li className="li">Expert advisers are not authorized.</li>
                                <br />
                                <li className="li">You cannot use scripts.</li>
                                <br />
                                <li className="lia">No hedging between accounts is permitted.</li>
                                <br />
                                <li className="lis">For the demo competition, participants may only register for one account
                                    for one round. The first account a participant registers will be
                                    utilized for trading, and any additional accounts will be canceled and
                                    rendered invalid if they unintentionally register for numerous accounts or rounds.</li>
                                <br />
                                <li className="liss">Only one person may be registered per IP address.
                                    These accounts will be deleted if more than two or three persons
                                    register using the same IP address.
                                </li>
                            </ul>

                            {/* <>
                                <ul>
                                    <li>All demo accounts will start at $1000 capital. (+$500 if you made a review)</li>
                                </ul>
                            </> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}