import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import img2 from "../images/master-redesign.svg";
import img4 from "../images/rank1.png";

export default class CopierThree extends Component {
  render() {
    return (
      <div>
        <div className="sec-one-cpy-0">
          <Container>
            <Row className="">
              <Col
                xs="12"
                md="12"
                lg="12"
                className="justify-content-center mob-alg-cpy-2"
              >
                <h4 className="copier-tx-mr-cp1 text-center">
                  Follow the best traders
                </h4>
                <Container
                  xs="12"
                  md="4"
                  lg="4"
                  className="justify-content-center d-flex"
                >
                  <p className="cp-tre-cont-p">
                    Find Masters you want to follow and click 'Copy'. Their
                    positions will be copied<br /> automatically. The deposit
                    percentage setting will help you manage your portfolio. Try <br />
                    copying different strategies to decide which of them work
                    best for you!.
                  </p>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col
                xs="12"
                md="12"
                lg="12"
                className="justify-content-center no-pad1 cpy-1234"
              >
                {/* <Container xs="12" md="4" lg="4" className="cpy-trd-box d-flex">
                  <Row>
                    <Col>
                      <div className="cpy-trade-pers">
                        <div className="d-flex cpy-trd-box1">
                          <div>
                            <Image loading="lazy" src={img1} alt="Image" className="op1" />
                          </div>
                          <div className="bp-box-s1">
                            <p className="personname">David Jacks</p>
                            <p className="no-mrg">Canada</p>
                          </div>
                          <div className="bp-box-s2">
                            <p className="line">| Profit</p>
                            <p className="cpy-trade-pers-grn no-mrg">$5,700</p>
                          </div>
                          <div className="bp-box-s3">
                            <p className="line">| Floating Profit</p>
                            <p className="cpy-trade-pers-grn no-mrg">{'\u00A0'}{'\u00A0'}$5,700</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container> */}
                <Container>
                  <Image loading="lazy" src={img4} alt="Image" className="cmpy-img-copy-2-scr" />
                </Container>
              </Col>
            </Row>
            <Row className="btn-cpy-trd-pad">
              <a className="create-accc text-center">
                <h4 className="text-center no-mrg cr-acc">Copy trade</h4>
              </a>
            </Row>
          </Container>
        </div>

        <div className="sec-one-cpy-2">
          <Container>
            {" "}
            <Row className="marg-ttt">
              <Col
                xs="12"
                md="6"
                lg="6"
                className="justify-content-center d-flex"
              >
                <Image loading="lazy" src={img2} alt="Image" className="cmpy-img-copy-2" data-aos="fade-left" data-aos-duration="1200" />
              </Col>
              <Col
                xs="12"
                md="6"
                lg="6"
                className="justify-content-center cpy-s2-lft"
              >
                <div className="">
                  <h4 className="copier-tx-mr-cp1">Monitor and profit!</h4>
                  <p className="copier-tx-p-cpy">
                    With an unlimited number Of Masters to copy you can create a
                    balanced and diversified trading portfolio and receive a
                    stable income. You also have full control over the process
                    and can modify/stop copying trades at any given time. You
                    can view detailed trading statistics for copied Master
                    Traders in your Copier Area.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
