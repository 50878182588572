import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import img1 from '../images/circlemob.svg';
import img2 from '../images/circ-crd1.svg';
import img3 from '../images/circ-crd2.svg';
import img4 from '../images/circ-crd3.svg';

export default class Secfour extends Component {
    render() {
        return (
            <div className="sec-one">
                <Container>
                    <Row>
                        <Col xs="12" md="7" lg="6" className="justify-content-center">
                            <h1 className="fw-bo">Get <span className="fw-bo-1">Unlimited Access </span>With Our Mobile App</h1>
                            <p className="fw-bo-2-fo">Get everything on your fingertips</p>
                            <p className="fw-bo-2">With just a blink of an eye you can get all the updates about the <br></br>company with you. Know all the latest events and information,<br></br>also the new trends about us.</p>
                            {/* <div className="si-alt">
                                <Button className="btn broker-btn">Start Trade</Button>
                            </div> */}
                            <Row>
                                <Col xs="12" md="4" lg="4">
                                    <div>
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn broker-btn">Start Trade</Button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="hero-fourth container ">
                            <div className="imges-grp container">
                                <Image loading="lazy" src={img1} alt="Image" className="sec-four-imgmob" />
                                <Image loading="lazy" src={img2} alt="Image" className="sec-four-crd1" data-aos="fade-right" data-aos-duration="2000" />
                                <Image loading="lazy" src={img3} alt="Image" className="sec-four-crd2" data-aos="fade-up" data-aos-duration="2000" />
                                <Image loading="lazy" src={img4} alt="Image" className="sec-four-crd3" data-aos="fade-left" data-aos-duration="2000" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}