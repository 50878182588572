import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Ib_img from "../images/ib-img2.svg"

export default class IBSix extends Component {
    render() {
        return (
            <div className="ib-six-align">
                <Container>
                    <Row>
                        <Col xs="12" md="7" lg="8" data-aos="fade-right" data-aos-duration="2000">
                            <Image loading="lazy" src={Ib_img} alt="Image"></Image>
                        </Col>
                        <Col xs="12" md="5" lg="4">
                            <div className="ib-text-section">
                                <p className="ib-des-one grey-txt">IB1 refers to a trader(s), the 'MA' will earn $3 of the lot traded</p>
                                <p className="ib-des-two grey-txt">IB2 refers to a trader(s), the 'MA' will earn $1 of the lot traded.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="affiliate-sec" data-aos="fade-up" data-aos-duration="2000">
                    <Container>
                        <Row>
                            <Col xs="12" md="12" lg="12">
                                <div className="text-title">
                                    <h2 className="head_one grey-txt">Affiliate Promotional Package</h2>
                                    <p className="description grey-txt">In order to promote your affiliate link, Winsorfx has got you a complete
                                        set of promotional packages that helps affiliates.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="ib-six-row-align">
                            <Col xs="12" md="7" lg="7">
                                <div class="points-section">
                                    <ul class="points-one">
                                        <li className="grey-txt"><i class="fa fa-star star-color" aria-hidden="true"></i> Email Templates</li>
                                        <li className="grey-txt"><i class="fa fa-star star-color" aria-hidden="true"></i> Winsorfx Information sheet</li>
                                        <li className="grey-txt"><i class="fa fa-star star-color" aria-hidden="true"></i> Static Banners</li>
                                        <li className="grey-txt"><i class="fa fa-star star-color" aria-hidden="true"></i> Animated Banners</li>
                                        <li className="grey-txt"><i class="fa fa-star star-color" aria-hidden="true"></i> Logo Files</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs="12" md="5" lg="5">
                                <p class="aff-des grey-txt">We highly recommend you get in touch with our affiliate manager via
                                    portal in order to have extra promotional materials for yourself. </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}