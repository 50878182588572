import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image"
import img1 from "../images/our-new-img.png"
import Button from "react-bootstrap/Button";


export default class OurPlatFour extends Component {
    render() {
        return (
            <div className="plat-four-align">
                <Container>
                    <Row>
                        <Col xs="12" md="7" lg="7" className=" ">
                            <Image loading="lazy" src={img1} alt="Image" className="our-pla-sys-re"></Image>
                        </Col>

                        <Col xs="12" md="5" lg="5" className=" ">
                            <h1 className="our-pl-re-tx-h">CTrader. Multi- <br></br>asset trading upgrade</h1>
                            <h6 className="our-pl-re-tx-p">Whilst MT4 was designed for Forex trading, MT5 is a multi-asset derivatives platform designed for
                                trading on a wide range of CFDs. It's a tuned-up, faster version of MT4 which enables hedging and
                                netting, and delivers an increase in technical indicators as well as more insight with market depth
                                and a wider number of timeframes.</h6>

                            <Row className="our-btn-re-lr">
                                <Col xs="12" md="6" lg="6">
                                    <div>
                                        <Button className="btn mt5-sing-new">Learn More</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                </Container>
            </div>
        )
    }
}