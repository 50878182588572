import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from "../images/img-1-forex.png";
import img2 from "../images/img-2-forex.png";
import img3 from "../images/img-forex-3.png"

export default class ForexFive extends Component {
    render() {
        return (
            <div>
                <h1 className="text-center tx-cl-re mr-re-tp">Fast account opening in 3 simple steps</h1>

                <Container fluid className="bg-forex-five">
                    <Row>
                        <Col xs="4" md="4" lg="4" data-aos="zoom-in" data-aos-duration="1500">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img1} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <h4 className=" text-center mr-tp-fx-5">Register</h4>
                            <p className="text-center mr-p-fx-5">Choose an account type and<br></br>
                                submit your application</p>
                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="zoom-in" data-aos-duration="2000">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img2} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <h4 className="text-center mr-tp-fx-5">Fund</h4>
                            <p className="text-center mr-p-fx-5">Fund your account using a
                                wide range<br></br> of funding
                                methods</p>
                        </Col>
                        <Col xs="4" md="4" lg="4" data-aos="zoom-in" data-aos-duration="2500">
                            <Row>
                                <Col xs="12" md="12" lg="12" className="d-flex justify-content-center">
                                    <Image loading="lazy" src={img3} alt="Image" className="img-1"></Image>
                                </Col>
                            </Row>
                            <h4 className="text-center mr-tp-fx-5">Trade</h4>
                            <p className="text-center mr-p-fx-5">Access 180+ instruments across<br></br>
                                all asset classes on App</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
