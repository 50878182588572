import { React, Component } from "react";
import { Row, Col, Container, Button, Image, Card } from "react-bootstrap"
import './compstyle.css'
export default class DemoContestfour extends Component {
    render() {
        return (
            <div>
                <Container className="moss">
                    <h5 class="line-2 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> When will it begin?</h5>
                </Container>
                <Container>
                    <Row>
                        <Col lg="6" md="6" xs="12" className="change">
                            <p className="reg-page">Demo Trade Competition registration is now<br />
                                open! Monthly rounds will be held, and during that<br />
                                time you will be allowed to trade using a demo account.<br />
                                The traders have a fantastic opportunity to put their<br />
                                talents to the test and succeed. View our timetable<br />
                                and mark the dates for the upcoming rounds!
                            </p>
                        </Col>

                        <Col lg="6" md="6" xs="12" className="d-flex justify-content-center">
                            <Card className="red-card-pages">
                                <h5 className="card-titles-2">Active</h5>
                                <h5 className="roun-page">Demo Trade</h5>
                                <p className="com">Competition</p>
                                <div className="card-body csc">
                                    <h5 className="mar">1st AUG to 30th<br />
                                        AUG 2023</h5>
                                </div>
                                <p className="com">Winner Announcement</p>
                                <h5 className="mars">3rd AUG 2023</h5>
                                <h5 className="card-titles">
                                    <a href="https://my.winsorfx.com/register.php"><Button className="btn btn-3">JOIN NOW</Button></a>
                                </h5>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}