import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import img1 from "../images/master-account.png";

export default class CopierTwo extends Component {
  render() {
    return (
      <div className="master-sec-2-bg-pad">
        <Container>
          <h1 className="fw-bo-copy-head-mstr text-center">How It Works</h1>
        </Container>
        <div className="master-two-bg">
          <Container>
            <Row>
              <Col
                xs="12"
                md="6"
                lg="6"
                className="justify-content-center cpy-s2-lft-new1"
              >
                <div className="">
                  <h4 className="copier-tx-mr-mstr">Create Master<br />Account</h4>
                  <p className="copier-tx-p-mstr">
                    Click on Master Area and create a Master Account—start a new
                    one or assign an existing one as your master account.
                  </p>
                  <div className="bnt-bnt">
                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                      <Button className="btn text-center our-btn">Create Account</Button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="6" lg="6" className="justify-content-end mstr-two-img-lft d-flex">
                <Image loading="lazy" src={img1} alt="Image" className="cmpy-img-copy-mstr" data-aos="fade-left" data-aos-duration="1500" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
