import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import './compstyle.css'
import Image from 'react-bootstrap/Image';
import img1 from '../images/Frame.svg'
import img2 from '../images/Frame-1.svg'
import img3 from '../images/Group.svg'
import img4 from '../images/Group-1.svg'
import img5 from '../images/Group-2.svg'
import img6 from '../images/Group-3.svg'

export default class CopierSix extends Component {
    render() {
        return (
            <div className="copier-six-mr">
                <Container>
                    <h1 className="cop-mr-h text-center">This is a new tool that helps traders earn<br className="dnone"></br> additional stable income by copying more <br className="dnone"></br>experienced traders.</h1>
                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center" data-aos="fade-right" data-aos-duration="1200">
                            <Container xs="12" md="6" lg="6" className="justify-content-center d-flex">
                                <Image loading="lazy" src={img1} alt="Image" className="" />
                            </Container>
                            <h3 className=" copi-tx-si-h-1 text-center">Learn from the best</h3>
                            <p className=" copier-tx-mr-3-1 text-center">Easy entrance to the Forex market for newbies.</p>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center" data-aos="fade-left" data-aos-duration="1200">
                            <Container xs="12" md="6" lg="6" className="justify-content-center d-flex">
                                <Image loading="lazy" src={img2} alt="Image" className="to2" />

                            </Container>
                            <h3 className=" copi-tx-si-h-2 text-center">Diversify your portfolio</h3>
                            <p className="copier-tx-mr-3-2 text-center">Choose Master Traders to follow from a large<br></br>
                                number of professionals.</p>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center" data-aos="fade-left" data-aos-duration="1600">
                            <Container xs="12" md="6" lg="6" className="justify-content-center d-flex">
                                <Image loading="lazy" src={img3} alt="Image" className="to3" />

                            </Container>
                            <h3 className="copi-tx-si-h-3 text-center">Enjoy fast order execution</h3>
                            <p className="copier-tx-mr-3-3 text-center">Your order is executed less than 5ms after the <br></br>original one.</p>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center" data-aos="fade-right" data-aos-duration="1600">
                            <Container xs="12" md="4" lg="4" className="justify-content-center d-flex mt-24">
                                <Image loading="lazy" src={img4} alt="Image" className="to1" />
                            </Container>
                            <h3 className=" copi-tx-si-h-1-1 text-center">Learn from the best</h3>
                            <p className=" copier-tx-mr-3-1 text-center">Easy entrance to the Forex market for newbies.</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center" data-aos="fade-right" data-aos-duration="2000">
                            <Container xs="12" md="6" lg="6" className="justify-content-center d-flex">
                                <Image loading="lazy" src={img5} alt="Image" className="to3" />

                            </Container>
                            <h3 className=" copi-tx-si-h-2 text-center">Diversify your portfolio</h3>
                            <p className="copier-tx-mr-3-2 text-center">Choose Master Traders to follow from a large<br></br>
                                number of professionals.</p>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center" data-aos="fade-left" data-aos-duration="2000">
                            <Container xs="12" md="6" lg="6" className="justify-content-center d-flex">
                                <Image loading="lazy" src={img6} alt="Image" className="to4" />

                            </Container>
                            <h3 className="copi-tx-si-h-3-3 text-center">Enjoy fast order execution</h3>
                            <p className="copier-tx-mr-3-3 text-center">Your order is executed less than 5ms after the<br></br> original one.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}