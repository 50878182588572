import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class AboutTwoOne extends Component {
    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
                <Container>
                    <Row>
                    <Col xs="12" md="1" lg="1"></Col>
                        <Col xs="12" md="10" lg="10">

                            <h1 className="text-center txt-cop-h1">Global Offering</h1>
                            <h6 className="text-center txt-cop-p">The winsorfx.com group has regulated entities in the UK, Europe,
                             Australia, South Africa and BVI. Each entity is regulated by the relevant authority for that jurisdiction.
                              Our size and scale allow us to provide traders a more relevant offering which is based on geographical 
                              location, citizenship and residency, all per the applicable regulations. We continue to grow and as a 
                              global brand we have accumulated the resources and rightfully earned the expertise necessary to deliver
                               the best trading experience to our clients. We invest in your knowledge and focus on creating new 
                               trading tools to assist your decision making and to allow you to make more informed trades.Wherever you
                                are in the world, some things don't change; you'll still enjoy a competitive trading experience on our
                                 advanced and intuitive trading platform.</h6>
                          

                        </Col>
                        <Col xs="12" md="1" lg="1"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}