import { React, Component } from "react";
import { Container } from "react-bootstrap"
import './compstyle.css'
export default class DuelContestTwo extends Component {
    render() {
        return (
            <div className="white-bg">
                <Container className="moss">
                    <h5 class="line-1 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> Here's what you need to know:</h5>
                </Container>
                <Container className="black-box-two">
                    <div className="mo-spend-two">
                        <h5 className="winner-award-two">THE CHALLENGE:</h5>
                        <p className="index-winner-top">Trade on a designated C-TRADER account within 24 hours. The trader with <br />
                            the highest profits emerges as the champion! 💹💰</p>
                        <h5 className="winner-award">HOW TO JOIN:</h5>
                        <p className="index-winner-top"><b class="top-tr">🔥 FIND A DUEL PARTNER:</b> Invite a friend to join you in the duel, or opt to be matched with a random user for a surprise challenge.</p>
                        <p className="index-winner-top"><b class="top-tr">🎮 GET YOUR DUEL GAME ACCOUNT:</b> Your unique account details will be provided upon registration.
                        </p>
                        <p className="index-winner-top"><b class="top-tr">💼 TRADE TO TRIUMPH: </b> Engage in strategic trading, aiming to outperform your opponent and secure the top spot.</p>

                    </div>
                </Container>
            </div>
        )
    }
} 