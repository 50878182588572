import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class RiskTwo extends Component {
    render() {
        return (
            <div className="ab-fo-two">
                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Collection of Personal Information:</h5>
                                <p className="about-para">Winsorfx collects personal information from users when they register and use the trading platform. This information may include but is not limited to, name, email address, phone number, date of birth, government-issued identification, and financial information. The collection of personal information is necessary to provide and improve our services.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Use of Personal Information:</h5>
                                <p className="about-para">The personal information collected by Winsorfx is used for the following purposes: account management, customer support, authentication, processing financial transactions, complying with legal and regulatory requirements, and communication with users. We may also use the information for analysis and improvement of our services.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Protection of Personal Information:</h5>
                                <p className="about-para">Winsorfx takes reasonable measures to protect the personal information of our users. We employ industry-standard security practices to prevent unauthorized access, disclosure, alteration, or destruction of personal data. However, no system is completely secure, and Winsorfx cannot guarantee the absolute security of your information.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Sharing of Personal Information:</h5>
                                <p className="about-para">Winsorfx may share personal information with third parties in the following circumstances: to facilitate financial transactions, comply with legal obligations, enforce our terms and conditions, prevent fraud or illegal activities, or provide specific services requested by the user. We ensure that any third parties with whom we share personal information are bound by confidentiality and data protection obligations.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Cookies and Tracking Technologies:</h5>
                                <p className="about-para">Winsorfx may use cookies and similar tracking technologies to enhance user experience, analyze website usage, and gather information about user preferences. These technologies may collect and store information such as IP addresses, browser type, operating system, and referring URLs. Users can manage cookie preferences through their browser settings.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Marketing Communications:</h5>
                                <p className="about-para">Winsorfx may send marketing communications to users who have provided their consent. Users have the option to unsubscribe from marketing communications at any time by following the instructions provided in the communication or by contacting Winsorfx directly.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Data Retention:</h5>
                                <p className="about-para">Winsorfx retains personal information for as long as necessary to fulfill the purposes for which it was collected or as required by applicable laws and regulations. When personal information is no longer needed, we securely delete or anonymize it.</p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}