import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import ourplat_img from "../../images/why-sec-3-img.png";


export default class AboutEightThree extends Component {
    render() {
        return (
            <div className="bg-white pt-5">
                <Container className="">
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <h4 className="text-center font-medium text-4xl head-our-platf">What do our traders get?</h4>
                            <p className="text-center text-black text-base font-normal my-4">Whether you’re just looking to get started, or are an experienced trader, winsorfx.com is the
                                place to be. Here, trading exists in its purest form. That’s the winsorfx.com difference. We make
                                trading accessible to you.</p>
                        </Col>
                    </Row>
                    <Row className="my-5">
                        <Col xs="12" md="6" lg="6" className="justify-content-center">
                            <Container className=" justify-content-center">
                                <h4 className="text-left text-black text-xl font-normal ourplatf-s3-head">More power in our platforms</h4>
                                <p className="text-left text-black font-normal">
                                    We provide our traders with a wide range of easy to use,
                                    effective and powerful trading platforms. Trade online with
                                    us on your desktop, on the go via our mobile app, or go
                                    pro with CTrader.
                                </p>
                                <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                    <button type="button" class="btn text-center our-btn bts-mb-re">Find More</button>
                                </Link>
                            </Container>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center">
                            <Container className=" justify-content-center">
                                <Image loading="lazy" src={ourplat_img} alt="Image" className="lap-re-img" data-aos="slide-left" data-aos-duration="2000"></Image>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }
}