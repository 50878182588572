import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import MiniChartWidget45 from "./Widgets/MiniChartWidget45";
import MiniChartWidget46 from "./Widgets/MiniChartWidget46";
import MiniChartWidget47 from "./Widgets/MiniChartWidget47";
import MiniChartWidget48 from "./Widgets/MiniChartWidget48";

export default class ETFFive extends Component {
    render() {
        return (
            <div className="commod-bg-4">
                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12" className="text-center">
                            <h1 className="commod-4-txt-h">Ways to Trade ETFs CFDs</h1>
                            <p className="commod-4-txt-p">Commodity CFDs - A CFD account will allow you to trade online, accessing a range of spot and futures commodity markets, as well as commodity stocks and ETFs.</p>

                        </Col>
                        <Container className="justify-content-center mr-comd-tp">
                            <Row className="mr-comd-tp d-flex justify-content-center">
                                {/* <Col xs="0" md="1" lg="1"></Col> */}
                                <Col xs="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="slide-right" data-aos-duration="1200">
                                    <Container className="bg-wh-comd">
                                        <MiniChartWidget45 />

                                        <Container>
                                            <Row className="d-flex justify-content-around">
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                                        <Button className="btn text-center comd-our-btn-nw-mob">Trade</Button>
                                                    </Link>
                                                </Col>
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/login.php" target="_blank">
                                                        <Button className="btn text-center comd2-our-btn-nw-mob">Commodities</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Container>
                                </Col>
                                {/* <Col xs="0" md="2" lg="2"></Col> */}
                                <Col xs="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="slide-left" data-aos-duration="1200">
                                    <Container className="bg-wh-comd">
                                        <MiniChartWidget46 />
                                        <Container>
                                            <Row className="d-flex justify-content-around">
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                                        <Button className="btn text-center comd-our-btn-nw-mob">Trade</Button>
                                                    </Link>
                                                </Col>
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/login.php" target="_blank">
                                                        <Button className="btn text-center comd2-our-btn-nw-mob">Commodities</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Container>
                                </Col>
                                {/* <Col xs="0" md="1" lg="1"></Col> */}
                            </Row>
                            <Row className="mr-comd-bt d-flex justify-content-center">
                                {/* <Col xs="0" md="1" lg="1"></Col> */}
                                <Col xs="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="slide-right" data-aos-duration="1800">
                                    <Container className="bg-wh-comd">
                                        <MiniChartWidget47 />

                                        <Container>
                                            <Row className="d-flex justify-content-around">
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                                        <Button className="btn text-center comd-our-btn-nw-mob">Trade</Button>
                                                    </Link>
                                                </Col>
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/login.php" target="_blank">
                                                        <Button className="btn text-center comd2-our-btn-nw-mob">Commodities</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Container>
                                </Col>
                                {/* <Col xs="0" md="2" lg="2"></Col> */}
                                <Col xs="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="slide-left" data-aos-duration="1800">
                                    <Container className="bg-wh-comd">
                                        <MiniChartWidget48 />
                                        <Container>
                                            <Row className="d-flex justify-content-around">
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                                        <Button className="btn text-center comd-our-btn-nw-mob">Trade</Button>
                                                    </Link>
                                                </Col>
                                                <Col xs="5" md="6" lg="6" className="no-pad-nw-mob">
                                                    <Link to="https://my.winsorfx.com/login.php" target="_blank">
                                                        <Button className="btn text-center comd2-our-btn-nw-mob">Commodities</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Container>
                                </Col>
                                {/* <Col xs="0" md="1" lg="1"></Col> */}
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </div>
        )
    }
}
