import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer'
import MasterOne from './components/Master-one';
import MasterFive from './components/Master-five';
import MasterSix from './components/Master-six';
import MasterSeven from './components/Master-seven';
import MasterNine from './components/Master-nine';
import Masterthree from './components/Master-three';
import Mastertwo from './components/Master-two';
import Masterfour from './components/Master-four';
import Mastereight from './components/Master-eight';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function MasterAccountPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - Master Account';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className="clsmnu">
        <MasterOne />
        <Mastertwo />
        <Masterthree />
        <Masterfour />
        <MasterFive />
        <MasterSix />
        <MasterSeven />
        <MasterNine />
        <Mastereight />
        <Footer />
        <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default MasterAccountPage;