import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';

export default class CopierNine extends Component {
    render() {
        return (
            <div className="forex-re-7">
                <Container>
                    <h1 className="text-center tx-cl-re-8">Questions?</h1>
                    <h1 className="text-center tx-cl-red-8">We have answers!</h1>
                    {/* <a className="text-left text-red text-sm my-4 explore-txt font-normal">Explore all FAQs</a><span>></span> */}
                    <Row>
                        <Col xs="12" md="12" lg="12" className="justify-content-center">
                            <Container>
                                <Accordion id="faq-acc">
                                    <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000">
                                        <Accordion.Header>What is a Copier account on WinsorFX?</Accordion.Header>
                                        <Accordion.Body>
                                            A Copier account on WinsorFX is an account type that allows you to copy the trades from another account, such as a master account, thus eliminating the need for you to manually place trades.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1300">
                                        <Accordion.Header>How do I open a Copier account?</Accordion.Header>
                                        <Accordion.Body>
                                            You can open a Copier account on my.winsorfx.com by logging into your account and selecting the “myinvest” option from the navigation bar.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1600">
                                        <Accordion.Header>How do I monitor the performance of the Copier account?</Accordion.Header>
                                        <Accordion.Body>
                                            You can monitor the performance of your Copier account by logging into your account and viewing the “myinvest” dashboard.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1900">
                                        <Accordion.Header>What type of trading strategies can I use with a Copier account?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            You can use any type of trading strategies as long as they are compatible with the master account you are copying.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="2200">
                                        <Accordion.Header>What is the maximum amount of trades I can copy from a master account?</Accordion.Header>
                                        <Accordion.Body>
                                            The maximum amount of trades you can copy from a master account is unlimited.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Container>
                        </Col>
                    </Row>
                    <div className="txt-move" data-aos="fade-up" data-aos-duration="2500">
                        <a className="text-red text-sm expand-txt">Explore all FAQs</a>
                    </div>
                </Container>
            </div>
        )
    }
}