import { React, Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import { Row, Col, Image } from "react-bootstrap";

export default class TermsOne extends Component {
    render() {
        return (
            <div className="copier1-bg terms-bg" id="co-bg-re">
                <Container>
                    <Row>
                        <Col xs="12" md="1" lg="1"></Col>
                        <Col xs="12" md="10" lg="10">
                            <h1 className="text-center txt-cop-h1">TERMS AND CONDITIONS</h1>
                            <h6 className="text-center txt-cop-p">These terms and conditions pertain to your use as a trader of the Winsorfx trading platform offered by Winsorfx. You consent to being legally bound by these Terms by accessing or using the Platform. You are not permitted to use the Platform if you disagree with these Terms.</h6>
                        </Col>
                        <Col xs="12" md="1" lg="1"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}