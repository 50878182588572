import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


export default class TradingPlatformOne extends Component {
    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
                <Container className="mbtrading-cont">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <Container>
                                <h1 className="text-center font-semibold text-[50px] mt-4 red-text">Trading Platform</h1>
                                <h6 className="text-center our-plat-para">Take your trading to the next level with the winsorfx.com powerful,
                                    feature & benefit rich<br className="dnone"></br> platform, featuring high grade charting, unique analytic tools and customisable
                                    alerts.</h6>
                                <Row>
                                    <Col xs="12" md="12" lg="12" className="text-center">
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn text-center our-btn">Sign Up</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
