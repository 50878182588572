import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from '../components/Navbar';
import Footer from '../components/Footer'
import AboutSevenOne from './Components/AboutSeven-one'
import AboutSevenTwo from './Components/AboutSeven-two'
import AboutSevenThree from './Components/AboutSeven-three';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function FAQ() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    document.title = 'Winsorfx - FAQs';
  }, []);
  return (
    <div className="App">
      <NavbarComp />
      <div className='clsmnu'>
      <AboutSevenOne />
      <AboutSevenTwo />
      <AboutSevenThree />
      <Footer />
      <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
      </div>
    </div>
  );
}

export default FAQ;
