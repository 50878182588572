import { React, Component } from "react";
import { Col, Container, Row, Image } from "react-bootstrap"
import './compstyle.css'
import rewards_img from '../images/rewards-img.png';
import whyduel_img from '../images/whyduel-img.png';
import acceptduel_img from '../images/acceptduel-img.png';

export default class DuelContestThree extends Component {
    render() {
        return (
            <Container>
                <Row className="align-items-center">
                    <Col xs="12" md="6" lg="6">
                        <Container className="moss">
                            <h5 class="line-1 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> THE REWARDS:</h5>
                            <p>The winner of each Doubling Duel earns a whopping 50 reward points! 🎁 These points can be redeemed for live account deposits, boosting your trading potential!</p>
                        </Container>
                    </Col>
                    <Col xs="12" md="6" lg="6" className="d-flex justify-content-center">
                        <Image loading="lazy" src={rewards_img} alt="Image" className="rewards-img"></Image>
                    </Col>
                </Row>
                <Row className="align-items-center col-reverse">
                    <Col xs="12" md="6" lg="6" className="d-flex justify-content-center">
                        <Image loading="lazy" src={whyduel_img} alt="Image" className="whyduel-img"></Image>
                    </Col>
                    <Col xs="12" md="6" lg="6">
                        <Container className="moss">
                            <h5 class="line-1 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> WHY DUEL:</h5>
                            <p>Engaging in a head-to-head competition adds a new level of excitement and learning to our trading journeys. It's a fantastic opportunity to test our strategies and push ourselves to new heights.</p>
                        </Container>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col xs="12" md="6" lg="6">
                        <Container className="moss">
                            <h5 class="line-1 d-flex"><span class="line-index">|<h6 class="rot"></h6></span> HOW TO ACCEPT THE DUEL:</h5>
                            <p>Simply Click "Challenge Accepted!" and We'll ensure you receive your unique game account details promptly. Let's dive into this challenge and aim for victory!

                                We can't wait to see who emerges as the victor in this thrilling duel. Let the games begin! 🎯
                            </p>
                        </Container>
                    </Col>
                    <Col xs="12" md="6" lg="6" className="d-flex justify-content-center">
                        <Image loading="lazy" src={acceptduel_img} alt="Image" className="rewards-img"></Image>
                    </Col>
                </Row>
            </Container>
        )
    }

}