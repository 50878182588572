import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class OurPlatOne extends Component {
    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
                <Container>
                    <Row>
                    <Col xs="12" md="2" lg="2"></Col>
                        <Col xs="12" md="8" lg="8">

                            <h1 className="text-center txt-cop-h1">Our Platform</h1>
                            <h6 className="text-center txt-cop-p">The winsorfx.com trading platform is your gateway to the world’s 
                            financial markets. Trade from your desk or on the app. Smart, fast and customisable to your requirements.</h6>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="text-center">
                                    <Button className="btn text-center copier-btn">Try it Now</Button>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs="12" md="2" lg="2"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}