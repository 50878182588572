import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class IBThree extends Component {
    render() {
        return (
            <div className="ib-three-align bg-white">
                <Container>
                    <Row data-aos="fade-up" data-aos-duration="2000">
                        <Col xs="12" md="6" lg="6">
                            <h3 className="pamm-one-head5 text-xl font-medium text-left">THE WAY WINSORFX AFFILIATE PROGRAM OPERATES</h3>
                            <p className="ib-three-p-1 grey-txt">
                                Our affiliate program functions in a very easy way possible. All you need to do is, you promote us on
                                various ways and platforms <br className="dnone"></br>by using our unique link. You start earning commission when the clients referred
                                by you start to trade with us with the real money <br className="dnone"></br>and not just this, we have also got a multi-tier scheme
                                for you. </p>
                        </Col>
                        <Col xs="12" md="6" lg="6">
                            <h3 className="pamm-one-head5 text-xl font-medium text-left">HOW MUCH PROFIT WILL I MAKE?</h3>
                            <p className="ib-three-p-1 grey-txt">
                                Winsorfx offers a great platform where our affiliates can earn a good amount. On each completed deal, each
                                and every affiliate<br className="dnone"></br> earns $10 per lot traded and has the potential to gain up to an additional
                                $16.7 per lot traded by also referring sub affiliates to <br className="dnone"></br>Winsorfx. You would be happy to know
                                that all our deals and packages are lifetime which means they will continue till the time <br className="dnone"></br>your
                                referrals trades with us on a regular basis. </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}