import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Button from "react-bootstrap/Button";
import img4 from "../images/rank1.png";

export default class CopierSeven extends Component {
  render() {
    return (
      <div className="bg-white copier-six-bg">
        <Container>
          {/* <Row>
              <Col
                xs="12"
                md="12"
                lg="12"
                className="justify-content-center no-pad1 cpy-1234"
              >
                <Container xs="12" md="4" lg="4" className="cpy-trd-box d-flex">
                  <Row>
                    <Col>
                      <div className="cpy-trade-pers">
                        <div className="d-flex cpy-trd-box1">
                          <div>
                            <Image loading="lazy" src={img1} alt="Image" className="op1" />
                          </div>
                          <div className="bp-box-s1">
                            <p className="personname">David Jacks</p>
                            <p className="no-mrg">Canada</p>
                          </div>
                          <div className="bp-box-s2">
                            <p className="line">| Profit</p>
                            <p className="cpy-trade-pers-grn no-mrg">$5,700</p>
                          </div>
                          <div className="bp-box-s3">
                            <p className="line">| Floating Profit</p>
                            <p className="cpy-trade-pers-grn no-mrg">{'\u00A0'}{'\u00A0'}$5,700</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row> */}
          <Row>
            <Col>
              <Image loading="lazy" src={img4} alt="Image" className="cmpy-img-copy-2-scr" />
            </Col>
          </Row>
          <Row className="btn-cpy-trd-pad">
            <a className="create-accc text-center">
              <h4 className="text-center no-mrg cr-acc">Copy trade</h4>
            </a>
          </Row>
          <Row>
            <Col>
              <div className="bnt-bnt1">
                <Link to="https://my.winsorfx.com/register.php" target="_blank">
                  <Button className="btn text-center our-btn">Join Trading</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    )
  }
}