import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image"
// import redbg_phone from "../images/home3new.png"
import redbg_phone from "../images/home3new-fotor-bg-remover.png"
import five_tick from "../images/tick.svg"
import "./compstyle.css"

export default class SecFifth extends Component {
    render() {
        return (
            <div className="sec-fifth-align">
                <Container>
                    <Row>
                        <Col xs="12" md="6" lg="6" className="home-img-align">
                            <div className="img-bg-hme" data-aos="zoom-in-up" data-aos-duration="2000">
                                <Image loading="lazy" src={redbg_phone} alt="Image" className="red-bg-phone-1" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800"></Image>
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="6">
                            <div className="sec-five-left-align">
                                <h1 className="text-dark">We Are The <b className="text-red-500 font-medium">Market</b><br></br><h1 className="text-red-500">Leader</h1></h1>
                                <p className="sec-five-para ">We have many features to make the clients comfortable.</p>
                                <div>
                                    <Row>
                                        <Col xs="2" md="2" lg="2">
                                            <Image loading="lazy" src={five_tick} alt="Image" className="five-tick"></Image>
                                        </Col>
                                        <Col xs="10" md="10" lg="10">
                                            <div>
                                                <h4 className="font-semibold">Instant Customer Support</h4>
                                                <p>We offer quick assistance to all your queries so that you get the optimum solutions.</p>
                                            </div>
                                        </Col>
                                        <Col xs="2" md="2" lg="2">
                                            <Image loading="lazy" src={five_tick} alt="Image" className="five-tick"></Image>
                                        </Col>
                                        <Col xs="10" md="10" lg="10">
                                            <div>
                                                <h4 className="font-semibold">Trading Instruments</h4>
                                                <p className="text-sm">Explore the global financial market with Winsorfx and increase your trading portfolio.</p>
                                            </div>
                                        </Col>
                                        <Col xs="2" md="2" lg="2">
                                            <Image loading="lazy" src={five_tick} alt="Image" className="five-tick"></Image>
                                        </Col>
                                        <Col xs="10" md="10" lg="10">
                                            <div>
                                                <h4 className="font-semibold">Professional Conditions</h4>
                                                <p className="text-sm">We offer the best trading environment to our clients to trade and experience the best.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}