import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Trading from './TradingPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Mt5page from './Mt5Page';
import Ctrader from './WinsorfxCTrader';
import Mt4page from './MT4Page';
import MobileTradingpage from './MobileTradingPage';
import BondCFDpage from './BondCdfPage';
import Tradingplatform from './Tradingplatform';
import OurPlat from './our-platform'
import CommoditiesPage from './CommoditiesPage';
import CryptoCurrencyPage from './CryptoCurencyPage';
import IndicesCFDsPage from './IndicesCFDsPage';
import ETFsPage from './ETFsPage';
import SharesPage from './Shares';
import IntroducingCFDs from './IntroducingCFD';
import ForexPage from './Forex';
// about
import Complaints from './About/Complaints';
import ContactSupport from './About/ContactSupport';
import FAQ from './About/FAQ';
import GlobalOffering from './About/GlobalOffering';
import LegalPack from './About/LegalPack';
import SafetyOnline from './About/SafetyOnline';
import WhyWinsorfx from './About/WhyWinsorfx';
// about
import CopierAccountPage from './CopierAccountPage';
import MasterAccountPage from './MasterAccountPage';
import PammPage from './PammPage';
import IBPage from './IBPage';
import DemoContest from './DemoContest';
import TermsPage from './About/TermsandConds';
import RiskDisk from './About/RiskDisk';
import PrivacyPolicy from './About/PrivacyPolicy';
import Conflicts from './About/Conflicts';
import ClientsComplaints from './About/ClientsComplaints';
import DuelDemoContest from './DuelDemoContest';

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//   },
//   {
//     path: "Trading",
//     element: <Trading />,
//   },
//   {
//     path: "Home",
//     element: <App />,
//   },
//   {
//     path: "CTrader",
//     element: <Ctrader />,
//   },
//   {
//     path: "MT5",
//     element: <Mt5page />,
//   },
//   {
//     path: "MT4",
//     element: <Mt4page />,
//   },
//   {
//     path: "MobileTrading",
//     element: <MobileTradingpage />,
//   },
//   {
//     path: "BondfCFDs",
//     element: <BondCFDpage />,
//   },
//   {
//     path: "TradingPlatform",
//     element: <Tradingplatform />,
//   },
//   {
//     path: "OurPlatform",
//     element: <OurPlat />,
//   },
//   {
//     path: "Commodities",
//     element: <CommoditiesPage />,
//   },
//   {
//     path: "CryptoCurrency",
//     element: <CryptoCurrencyPage />,
//   },
//   {
//     path: "IndicesCFDs",
//     element: <IndicesCFDsPage />,
//   },
//   {
//     path: "ETFs",
//     element: <ETFsPage />,
//   },
//   {
//     path: "Shares",
//     element: <SharesPage />,
//   },
//   {
//     path: "Forex",
//     element: <ForexPage />,
//   },
//   {
//     path: "Blends",
//     element: <IntroducingCFDs />,
//   },
//   {
//     path: "about/Complaints",
//     element: <Complaints />,
//   },
//   {
//     path: "about/ContactSupport",
//     element: <ContactSupport />,
//   },
//   {
//     path: "about/FAQ",
//     element: <FAQ />,
//   },
//   {
//     path: "about/GlobalOffering",
//     element: <GlobalOffering />,
//   },
//   {
//     path: "about/LegalPack",
//     element: <LegalPack />,
//   },
//   {
//     path: "about/SafetyOnline",
//     element: <SafetyOnline />,
//   },
//   {
//     path: "about/WhyWinsorfx",
//     element: <WhyWinsorfx />,
//   },
//   {
//     path: "CopierAccount",
//     element: <CopierAccountPage />,
//   },
//   {
//     path: "MasterAccount",
//     element: <MasterAccountPage />,
//   },
//   {
//     path: "Pamm",
//     element: <PammPage />,
//   },
//   {
//     path: "IB",
//     element: <IBPage />,
//   },
// ]);
// console.log("test dat", process.env.REACT_APP_URL);
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>
// );

export function ReactApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        {/* <Route path="home" index element={<App />} /> */}
        <Route path="trading" element={<Trading />} />
        <Route path="ctrader" element={<Ctrader />} />
        <Route path="MT5" element={<Mt5page />} />
        <Route path="Mt4" element={<Mt4page />} />
        <Route path="MobileTrading" element={<MobileTradingpage />} />
        <Route path="BondCFDs" element={<BondCFDpage />} />
        <Route path="TradingPlatform" element={<Tradingplatform />} />
        <Route path="OurPlatform" element={<OurPlat />} />
        <Route path="Commodities" element={<CommoditiesPage />} />
        <Route path="CryptoCurrency" element={<CryptoCurrencyPage />} />
        <Route path="IndicesCFDs" element={<IndicesCFDsPage />} />
        <Route path="ETFs" element={<ETFsPage />} />
        <Route path="Shares" element={<SharesPage />} />
        <Route path="Forex" element={<ForexPage />} />
        <Route path="Blends" element={<IntroducingCFDs />} />
        <Route path="about/Complaints" element={<Complaints />} />
        <Route path="about/ContactSupport" element={<ContactSupport />} />
        <Route path="about/FAQ" element={<FAQ />} />
        <Route path="about/GlobalOffering" element={<GlobalOffering />} />
        <Route path="about/LegalPack" element={<LegalPack />} />
        <Route path="about/SafetyOnline" element={<SafetyOnline />} />
        <Route path="about/WhyWinsorfx" element={<WhyWinsorfx />} />
        <Route path="CopierAccount" element={<CopierAccountPage />} />
        <Route path="MasterAccount" element={<MasterAccountPage />} />
        <Route path="Pamm" element={<PammPage />} />
        <Route path="IB" element={<IBPage />} />
        <Route path="DemoContest" element={<DemoContest />} />
        <Route path="about/TermsandConds" element={<TermsPage />} />
        <Route path="about/RiskDisk" element={<RiskDisk />} />
        <Route path="about/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="about/Conflicts" element={<Conflicts />} />
        <Route path="about/ClientsComplaints" element={<ClientsComplaints />} />
        <Route path="DuelDemoContest" element={<DuelDemoContest />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<ReactApp />);

export default ReactApp


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

