import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';
import ForexOne from './components/forex-one';
import ForexThree from './components/forex-three';
import ForexFour from './components/forex-four';
import ForexFive from './components/forex-five';
import ForexSix from './components/forex-six';
import ForexSeven from './components/forex-seven';
import ForexEight from './components/forex-eight';
import ForexTwo from './components/forex-two';
import {useEffect} from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function ForexPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - Forex';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div className='clsmnu'>
            <ForexOne />
            <ForexTwo />
            {/* <ShareTwo /> */}
            <ForexThree />
            <ForexFour />
            <ForexFive />
            <ForexSix />
            <ForexSeven />
            <ForexEight />
            {/* <PopupOne /> */}
            <Footer />
            <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default ForexPage;