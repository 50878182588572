import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer';

import ForexEight from './components/forex-eight'
import IndicesOne from './components/Indices-one';
import IndicesTwo from './components/Indices-two';
import IndicesThree from './components/Indices-three';
import IndicesFour from './components/Indices-four';
import IndicesFive from './components/Indices-five';
import ForexFive from './components/forex-five';
import { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function IndicesCFDsPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - Indices CFDs';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div className="clsmnu">
                <IndicesOne />
                <IndicesTwo />
                <IndicesThree />
                <IndicesFour />
                <ForexFive />
                <IndicesFive />
                <ForexEight />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default IndicesCFDsPage;