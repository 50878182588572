import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class RiskTwo extends Component {
    render() {
        return (
            <div className="ab-fo-two">
                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Trading risks:</h5>
                                <p className="about-para">There are major dangers involved in trading on the financial markets. Significant financial losses could occur, and previous performance is no guarantee of future success. Before starting any trading activities, you should carefully assess your financial status and risk tolerance.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Volatility and Market fluctuations:</h5>
                                <p className="about-para">Market fluctuations and substantial market volatility are two things that might happen in the financial sector. Events in the economy, politics, and social life can all contribute to these oscillations. Such volatility may result in sudden and significant gains or losses.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Leverage:</h5>
                                <p className="about-para">Using leverage when trading on the Winsorfx platform is possible. Utilizing leverage enables you to manage a greater position with less capital. Leverage can increase earnings, but it can also increase losses.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Risks associated with liquidity:</h5>
                                <p className="about-para">Certain market circumstances or occurrences may lead to decreased liquidity, making it challenging to complete trades at desired pricing. The danger of slippage, where trades are executed at a different price than anticipated, can increase in illiquid markets.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Technical and System Risks:</h5>
                                <p className="about-para">The Winsorfx trading platform relies on cutting-edge technological systems, which are prone to technical problems like connectivity failures, server outages, and software bugs. These technical problems may cause incorrect pricing, delayed order fulfillment, or other operational problems.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Regulatory and Legal Risks:</h5>
                                <p className="about-para">Trading on Winsorfx is governed by all relevant laws, rules, and market regulations. Regulation or legal requirement changes may have an effect on market accessibility, trading conditions, and the accessibility of particular financial products. It is vital to keep up with regulatory developments and obey all applicable rules.</p>
                            </Row>
                            <Row data-aos="fade-up" data-aos-duration="1200">
                                <h5 className="about-head abt-compla-re">Information and Analysis:</h5>
                                <p className="about-para">The information, analysis, and research provided on the Winsorfx platform are for informational purposes only and should not be considered as financial advice. While reasonable efforts are made to ensure the accuracy of the information, there is no guarantee of its completeness or reliability.</p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}