import React, { Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class AboutFiveOne extends Component {
    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
                <Container>
                    <Row>
                        <Col xs="12" md="2" lg="2"></Col>
                        <Col xs="12" md="8" lg="8">

                            <h1 className="text-center txt-cop-h1">Contact Support</h1>
                            <h6 className="text-center txt-cop-p">We're committed to ensuring that your experience with winsorfx.com is the best possible. So, we're here
                                if you have any issues or just need some help.</h6>


                        </Col>
                        <Col xs="12" md="2" lg="2"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}