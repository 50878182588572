import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../../components/compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ctradimg from "../../images/trade-lap.png";
import img2 from "../../images/why-mobile-sec-img.png";
import appstore from '../../images/down-appstore.svg';
import playstore from '../../images/down-playstore.svg';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import surface from "../../images/surface-tab-corrected.png";

export default class AboutEightFIve extends Component {
  render() {
    return (
      <div className="bg-lft-1">
        <div className="bg-rit-2">
          <Container>
            <Row className="padding-why-abt-row">
              <Col xs="12" md="12" lg="12" className="justify-content-center">
                <h4 className="text-center font-medium text-4xl text-black">
                  Ctrader
                </h4>
                <p className="text-center text-black text-base font-normal my-4">
                  Get our easy to use trading platform with informative,
                  customisable charts and alerts. Go long or short on thousands
                  of financial instruments Lower Spreads, Speedy Execution
                </p>
                <Container className=" img-contai">
                  <Image loading="lazy"
                    src={ctradimg}
                    alt="Image"
                    className="lap-re-img-why-winsor-ctrd-lap" data-aos="zoom-in" data-aos-duration="1200"
                  ></Image>
                  <Link to="https://my.winsorfx.com/register.php" target="_blank">
                    <button type="button" class="btn text-center why-winsor-btn  bts-mb-re-1">Open Account</button>
                  </Link>
                </Container>
              </Col>
            </Row>
            <Row className="padding-why-abt-row">
              <Col xs="12" md="12" lg="12" className="justify-content-center">
                <h4 className="text-center font-medium text-4xl text-black">
                  Mobile Trading
                </h4>
                <p className="text-center text-black text-base font-normal my-4">
                  Our mobile trading app was created for traders seeking quick
                  access to the markets 24/7. Make best use of the simple
                  interface and quick execution - trade with a click.
                </p>
                <Container className=" img-contai">
                  <Image loading="lazy"
                    src={img2}
                    alt="Image"
                    className="lap-re-img-why-winsor-ctrd-mob2" data-aos="zoom-out" data-aos-duration="1200"
                  ></Image>
                </Container>
              </Col>
            </Row>
            <Row id="btn-group-new">
              <Col xs="12" md="12" lg="12" className="text-center">
                <ButtonToolbar aria-label="Toolbar with button groups">
                  <ButtonGroup className="me-2" aria-label="Second group">
                    <a href="https://apps.apple.com/in/app/ctrader/id767428811" target="_blank"><Image loading="lazy" src={appstore} alt="Image" className="app-btn" /></a>
                  </ButtonGroup>
                  <ButtonGroup aria-label="Third group">
                    <a href="https://play.google.com/store/apps/details?id=com.spotware.ct" target="_blank"><Image loading="lazy" src={playstore} alt="Image" className="app-btn2" /></a>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </Row>

            <Row className="padding-why-abt-row">
              <Col xs="12" md="12" lg="12" className="justify-content-center">
                <h4 className="text-center font-medium text-4xl text-black">
                  Trading Platform Tools
                </h4>
                <p className="text-center text-black text-base font-normal my-4">
                  We are at the forefront of trader knowledge, providing insight
                  & analysis with an extensive range of fundamental, technical
                  and sentiment tools.
                </p>
                <Container className=" img-contai">
                  <Image loading="lazy"
                    src={surface}
                    alt="Image"
                    className="lap-re-img-why-winsor-ctrd-lap" data-aos="fade-up" data-aos-duration="1200"
                  ></Image>
                  <Link to="https://my.winsorfx.com/register.php" target="_blank">
                    <button type="button" class="btn text-center why-winsor-btn bts-mb-re-1">Trade Now</button>
                  </Link>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
