import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./compstyle.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import img13 from "../images/new-lap-img.png";

export default class ForexFour extends Component {
  render() {
    return (
      <div className="forex-bg-4">
        <Container className="">
          <Row>
            <Col xs="12" md="12" lg="12">
              <h1 className="text-center tx-cl-re">
                Why Trade Crytocurrency CFDs with Winsorfx
              </h1>
              <p className="text-center tx-p-re">
                Go long or short with crypto CFDs on more than 20 different
                assets. No need for a crypto wallet or to own the underlying
                asset - simply trade the price action. Institutional-grade
                liquidity provides access to narrower spreads and reduced
                slippage.
              </p>
              <Row>
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  className="d-flex justify-content-center"
                >
                  <Image loading="lazy" src={img13} alt="Image" className="lap-re-img" data-aos="zoom-in-up" data-aos-duration="1200"></Image>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
