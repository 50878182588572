import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import traplalap from "../images/surface-tab-corrected.png"


export default class TradePlatformFive extends Component {
    render() {
        return (
            <div className="trading3-bg tr-four">
                <Container>
                    <Row>
                        <Col xs="12" md="5" lg="5" className="justify-content-center mar-tp">
                            <h1 className="tr-pl-tx red-text mar-tp-txt-1">Simply trade with us</h1>
                            <p className="tr-pl-tx-si mar-tp-txt-1 text-light">Market-leading analysis tools with Analyst<br></br> Recommendations, Insiders and more</p>
                            <p className="mar-tp-txt-1 text-light">Watch our expert tips on XRay with several<br></br> shows a day</p>
                            <p className="mar-tp-txt-1 text-light">News and Analysis with a live stream for<br></br> market-moving updates</p>
                            <p className="mar-tp-txt-1 text-light">Pro-level charting tools<br></br> with TradingView</p>
                            <Row className="mar-tp-txt-1">
                                <Col xs="12" md="6" lg="6" className="">
                                    <div>
                                        <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                            <Button className="btn mt5-sing-new tex-light">Sign Up</Button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="7" lg="7" className="justify-content-center d-flex align-items-center">
                            <Container className="align-items-center">
                                <Image loading="lazy" src={traplalap} alt="Image" className="tr-pl-la-new" data-aos="fade-up" data-aos-duration="1500" />
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
