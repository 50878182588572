import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img1 from '../images/cop-eig.svg';

export default class CopierEight extends Component {
    render() {
        return (
            <div className="sec-one-cpy-3">
                <Container fluid className="fluide-cpy-pg">
                    <Row>
                        <Col xs="12" md="6" lg="6" className="justify-content-center cpy-s222">
                            <div className="">
                                <h1 className="txt-cp-eg">All Benefits Of Copy Trading In The Mobile App!</h1>
                                <ul className="list-cp-eg">
                                    <li className="mr-tp-li-cp">Focus on investing with a convenient Winsorfx Copytrading app</li>
                                    <li className="mr-tp-li-cp">Control your portfolio and investments on the go</li>
                                    <li className="mr-tp-li-cp">Track Master Traders and their performance live for smart investing</li>
                                    <li className="mr-tp-li-cp">View how your funds are invested and manage risks in real time</li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="6" className="justify-content-center d-flex">
                            <Image loading="lazy" src={img1} alt="Image" className="mb-copier-img" data-aos="fade-right" data-aos-duration="1200" />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}