import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import flag1 from "../images/JPYUSD.png";
import flag2 from "../images/USDCAD.png";
import flag3 from "../images/EURUSD.png";
import flag4 from "../images/GBPUSD.png";
import SingleTicker1 from "./Widgets/SingleTicker1";
import SingleTicker2 from "./Widgets/SingleTicker2";
import SingleTicker3 from "./Widgets/SingleTicker3";
import SingleTicker4 from "./Widgets/SingleTicker4";

export default class ForexThree extends Component {
    render() {
        return (
            <div className="bg-intro mr-tp-forex tes-mr">
                <div className="forex4-bg-1">
                    <Container>
                        <h1 className="fw-bo-new text-center forex-11-head">What are Currency Pairs?</h1>
                        <h6 className="fw-bo-2-tr text-center text-lg">Currency pairs are financial instruments where one currency is quoted against another currency.<br></br> The quote indicates the amount you would pay in one currency for the other.</h6>
                    </Container>
                </div>
                <div className="forex4-bg-2">
                    <div className="forec2-bg-out ">
                        <Container fluid>
                            <Row>
                                <Col xs="12" md="5" lg="5" className="mr-for">
                                    <h1 className="fw-bo for-lf forex-11-head">Here's a simplified<br></br>example:</h1>
                                    <h6 className="fw-bo-2-tr for-lf">The currency pair GBP/USD is at 1.15. You can<br className="dnone"></br>
                                        exchange 1 GBP for 1.15 USD.</h6>
                                    <h6 className="fw-bo-2-tr for-lf">If GBP/USD started the day at 1.15, and by the end<br className="dnone"></br>
                                        of the day rose to 1.16, it's because the Sterling has<br className="dnone"></br>strengthened against the Dollar.</h6>
                                    <h6 className="fw-bo-2-tr for-lf">If GBP/USD started the day at 1.15, then drops 1.13,<br className="dnone"></br>
                                        it's because the Sterling has weakened against the<br className="dnone"></br>Dollar.</h6>
                                </Col>
                                <Col xs="12" md="7" lg="7" className="justify-content-center d-flex">
                                    <Container className="forex4-cont">
                                        <Row>
                                            <Col xs="12" md="6" lg="6" data-aos="fade-up" data-aos-duration="1400">
                                                <Row>
                                                    <Col xs="6" md="12" lg="12" className="justify-content-start">
                                                        <Image loading="lazy" src={flag1} alt="Image" className="forex4-flag-n1"></Image>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" md="12" lg="12" className="justify-content-center d-flex">
                                                        <SingleTicker1 />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" md="6" lg="6" data-aos="fade-up" data-aos-duration="1400">
                                                <Row>
                                                    <Col xs="6" md="12" lg="12" className="justify-content-start">
                                                        <Image loading="lazy" src={flag2} alt="Image" className="forex4-flag"></Image>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" md="12" lg="12" className="justify-content-center d-flex">
                                                        <SingleTicker2 />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" md="6" lg="6" data-aos="fade-up" data-aos-duration="1800">
                                                <Row>
                                                    <Col xs="6" md="12" lg="12" className="justify-content-start">
                                                        <Image loading="lazy" src={flag3} alt="Image" className="forex4-flag-n1"></Image>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" md="12" lg="12" className="justify-content-center d-flex">
                                                        <SingleTicker3 />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" md="6" lg="6" data-aos="fade-up" data-aos-duration="1800">
                                                <Row>
                                                    <Col xs="6" md="12" lg="12" className="justify-content-start">
                                                        <Image loading="lazy" src={flag4} alt="Image" className="forex4-flag"></Image>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" md="12" lg="12" className="justify-content-center d-flex">
                                                        <SingleTicker4 />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}