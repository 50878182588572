import { React, Component } from "react";
import { Container } from "react-bootstrap";
import '../../components/compstyle.css'
import { Row, Col } from "react-bootstrap";

export default class ConflictsOne extends Component {
    render() {
        return (
            <div className="copier1-bg terms-bg" id="co-bg-re">
                <Container>
                    <Row>
                        <Col xs="12" md="1" lg="1"></Col>
                        <Col xs="12" md="10" lg="10">
                            <h1 className="text-center txt-cop-h1">CONFLICTS OF INTEREST POLICY</h1>
                            <h6 className="text-center txt-cop-p">Trading platforms like Winsorfx generally have Conflicts of Interest policies in place to ensure fair and transparent practices for their users. These policies typically address the potential conflicts that may arise between Winsorfx and its clients, as well as among the clients themselves.</h6>
                        </Col>
                        <Col xs="12" md="1" lg="1"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}