import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import './compstyle.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default class CopierOne extends Component {
    render() {
        return (
            <div className="copier1-bg" id="co-bg-re">
                <Container>
                    <Row>
                        <Col xs="12" md="2" lg="2"></Col>
                        <Col xs="12" md="8" lg="8">
                            <h1 className="text-center txt-cop-h1">MAKE MONEY COPYING<br /> THE SUCCESSES OF PRO TRADERS</h1>
                            <h6 className="text-center txt-cop-p">Winsorfx Copy Trading provides an opportunity to automatically copy
                                leading traders so that you do not have to spend many hours developing your own strategy. Choose
                                the best Forex Masters and diversify your trading portfolio.</h6>
                            <Row>
                                <Col xs="12" md="12" lg="12" className="text-center">
                                    <Link to="https://my.winsorfx.com/register.php" target="_blank">
                                        <Button className="btn text-center copier-btn">Join Copy Trading</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" md="2" lg="2"></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}