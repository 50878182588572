import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import appstore from '../images/down-appstore.svg';
import playstore from '../images/down-playstore.svg';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

export default class MobileTradingOne extends Component {
    render() {
        return (
            <div className="copier1-bg-new" id="co-bg-re">
                <Container className="mbtrading-cont">
                    <Row>
                        <Col xs="12" md="12" lg="12">
                            <Container>
                                <h1 className="text-center font-semibold text-[50px] mt-4 red-text">Mobile Trading</h1>
                                <h6 className="text-center our-plat-para">Never miss a trading opportunity. Trade on the go with our powerful trading app.</h6>
                                <Row id="btn-group-new">
                                    <Col xs="12" md="12" lg="12" className="text-center">
                                        <ButtonToolbar aria-label="Toolbar with button groups">
                                            <ButtonGroup className="me-2" aria-label="Second group">
                                                <a href="https://apps.apple.com/in/app/ctrader/id767428811"><Image loading="lazy" src={appstore} alt="Image" className="app-btn" /></a>
                                            </ButtonGroup>
                                            <ButtonGroup aria-label="Third group">
                                                <a href="https://play.google.com/store/apps/details?id=com.spotware.ct"><Image loading="lazy" src={playstore} alt="Image" className="app-btn2" /></a>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
