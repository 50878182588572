import React, { Component } from "react";
import { Container } from "react-bootstrap";
import './compstyle.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import img2 from '../images/phone22.png';



export default class TradingThree extends Component {
    render() {
        return (
            <div className="tradingpg-s3-bg">
                <Container>
                    <Row className="mobile-cont-space-new">
                        <Col xs="12" md="5" lg="5" className="d-flx-mobtrading d-flex justify-content-center">
                            <Image loading="lazy" src={img2} alt="Image" className="tradingpg-mob-img" data-aos="fade-left" data-aos-duration="1500"/>
                        </Col>
                        <Col xs="12" md="7" lg="7" className="justify-content-center">
                            <Container className="mobile-three-1bg-tradings">
                                <h4 className="text-left font-semibold text-lg trading-pg-head">Accessible to everyone</h4>
                                <ul className="trading-ul-s">
                                    <li className="bobile-txt-trading text-left">Trade the markets with 0 commision*</li>
                                    <li className="bobile-txt-trading text-left">Learn at your own price</li>
                                    <li className="bobile-txt-trading text-left">All the tools included</li>
                                </ul>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
