import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/Navbar';
import Footer from './components/Footer'
import PammOne from './components/Pamm-one';
import PammHero from './components/Pamm-hero';
import PammTwo from './components/Pamm-two';
import PammThree from './components/Pamm-three';
import { useEffect } from 'react';
import PammFour from './components/PammFour';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function PammPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'Winsorfx - Pamm';
    }, []);
    return (
        <div className="App">
            <NavbarComp />
            <div className='clsmnu'>
                <PammHero />
                <PammOne />
                <PammTwo />
                <PammThree />
                <PammFour />
                <Footer />
                <TawkMessengerReact propertyId="6492a7b094cf5d49dc5ef593" widgetId="1h3edu2r0" />
            </div>
        </div>
    );
}

export default PammPage;